import Session from "../classes/session";
import Constantes from "../Constantes";

function getSession() {
    Session.setIp()
    waitForIP()

}

function CloseSession() {
    console.log("No se encuentra sesión")
    localStorage.setItem('auth', '"no"')
    localStorage.setItem('uid', 'null')
    window.location.reload(false);
}

function waitForIP() {
    var dip = Session.getIp();
    if (dip !== "") {
        //variable exists, do what you want
        //console.log("NO Waiting")  

        fetch(`${Constantes.RUTA_API}/get_session.php?ip=${dip}`)
            .then(result => result.json())
            .then(data => {
                //console.log("Data: " + (data))
                if ((data) == false) {
                    CloseSession()
                } else {

                    localStorage.setItem('auth', '"yes"')
                    localStorage.setItem('uid', data.userid)
                    localStorage.setItem('theme', data.theme)
                    Session.setUserId(data.userid)
                    Session.setName(data.username)
                    //console.log("Name: " + Session.getName() + `(${Session.getUserId()})`)
                }

            })
            .catch(({ response }) => {
                // Username not found
                console.log("ERROR")
                CloseSession();
            })
    }
    else {
        //console.log("Waiting")
        setTimeout(waitForIP, 250);
        dip = Session.getIp()

    }

}

export default getSession