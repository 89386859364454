// https://parzibyte.me/blog/2020/12/19/php-react-ejemplo-aplicacion-web/#Descargando_el_codigo

const Constantes = {
    // Prod = cartas.gunaphotos.com // Test = cartas.gunaphotos.com
    RUTA_API: "https://cartas.gunaphotos.com/php",
    RUTA_API_JS: "https://capi.gunaphotos.com",
    RUTA_IMG_CARTAS: "/assets/img/cartas/",
    VERSION: "2.0.5"
};
export default Constantes;

