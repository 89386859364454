import * as React from 'react';
import Loading from "../../Functions/Loading";
import { useState, useEffect } from "react";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Select from 'react-select';

// Acordion
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const FiltrosCartas = (props) => {

    const [loading, setLoading] = useState(true);
    const [cartas, setCartas] = useState(props.cartas);
    const [showobtenidas, setShowObtenidas] = useState(false);
    const [showfaltantes, setShowFaltantes] = useState(false);
    const [showrepetidas, setShowRepetidas] = useState(false);
    const [showrc, setShowRC] = useState(false);
    const [showfirma, setShowFirma] = useState(false);
    const [showpatch, setShowPatch] = useState(false);
    const [shownum, setShowNum] = useState(false);
    const [uniqueyears, setUniqueyears] = useState([]);
    const [year, setYear] = useState("Todos");
    const [expanded, setExpanded] = useState(true);

    useEffect(() => {
        componentDidMount();
    }, [props.cartas]);


    const componentDidMount = async () => {

        UniqueYears(cartas)
        setLoading(false);
    }

    const UniqueYears = (cartas) => {


        const uniqueTags = ["Todos"];
        cartas.map(c => {
            if (uniqueTags.indexOf(c.year) === -1 && c.year != null) {
                uniqueTags.push(c.year)
            }
        })

        uniqueTags.sort((a, b) => a - b);
        setUniqueyears(uniqueTags)

    }

    const handleYearChange = (e) => {
        const valor = e.value;
        ApplyFiltro("year", valor)


    }

    const ApplyFiltro = (filtro, valor) => {
        var filtered = cartas;
        var faltantes = showfaltantes;
        var repetidas = showrepetidas;
        var rookie = showrc;
        var firma = showfirma;
        var patch = showpatch;
        var num = shownum;
        var y = year;
        var obtenidas = showobtenidas;
        if (filtro === "faltantes") {
            faltantes = !showfaltantes
            obtenidas = false;
            repetidas = false;
        } else if (filtro === "repetidas") {
            repetidas = !showrepetidas
            faltantes = false;
        } else if (filtro === "rookie") {
            rookie = !showrc
        } else if (filtro === "obtenidas") {
            obtenidas = !showobtenidas;
            faltantes = false;
        } else if (filtro === "firma") {
            firma = !showfirma;
        } else if (filtro === "year") {
            y = valor;
        } else if (filtro === "patch") {
            patch = !showpatch;
        } else if (filtro === "num") {
            num = !shownum;
        }

        if (obtenidas) {
            filtered = cartas.filter(
                (c) => c.quantity > 0
            );
            setShowObtenidas(true)
        } else {
            setShowObtenidas(false)
        }
        if (repetidas) {
            filtered = filtered.filter(
                (c) => c.quantity > 1
            );
            setShowRepetidas(true)
        } else {
            setShowRepetidas(false)
        }
        if (faltantes) {
            filtered = cartas.filter(
                (c) => c.quantity === null || c.quantity === 0
            );
            setShowFaltantes(true)
        } else {
            setShowFaltantes(false)
        }

        if (rookie) {
            filtered = filtered.filter(
                (c) => c.rookie && c.rookie === 1
            );
            setShowRC(true);
        } else {
            setShowRC(false);
        }

        if (firma) {
            filtered = filtered.filter(
                (c) => c.auto && c.auto === 1
            );
            setShowFirma(true);
        } else {
            setShowFirma(false);
        }

        if (num) {
            filtered = filtered.filter(
                (c) => c.numerada && c.numerada === 1
            );
            setShowNum(true);
        } else {
            setShowNum(false);
        }

        if (patch) {
            filtered = filtered.filter(
                (c) => c.patch && c.patch === 1
            );
            setShowPatch(true);
        } else {
            setShowPatch(false);
        }

        if (y != "Todos") {
            filtered = filtered.filter(
                (c) => c.year == y
            );
            setYear(y)
        }

        props.set(filtered, y)
        props.cur()
        setLoading(false);
    }

    const removefilters = () => {
        setShowFaltantes(false);
        setShowRepetidas(false);
        setShowObtenidas(false)
        setShowFirma(false);
        setShowNum(false);
        setShowRC(false);
        setYear("Todos")
        props.removefilters()
    }

    const handleChangeAC = () => {
        setExpanded(!expanded)
    }

    return (
        <div >
            {loading ? (
                <Loading />
            ) : (
                <Accordion className='menupaperinner MenuIcon secondaryborderbottom' expanded={expanded} onChange={handleChangeAC} elevation={0}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className='secondarycolor' />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Filtros</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={{ xs: 1, md: 1 }} justifyContent="center" alignItems="center">
                            <Grid item xs={12} md className='selectTop' style={{ zIndex: 500 }}>
                                <Select className='selectTop'
                                    id="iyear"
                                    options={uniqueyears.map(c => {
                                        return {
                                            value: c, label: c
                                        }

                                    })}
                                    placeholder="Año..."
                                    onChange={handleYearChange}
                                />
                            </Grid>
                            <Grid item xs>
                                <FormControlLabel control={<Switch
                                    checked={showfaltantes}
                                    onChange={() => {
                                        ApplyFiltro("faltantes");
                                    }} />} label="Faltantes" />
                            </Grid>
                            <Grid item xs>
                                <FormControlLabel control={<Switch
                                    checked={showobtenidas}
                                    onChange={() => {
                                        ApplyFiltro("obtenidas");
                                    }} />} label="Obtenidas" />
                            </Grid>
                            <Grid item xs>
                                <FormControlLabel control={<Switch
                                    checked={showrepetidas}
                                    onChange={() => {
                                        ApplyFiltro("repetidas");
                                    }} />} label="Repetidas" />
                            </Grid>
                            <Grid item xs>
                                <FormControlLabel control={<Switch
                                    checked={showrc}
                                    onChange={() => {
                                        ApplyFiltro("rookie");
                                    }} />} label="Rookie Card" />
                            </Grid>
                            <Grid item xs>
                                <FormControlLabel control={<Switch
                                    checked={shownum}
                                    onChange={() => {
                                        ApplyFiltro("num");
                                    }} />} label="Numerada" />
                            </Grid>
                            <Grid item xs>
                                <FormControlLabel control={<Switch
                                    checked={showfirma}
                                    onChange={() => {
                                        ApplyFiltro("firma");
                                    }} />} label="Auto" />
                            </Grid>
                            <Grid item xs>
                                <FormControlLabel control={<Switch
                                    checked={showpatch}
                                    onChange={() => {
                                        ApplyFiltro("patch");
                                    }} />} label="Patch" />
                            </Grid>
                            <Grid item xs={12} md>
                                <Button variant="contained" color="error" onClick={removefilters}>
                                    Quitar filtros
                                </Button>
                            </Grid>
                        </Grid>

                    </AccordionDetails>
                </Accordion>
            )}
        </div>
    );
}

export default FiltrosCartas;