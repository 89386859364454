import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import 'jszip';
import 'pdfmake';
import "pdfmake/build/vfs_fonts.js";
import 'datatables.net-dt';
import 'datatables.net-autofill-dt';
import 'datatables.net-buttons-dt';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.flash';
import 'datatables.net-buttons/js/buttons.print';
import 'datatables.net-fixedcolumns-dt';
import 'datatables.net-fixedheader-dt';
import 'datatables.net-keytable-dt';
import 'datatables.net-responsive-dt';
import 'datatables.net-rowgroup-dt';
import 'datatables.net-searchbuilder-dt';
import 'datatables.net-searchpanes-dt';
import 'datatables.net-select-dt';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import theme from "../Theme/theme";


pdfMake.vfs = pdfFonts.pdfMake.vfs;

class TablaCompraventas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            compraventas: this.props.compraventas
        };
    }
    async componentDidMount() {
        var perpage = 25;
        if (this.props.perpage !== undefined) {
            perpage = this.props.perpage;
        }

        const compraventas = this.props.compraventas
        this.setState({
            compraventas: compraventas
        });

        //initialize datatable
        $(document).ready(function () {
            setTimeout(function () {
                $('#tablacompraventa').DataTable({
                    dom: 'frltip',
                    pageLength: perpage,
                    lengthMenu: [[perpage, 50, 75, 100, -1], [perpage, 50, 75, 100, "Todos"]],
                    order: [[0, "desc"]],
                    FixedHeader: true,
                    responsive: true,
                    autoFill: false,
                    keys: false,
                    bDestroy: true
                });
            }, 1000);

        });
    }

    gettextclass(tipo) {
        if (tipo == 0) {
            return "greentext";
        } else {
            return "redtext";
        }
    }

    getStatus(pedido) {
        let text = "";
        if (pedido.tipo == 0) { // venta
            text = "ENVIADO";
            //return "greentext";
        } else { // compra
            text = "RECIBIDO"
            //return "redtext";
        }

        if (pedido.ship == 1) { // enviado / recibido
            return <Chip theme={theme} color='success' label={text} />;
        } else if (pedido.ship == 0) { // NO enviado / recibido
            return <Chip theme={theme} color='error' label={"NO " + text} />;
        } else {
            return <Chip theme={theme} color='warning' label={"NO APLICA"} />;
        }
    }



    render() {

        return (
            <div className='containerblock'>
                <div className="column">
                    <ToastContainer></ToastContainer>
                </div>
                <div className="table-container">
                    <table id="tablacompraventa" className="display  dataTable dtr-inline">
                        <thead>
                            <tr >
                                <th>Id</th>
                                <th>Fecha</th>
                                <th>Titulo</th>
                                <th>Tipo</th>
                                <th>Cartas</th>
                                <th>Precio Total</th>
                                <th>Estado</th>
                                <th>Ajustes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.compraventas.map(item => {
                                return <tr key={item.id} >

                                    <td className='centeredtext'>
                                        {item.id}
                                    </td>
                                    <td className='centeredtext'>
                                        {item.fecha}
                                    </td>
                                    <td>
                                        <Link className='firstline secondarycolor' to={`/pedido/${item.id}`}>
                                            {item.titulo}
                                        </Link>

                                    </td>
                                    <td className={this.gettextclass(item.tipo) + " centeredtext"}>
                                        {item.tipo ? <>Compra</> : <>Venta</>}
                                    </td>
                                    <td className='centeredtext'>
                                        {item.cantidad}
                                    </td>
                                    <td className={this.gettextclass(item.tipo) + " centeredtext"}>
                                        {item.total}
                                    </td>
                                    <td className='centeredtext'>
                                        {this.getStatus(item)}
                                    </td>
                                    <td>
                                        <Tooltip title="Edit">
                                            <Link to={`/pedido/${item.id}`}>
                                                <IconButton >
                                                    <EditIcon className='whitecolor' />
                                                </IconButton>
                                            </Link>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <IconButton>
                                                <DeleteIcon className='redtext' />
                                            </IconButton>
                                        </Tooltip>
                                        { /* <Link to={"/"} className="btn btn-danger btn-sm">Eliminar</Link> */}
                                    </td>

                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default TablaCompraventas;