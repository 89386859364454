import React from 'react';
import Constantes from "../Constantes";
import Session from '../classes/session';
import Loading from '../Functions/Loading';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablaCambios from './TablaCambios';
import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import AddBoxIcon from '@mui/icons-material/AddBox';
import GHistorialCambios from '../Components/Graficos/GHistorialCambios';

const VerCambios = (props) => {
    const [cambios, setCambios] = useState();
    const [loading, setLoading] = useState(true);


    // Llama una vez al iniciar
    useEffect(() => {
        componentDidMount();
    }, []);

    const componentDidMount = async () => {

        var uid = props.id_user
        //console.log("UID: " + uid)
        const respuesta = await fetch(`${Constantes.RUTA_API}/obtener_cambio.php?id=%&id_user=${uid}`);
        const c = await respuesta.json();
        setCambios(c);
        setLoading(false);


    }

    return (
        <div className="container">
            {loading ? (
                <Loading />
            )
                : (
                    <Grid container spacing={{ xs: 1, md: 2 }} >
                        <Grid item xs={12} md={12}>
                            <GHistorialCambios id_user={props.id_user} />
                        </Grid>
                        <Grid item xs={12} md={12} className='block'>
                            <Stack direction="row" spacing={1} className='containerheader'>
                                <Link className='firstline' to={`/cambio/0`} style={{ color: "green" }}>
                                    <AddBoxIcon fontSize="large" />
                                </Link>
                                <h4 >Cambios</h4>
                            </Stack>
                            <TablaCambios perpage={props.perpage} cambios={cambios} />
                        </Grid>
                    </Grid>
                )
            }
        </div >
    );

}

export default VerCambios;