import React from "react";
import Constantes from "../Constantes";

export const RefreshToken = async (props) => {



    let error = true;
    let tokens = []
    const requestOptions = await {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            name: localStorage.getItem('uid'),
            token: localStorage.getItem('rToken')
        })
    };

    console.log(requestOptions)

    await fetch(`${Constantes.RUTA_API_JS}/users/refreshToken`, requestOptions)
        .then(response => response.json())
        .then(response => {
            //console.log(response);
            if (response.status === 400) {
                //console.log("Refresh Token no valido " + response.status)
                console.log("Error 400 refresh token")
                localStorage.removeItem('uid')
                localStorage.removeItem('rToken')
                window.location.reload(false);
                throw new Error("Refresh Token no valido " + response.status);

            } else {
                //console.log("Token recibido OK - RefreshToken");
                tokens = response
                var today = new Date().getTime()
                localStorage.setItem('aToken', response.accessToken)
                localStorage.setItem('uid', response.username)
                localStorage.setItem('rToken', response.refreshToken)
                localStorage.setItem('aexpire', today)
            }

        })
        .catch(err => {
            //console.error(err)
            console.error("Error al refrescar Token")
            localStorage.removeItem('aToken')
            localStorage.removeItem('uid')
            window.location.reload(false);
        });



    return (tokens)


}

export const LoginUser = async (props) => {
    //console.log("ENTRA LOGIN")
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            name: props.user,
            password: props.pwd
        })
    };

    const api = await fetch(`${Constantes.RUTA_API_JS}/users/login`, requestOptions)
    //let response = await api.json();
    if (api.status === 200) {
        const response = await api.json();
        var today = new Date().getTime()
        localStorage.setItem('aToken', response.accessToken)
        localStorage.setItem('uid', response.userid)
        localStorage.setItem('theme', response.theme)
        localStorage.setItem('rToken', response.refreshToken)
        localStorage.setItem('aexpire', today)
        //console.log(response)
        return response
    } else { // Error autentificación

    }
    //console.log(api.status)


}