import React from 'react';
import { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from 'react-router-dom';

import OutboxIcon from '@mui/icons-material/Outbox';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';

const InfoBox = (props) => {
    const [itext, setText] = useState("Texto de muestra");
    const [ivalue, setValue] = useState(30);
    const [icolor, setColor] = useState("green");
    const [ilink, setLink] = useState("/");
    const [isHovering, setIsHovering] = useState(false);
    const [isSmall, setIsSmall] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    // Llama una vez al iniciar
    useEffect(() => {
        componentDidMount();
    });

    const componentDidMount = async () => {
        setIsLoading(true);
        if (props.text != undefined) {
            setText(props.text)
        }
        if (props.link != undefined) {
            setLink(props.link)
        }
        if (props.color != undefined) {
            setColor(props.color)
        }
        if (props.value != undefined) {
            setValue(convertToInternationalCurrencySystem(props.value))
        }
        setIsSmall(props.small)
        setIsLoading(false);
    }

    function convertToInternationalCurrencySystem(labelValue) {

        // Nine Zeroes for Billions
        return Math.abs(Number(labelValue)) >= 1.0e+9

            ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(1) + "B"
            // Six Zeroes for Millions 
            : Math.abs(Number(labelValue)) >= 1.0e+6

                ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(1) + "M"
                // Three Zeroes for Thousands
                : Math.abs(Number(labelValue)) >= 1.0e+3

                    ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(1) + "K"

                    : Math.abs(Number(labelValue));

    }

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    return (
        <>
            {isLoading ? (
                <Skeleton variant="rectangular" width={210} height={60} />
            ) : (
                <>
                    {isSmall ? (
                        <>
                            <Link to={ilink} className='home_data-card_small nodecoration' >
                                {itext}
                                {(typeof props.value2 !== 'undefined') ? (
                                    <>
                                        <span style={{ color: icolor, marginRight: "5px", fontSize: "2em", display: "inline-block" }}>{props.value}<OutboxIcon /> {props.value2}<MoveToInboxIcon /></span>
                                    </>
                                ) : (
                                    <>
                                        <span style={{ color: icolor, marginRight: "5px", fontSize: "2em", display: "inline-block" }}>{props.value}</span>
                                    </>
                                )}

                            </Link>
                        </>
                    ) : (
                        <>
                            <Link to={ilink} className='home_data-card' style={{ color: icolor }}>
                                <h3 style={{ borderBottom: "2px solid " + icolor }} >{props.value}</h3>
                                <h4>{itext}</h4>
                                <p></p>
                                <span class="home_link-text">
                                    Ver Todo &nbsp;
                                    <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.8631 0.929124L24.2271 7.29308C24.6176 7.68361 24.6176 8.31677 24.2271 8.7073L17.8631 15.0713C17.4726 15.4618 16.8394 15.4618 16.4489 15.0713C16.0584 14.6807 16.0584 14.0476 16.4489 13.657L21.1058 9.00019H0.47998V7.00019H21.1058L16.4489 2.34334C16.0584 1.95281 16.0584 1.31965 16.4489 0.929124C16.8394 0.538599 17.4726 0.538599 17.8631 0.929124Z" fill={icolor} />
                                    </svg>
                                </span>
                            </Link>
                        </>
                    )}

                </>
            )}
        </>
    );
}

export default InfoBox;