import React from 'react';
import { toast } from "react-toastify";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Loading from '../../Functions/Loading';
import Select from 'react-select';
import Constantes from "../../Constantes";
import { Stack } from '@mui/material';
import theme from "../../Theme/theme";
import { useState, useEffect } from "react";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import ApiFetch from '../../Functions/ApiFetch';
import ApiFile from '../../Functions/ApiFile';

const InfoCarta = (props) => {


    const [loading, setLoading] = useState(true);
    const [carta, setCarta] = useState(props.carta);
    const [colecciones, setColecciones] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null)
    const checks = ["auto", "patch", "numerada", "rookie", "grade"]

    useEffect(() => {
        componentDidMount();
    }, []);


    const componentDidMount = async () => {
        const respuesta = await fetch(`${Constantes.RUTA_API}/obtener_colecciones.php`);
        const c = await respuesta.json();

        let o = c.map(coleccion => {
            return { value: coleccion.id, label: coleccion.name }
        })

        setOptions(o)

        setColecciones(c)
        //setCarta(props.carta)
        setLoading(false);
    }


    // On file select (from the pop up) 
    // https://www.laravelcode.com/post/how-to-upload-files-in-reactjs-with-example
    const onFileChange = (event) => {
        selectedFile = event.target.files[0];
        //console.log(event.target.files[0]);
        // Update the state 
        setSelectedFile(event.target.files[0])
    }

    const manejarCambio = (evento) => {
        // Extraer la clave del estado que se va a actualizar, así como el valor
        const clave = evento.target.id;
        let valor = evento.target.value;
        console.log("Clave: " + clave + " Valor: " + valor)
        const cartaactualizada = carta;
        // Si es la calificación o el nombre, necesitamos castearlo a entero
        if (clave == "year" || clave == "quantity") {
            if (valor == "") {
                valor = 0;
            }
            valor = parseFloat(valor);
        }
        if (clave == "rookie") {
            valor = 0
        }
        // Actualizamos el valor del videojuego, solo en el campo que se haya cambiado
        cartaactualizada[clave] = valor;
        console.log("Clave: " + clave + " Valor: " + valor)
        console.log(cartaactualizada)
        setCarta(cartaactualizada)

    }

    const manejarCambioSelect = async (e) => {
        // e.value o e.label
        //console.log("Id coleccion seleccionada: " + e.value)
        carta.id_coleccion = e.value
        //console.log("Col: " + cartas[0].name)
        setCarta(carta)

    }
    const handleChange = (event) => {
        //setShowRC(event.target.checked);
        let v = 0
        if (event.target.checked) {
            v = 1
        }
        setCarta({
            ...carta,
            [event.target.name]: v,
        });
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'inherit',
            color: 'inherit',
            [`& .${provided.classNamePrefix}__input`]: {
                color: '#ffffff',
            },
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: 'inherit',
            color: 'inherit',
            [`& .${provided.classNamePrefix}__option`]: {
                color: '#ffffff',
            },
        }),
    };

    const manejarEnvioDeFormulario = async (evento) => {

        if (selectedFile != null) { // Si hay imagen
            var re = /(?:\.([^.]+))?$/;
            var ext = re.exec(selectedFile.name)[1];
            ext = ext.toLowerCase();

            const res = await ApiFile({ url: "/files/carta/upload", file: selectedFile, name: `${carta.id_coleccion}-${carta.num_carta}-${carta.year}.${ext}` });

            if (res.status === "uploaded") {

                carta.imgurl = Constantes.RUTA_IMG_CARTAS + carta.id_coleccion + "-" + carta.num_carta + "-" + carta.year + "." + ext;
                toast('Imagen (' + carta.imgurl + ') subida correctamente 🖼️', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,

                });
            }

        }



        evento.preventDefault();
        const cargaUtil = JSON.stringify(carta);
        //console.log(cargaUtil)


        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({
                carta: JSON.parse(cargaUtil)
            })
        };
        const ncartas = await ApiFetch({ requestOptions: requestOptions, url: "/carta/update" });

        const exitoso = ncartas.result;
        if (exitoso === "OK") {
            toast('Carta (' + carta.id + ') actualizada correctamente 🃏 ', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,

            });
        } else {
            toast.error("Error guardando. Intenta de nuevo");
        }

    }

    return (
        <div className='containerblock'>
            <form className="field" onSubmit={manejarEnvioDeFormulario}>
                <Grid container spacing={{ xs: 1, md: 2 }}>
                    <Grid item xs={12} md={12}>
                        <label className="form-label">Nombre:</label>
                        <input required placeholder="Lionel Messi" type="text" id="name"
                            onChange={(event) => {
                                setCarta(carta => ({ ...carta, name: event.target.value }));
                            }}
                            value={carta.name} className="form-control rainbowborder" />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <label className="form-label" htmlFor="precio">Colección:</label>
                        <Select
                            id="id_coleccion"
                            options={options}
                            styles={customStyles}
                            className="react-select"
                            classNamePrefix="react-select"
                            onChange={manejarCambioSelect}
                            value={
                                options.filter(option =>
                                    option.value === carta.id_coleccion)
                            }
                        />
                    </Grid>
                    <Grid item xs>
                        <FormControlLabel control=
                            {<Switch
                                checked={carta["auto"] ? (true) : (false)}
                                name={"auto"}
                                onChange={handleChange} />}
                            label={"Firma"} />
                    </Grid>
                    <Grid item xs>
                        <FormControlLabel control=
                            {<Switch
                                checked={carta["patch"] ? (true) : (false)}
                                name={"patch"}
                                onChange={handleChange} />}
                            label={"Patch"} />
                    </Grid>
                    <Grid item xs>
                        <FormControlLabel control=
                            {<Switch
                                checked={carta["numerada"] ? (true) : (false)}
                                name={"numerada"}
                                onChange={handleChange} />}
                            label={"Numerada"} />
                    </Grid>
                    <Grid item xs>
                        <FormControlLabel control=
                            {<Switch
                                checked={carta["rookie"] ? (true) : (false)}
                                name={"rookie"}
                                onChange={handleChange} />}
                            label={"Rookie"} />
                    </Grid>
                    <Grid item xs>
                        <FormControlLabel control=
                            {<Switch
                                checked={carta["grade"] ? (true) : (false)}
                                name={"grade"}
                                onChange={handleChange} />}
                            label={"Gradeada"} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <label className="form-label" htmlFor="cantidad">Número de carta:</label>
                        <input required placeholder="10" type="text" id="num_carta" onChange={(event) => {
                            setCarta(carta => ({ ...carta, num_carta: event.target.value }));
                        }} value={carta.num_carta} className="form-control rainbowborder" />

                        <label className="form-label">Equipo:</label>
                        <input required placeholder="FC Barcelona" type="text" id="team" value={carta.team} onChange={(event) => {
                            setCarta(carta => ({ ...carta, team: event.target.value }));
                        }} className="form-control rainbowborder" />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <label className="form-label">Año:</label>
                        <input required placeholder="2004" type="text" id="year" value={carta.year} onChange={(event) => {
                            setCarta(carta => ({ ...carta, year: event.target.value }));
                        }} className="form-control rainbowborder" />

                        <label className="form-label">Cantidad:</label>
                        <input required placeholder="0" type="text" id="quantity" value={carta.quantity} onChange={(event) => {
                            setCarta(carta => ({ ...carta, quantity: event.target.value }));
                        }} className="form-control rainbowborder" />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <label className="form-label" htmlFor="titulo">Imagen URL:</label>
                        <input required placeholder="test.jpg" type="text" id="imgurl" onChange={(event) => {
                            setCarta(carta => ({ ...carta, imgurl: event.target.value }));
                        }} value={carta.imgurl} className="form-control form-control-lg rainbowborder" />

                    </Grid>
                    <Grid item xs={12} md={12}>
                        <input className="custom-file-input" type="file" onChange={onFileChange} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <label className="form-label" htmlFor="comentario">Comentario::</label>
                        <textarea rows="4" cols="50" placeholder="Comentario.." type="number" id="comment" onChange={(event) => {
                            setCarta(carta => ({ ...carta, comment: event.target.value }));
                        }} className="form-control rainbowborder" defaultValue={carta.comment}></textarea>

                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Stack direction="row" spacing={1}>
                            <Button variant="contained" color="primary" onClick={manejarEnvioDeFormulario}>
                                Guardar
                            </Button>
                            <Button variant="contained" theme={theme} color='error' href='/cartas'>
                                Volver
                            </Button>
                        </Stack>

                    </Grid>
                </Grid>



            </form>
        </div>
    );




}

export default InfoCarta;