import React from 'react';
import Constantes from "../Constantes";
import { ToastContainer, toast } from 'react-toastify';
import { Link, Navigate } from 'react-router-dom';
import Select from 'react-select';
import BoxColeccion from './BoxColeccion';
import { useState, useEffect } from "react";
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/Pagination';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Loading from "../Functions/Loading";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import RowColeccion from './RowColeccion';
import { Stack } from '@mui/material';
import EditarColeccion from './EditarColeccion';
import theme from '../Theme/theme';
import { ThemeProvider } from '@mui/material';


const Colecciones = (props) => {
    const [colecciones, setColecciones] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredcol, setFilteredcol] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [count, setcount] = useState(0);
    const [add, setAddn] = useState(true);
    var PER_PAGE = 10;
    if (props.perpage !== undefined) {
        PER_PAGE = props.perpage;

    }




    // Llama una vez al iniciar
    useEffect(() => {
        componentDidMount();
    }, []);


    const componentDidMount = async () => {


        const respuesta = await fetch(`${Constantes.RUTA_API}/obtener_colecciones.php?uid=${props.id_user}`);
        const colecciones = await respuesta.json();
        setcount(Math.ceil(colecciones.length / PER_PAGE))
        //console.log("Col: " + colecciones[0].name)
        // "refrescar" el formulario
        if (PER_PAGE < 7) {
            setAddn(false);
        }
        setColecciones(colecciones)
        setFilteredcol(colecciones)
        setLoading(false)
    }

    const handleChange = (e, p) => {
        setCurrentPage(p);
    };

    const handleSearchChange = (e) => {
        const valor = e.target.value;
        //console.log(valor)
        setSearchTerm(valor)
        const filtered = colecciones.filter(
            (col) => col.name && col.name.toUpperCase().includes(valor.toUpperCase())
        );
        setcount(Math.ceil(filtered.length / PER_PAGE))
        setFilteredcol(filtered)

    }

    const currentData = () => {
        const begin = (currentPage - 1) * PER_PAGE;
        const end = begin + PER_PAGE;
        return filteredcol.slice(begin, end);
    }



    return (
        <div >
            {loading ? (
                <Loading />
            ) : (
                <div className='block' >
                    {add ? <EditarColeccion coleccion={{ id: 0, name: "", icon: "" }} /> : <></>}
                    <Stack direction="row" className='containerheader' spacing={1}>
                        <h4 >Colecciones</h4>
                    </Stack>
                    <Grid container className='containerblock' spacing={{ xs: 2, md: 2 }}>
                        <Grid item xs={12} md={9}>
                            <ThemeProvider theme={theme}>
                                <Pagination
                                    count={count}
                                    boundaryCount={1}
                                    siblingCount={0}
                                    size="large"
                                    page={currentPage}
                                    variant="outlined"
                                    color='secondary'
                                    onChange={handleChange}
                                />
                            </ThemeProvider>
                        </Grid>
                        <Grid item className="centeredtext" xs={12} md={3}>
                            <FormControl className='secondaryborderbottom' variant="outlined">
                                <InputLabel className='whitecolor' htmlFor="card-search">Buscar</InputLabel>
                                <OutlinedInput
                                    id="card-search"
                                    type="text"
                                    label="Search.."
                                    value={searchTerm}
                                    variant="outlined"
                                    theme={theme}
                                    color="secondary"
                                    onChange={handleSearchChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Search"
                                            >
                                                <SearchIcon className='whitecolor' />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12} className="box-container">
                            {/*{currentData().map(c => {
                                return <BoxColeccion key={c.id} info="" coleccion={c}></BoxColeccion>
                            })} */}

                            <TableContainer >
                                <Table aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className='whitecolor'>
                                                Logo
                                            </TableCell>
                                            <TableCell className='whitecolor'>
                                                Colección
                                            </TableCell>
                                            <TableCell className='whitecolor'>
                                                Cartas
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {currentData().map(c => {
                                            return (
                                                <RowColeccion key={c.id} info="" coleccion={c}></RowColeccion>
                                            )

                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>


                        </Grid>
                    </Grid>
                </div>
            )
            }


        </div >
    );
}

export default Colecciones;