import React from 'react';
import Constantes from "../Constantes";
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import { useState, useEffect } from "react";

import OutlinedInput from '@mui/material/OutlinedInput';

import Loading from "../Functions/Loading";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import RowAdded from '../Components/Cartas/RowAdded';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Swal from 'sweetalert2';
import NewMessageNotification from '../Components/Notificaciones/NewMessageNotification';
import CartaSeleccionada from '../Components/Cartas/CartaSeleccionada';

import ApiFetch from '../Functions/ApiFetch';


import { Input } from '@mui/material';
import { ConstructionOutlined } from '@mui/icons-material';
import ArchivosCambio from './ArchivosCambio';
import { Stack } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TextField from '@mui/material/TextField';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import theme from '../Theme/theme';

const getCurrentDate = (separator = '') => {

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
}

const EditarCambio = (props) => {
    const [colecciones, setColecciones] = useState([]);
    const [loading, setLoading] = useState(true);
    const [carta, setCarta] = useState({ name: "Selecciona carta", num_carta: "" });
    const [searchTerm, setSearchTerm] = useState("");
    const [cartas, setCartas] = useState([]);
    const [idcoleccion, setIdcoleccion] = useState(0);
    const [disabled, setDisabled] = useState(true);
    const [added, setAdded] = useState([]);
    const [year, setYear] = useState(2022);
    const [uniqueyears, setUniqueyears] = useState([]);
    const [counter, setCounter] = useState(0);
    const tiposcambio = [{ value: 1, label: "Ofrecido" }, { value: 0, label: "Recibido" }]
    const [tipo, setTipo] = useState(0);
    const [cambio, setCambio] = useState({ id_user: props.id_user, title: "", sent: 0, received: 0, id: 0, changeuser: "", cartas: [], idccambio: 0, date: getCurrentDate("-") });
    const [enviado, setEnviado] = useState(false);
    const [recibido, setRecibido] = useState(false);
    const [nuevo, setNuevo] = useState(true);
    const [ptitle, setPTitle] = useState("Crear nuevo cambio");
    var PER_PAGE = 10;
    var CryptoJS = require("crypto-js");

    if (props.perpage !== undefined) {
        PER_PAGE = props.perpage;
    }



    // Llama una vez al iniciar
    useEffect(() => {
        componentDidMount();
    }, []);


    const componentDidMount = async () => {
        var respuesta = null;
        if (props.idcambio != 0 && props.idcambio !== undefined) { // cargar cambio existente
            respuesta = await fetch(`${Constantes.RUTA_API}/obtener_cambio.php?id=${props.idcambio}`);
            var c = await respuesta.json();
            c = c[0]
            setEnviado(c.sent)
            setRecibido(c.received)

            respuesta = await fetch(`${Constantes.RUTA_API}/obtener_cartascambio.php?idcambio=${props.idcambio}&iduser=${props.id_user}`);
            const cts = await respuesta.json();
            if (cts.length > 0) {
                c.cartas = cts;
                setAdded(cts)
            } else {
                c.cartas = []
            }
            setPTitle("Editar cambio")
            setNuevo(false);
            if (c.address) {
                // Decrypt Address
                var bytes = CryptoJS.AES.decrypt(c.address, 'address@123');
                var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                c.address = decryptedData;
            }
            setCambio(c)

        }

        respuesta = await fetch(`${Constantes.RUTA_API}/obtener_colecciones.php?uid=${props.id_user}`);
        const colecciones = await respuesta.json();

        //console.log("Col: " + colecciones[0].name)
        // "refrescar" el formulario
        setColecciones(colecciones)
        setLoading(false)
    }

    const handleSelectChange = async (e) => {
        const coleccion = e.value;
        //console.log(valor)
        setIdcoleccion(coleccion)
        const requestOptions = {
            method: 'GET',
        };
        const ncartas = await ApiFetch({ requestOptions: requestOptions, url: "/cartas?idcoleccion=" + coleccion + "&iduser=" + props.id_user });
        const cartas = ncartas.data;
        setCartas(cartas);
        UniqueYears(cartas);
        setDisabled(false);

    }

    const UniqueYears = (cartas) => {


        const uniqueTags = [];
        cartas.map(c => {
            if (uniqueTags.indexOf(c.year) === -1 && c.year != null) {
                //console.log("añadido año: " + c.year)
                uniqueTags.push(c.year)
            }
        })
        //console.log(uniqueTags)
        setUniqueyears(uniqueTags)

    }

    const handleNumChange = (e) => {
        const valor = e.target.value;
        //console.log(valor)
        setSearchTerm(valor)

        const filtered = cartas.filter(
            (c) => c.num_carta.split("-")[0] == (valor) && c.year == year
        );
        var c = ""
        //console.log(filtered)
        if (filtered.length > 0) {
            c = filtered[0];
        } else {
            c = { name: "No encontrado", num_carta: 0 }
        }

        setCarta(c)
    }

    const handleYearChange = (e) => {
        const valor = e.value;
        //console.log("Set year: " + valor)
        setYear(valor)
    }

    const handleKeyPress = async (event) => {
        if (event.key === 'Enter' && carta.num_carta != 0) {
            //console.log('enter press here! ')
            setSearchTerm("")
            // Añadir carta a lista


            carta.completed = 0;
            carta.type = tipo;
            carta.id_cambio = cambio.id
            carta.id_user = props.id_user;
            carta.id_carta = carta.id;
            carta.cantidad = 1;
            //console.log(carta)
            var chg = cambio;
            var newc = true;
            if (tipo == 1 && enviado == true) {
                return toast.error("No puedes añadir más cartas si has confirmado el envio");
            } else if (tipo == 0 && recibido == true) {
                return toast.error("No puedes añadir más cartas si has confirmado la recepción del cambio");
            }

            // Comprobar si carta existe para no añadir nuevo registro y modificar la actual:
            chg.cartas = await Promise.all(chg.cartas.map(obj => {
                // 👇️ if id equals 2, update country property
                if (obj.id == carta.id && obj.type == tipo) {
                    newc = false;
                    carta.idccambio = obj.idccambio;
                    carta.num_cartas = obj.num_cartas + 1;
                    return { ...obj, num_cartas: carta.num_cartas };
                }

                // 👇️ otherwise return object as is
                return obj;
            }));

            if (newc) {
                carta.idccambio = 0;

                carta.num_cartas = 1;
                if (carta.quantity === null || carta.quantity === "") {
                    carta.oquantity = 0;
                    carta.quantity = 1;
                } else {
                    carta.oquantity = carta.quantity;
                    //carta.quantity = parseInt(carta.quantity + 1)
                }
            }

            const cargaUtil = JSON.stringify(carta);
            //console.log(carta)
            const respuesta = await fetch(`${Constantes.RUTA_API}/add_cartacambio.php`, {
                method: "PUT",
                body: cargaUtil,
            });

            let resp = await respuesta.json();
            if (resp) {
                if (newc) {
                    resp = JSON.parse(resp)
                    carta.idccambio = resp.id
                    chg.cartas = [carta, ...chg.cartas]
                    //setCounter(counter + 1)

                    setCambio(chg);
                } else {
                    setCambio(chg);
                }
            } else {
                toast.error("Error guardando. Intenta de nuevo");
            }

            setCarta({ name: "Selecciona carta", num_carta: "" })
        }
    }


    const manejarEnvioDeFormulario = async (evento) => {

    }



    const manejarCambioTipo = (e) => {
        setTipo(e.value)
    }

    const CambioEstado = async (tipo) => {
        // console.log(tipo.target.id) // enviado / recibido
        const modificar = await Swal.fire({
            title: 'Modificar cantidades',
            text: `Indica si quieres modificar las cantidades de las cartas`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3298dc',
            cancelButtonColor: '#f14668',
            cancelButtonText: 'No',
            confirmButtonText: 'Sí, modificar'
        });

        // Actualizar estado del pedido
        let c = cambio;
        let t = null;
        if (tipo.target.id === "enviado") {
            c.sent = 1;
            t = 1;
            setEnviado(true);
        } else {
            t = 0;
            c.received = 1;
            setRecibido(true)
        }
        setCambio(c)
        GuardarCambio();

        // Actualizar estado de las cartas
        // Si no modifica
        if (!modificar.value) {
            //console.log("NO modificar cantidad")

            return;
        } else {
            //console.log("Modificar cantidad")
            let cts = cambio.cartas.filter(
                (c) => c.type == t
            );

            cts.map(c => {
                c.completed = 1;
                c.ship = 1; // valor para funcion ModificarCantidad de backend
                c.tipo = 1; // valor para funcion ModificarCantidad de backend
                if (t == 0) { // recibir
                    c.cantidad = 0 + c.num_cartas
                } else { // enviar
                    c.cantidad = 0 - c.num_cartas
                }
                c.id_carta = c.id; // valor para funcion ModificarCantidad de backend
                c.id_user = props.id_user; // valor para funcion ModificarCantidad de backend
                ActualizarCartaCambio(c);

            })

            // Modify quantity
        }
    }

    const ActualizarCartaCambio = async (carta) => {
        const cargaUtil = JSON.stringify(carta);
        //console.log(cambio)
        let respuesta = "";
        respuesta = await fetch(`${Constantes.RUTA_API}/update_cartacambio.php`, {
            method: "PUT",
            body: cargaUtil,
        });

        const exitoso = await respuesta.json();
        if (exitoso) {
            let resp = JSON.parse(exitoso)
            //console.log(resp.id)
            toast(resp.text, {
                position: "top-right",
                autoClose: 7000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,

            });
        }
    }

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'inherit',
            border: '1px solid #fff',
            color: 'inherit',
            [`& .${provided.classNamePrefix}__input`]: {
                color: '#ffffff',

                border: '1px solid #fff',
            },
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: 'inherit',
            color: 'inherit',
            [`& .${provided.classNamePrefix}__option`]: {
                color: '#ffffff',
            },
        }),
    };

    const GuardarCambio = async () => {
        let ct = cambio;
        // Encrypt
        var ciphertext = CryptoJS.AES.encrypt(ct.address, 'address@123').toString();
        ct.address = ciphertext;
        const cargaUtil = JSON.stringify(ct);
        //console.log(ct)
        //console.log(cambio)
        let respuesta = "";
        respuesta = await fetch(`${Constantes.RUTA_API}/update_cambio.php`, {
            method: "PUT",
            body: cargaUtil,
        });

        const exitoso = await respuesta.json();
        if (exitoso) {
            let resp = JSON.parse(exitoso)
            //console.log(resp.id)
            toast(resp.text, {
                position: "top-right",
                autoClose: 7000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,

            });
            if (cambio.id == 0) {
                let c = cambio;
                c.id = resp.id
                setPTitle("Editar cambio")
                setCambio(c)
                setNuevo(false);
            }
            if (ct.address) {
                // Decrypt Address
                var bytes = CryptoJS.AES.decrypt(ct.address, 'address@123');
                var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                ct.address = decryptedData;
            }
        } else {
            toast.error("Error guardando. Intenta de nuevo");
        }
    }



    return (
        <div className="container">
            {loading ? (
                <Loading />
            ) : (
                <>
                    <ToastContainer></ToastContainer>

                    <form className="form" onSubmit={manejarEnvioDeFormulario}>
                        <Grid container className='block' sx={{ m: 2 }} spacing={{ xs: 2, md: 2 }} >
                            <Stack direction="row" spacing={1} className='containerheader'>
                                <h4 >{ptitle}</h4>
                            </Stack>
                            <Grid item xs={12} md={6}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel className='whitecolor' htmlFor="card-search">Título</InputLabel>
                                    <OutlinedInput
                                        required
                                        id="title"
                                        onChange={(event) => {
                                            setCambio(cambio => ({ ...cambio, title: event.target.value }));
                                        }}
                                        label="Título"
                                        placeholder="Cambio discord Topps XX"
                                        variant="standard"
                                        type="text"
                                        value={cambio.title}
                                        theme={theme}
                                        fullWidth
                                        color="secondary"
                                        className='whitecolor whitebordercolor'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel className='whitecolor' htmlFor="card-search">Cambio con</InputLabel>
                                    <OutlinedInput
                                        required
                                        id="changeuser"
                                        onChange={(event) => {
                                            setCambio(cambio => ({ ...cambio, changeuser: event.target.value }));
                                        }}
                                        label="Cambio con"
                                        placeholder="User_2387"
                                        variant="standard"
                                        type="text"
                                        value={cambio.changeuser}
                                        theme={theme}
                                        fullWidth
                                        color="secondary"
                                        className='whitecolor whitebordercolor'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel className='whitecolor' htmlFor="card-search">Fecha cambio</InputLabel>
                                    <OutlinedInput
                                        required
                                        id="date"
                                        onChange={(event) => {
                                            setCambio(cambio => ({ ...cambio, date: event.target.value }));
                                        }}
                                        label="Fecha"
                                        placeholder=""
                                        variant="standard"
                                        type="date"
                                        value={cambio.date}
                                        theme={theme}
                                        fullWidth
                                        color="secondary"
                                        className='whitecolor whitebordercolor'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel className='whitecolor' htmlFor="card-search">Dirección / Comentarios</InputLabel>
                                    <OutlinedInput
                                        required
                                        id="address"
                                        onChange={(event) => {
                                            setCambio(cambio => ({ ...cambio, address: event.target.value }));
                                        }}
                                        label="Dirección / Comentarios"
                                        placeholder=""
                                        variant="standard"
                                        type="date"
                                        value={cambio.address}
                                        theme={theme}
                                        rows={4}
                                        multiline
                                        fullWidth
                                        color="secondary"
                                        className='whitecolor whitebordercolor'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Button id="recibido" variant="contained" color="primary" onClick={GuardarCambio} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>

                    </form>
                    {!nuevo ? (<>
                        <Grid container className='block' spacing={{ xs: 2, md: 2 }} style={{ zIndex: 50 }}>

                            <Stack direction="row" spacing={1} className='containerheader'>
                                <h4 >Añadir cartas</h4>
                            </Stack>
                            <Grid item xs={12} md={7}>

                                <label className="form-label" htmlFor="precio">Selecciona colección:</label>
                                <Select
                                    id="id_coleccion"
                                    styles={customStyles}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    options={colecciones.map(c => {
                                        return {
                                            value: c.id, label: c.name
                                        }

                                    })}
                                    onChange={handleSelectChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={2} >
                                <label className="form-label">Selecciona año:</label>
                                <Select
                                    id="iyear"
                                    styles={customStyles}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    disabled={disabled}
                                    options={uniqueyears.map(c => {
                                        return {
                                            value: c, label: c
                                        }

                                    })}
                                    onChange={handleYearChange}
                                />

                            </Grid>
                            <Grid item xs={12} md={3} >

                            </Grid>
                            <Grid item xs={12} md={2} >
                                <label className="form-label">Selecciona tipo:</label>
                                <Select
                                    id="iyear"
                                    styles={customStyles}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    disabled={disabled}
                                    options={tiposcambio.map(c => {
                                        return {
                                            value: c.value, label: c.label
                                        }

                                    })}
                                    onChange={manejarCambioTipo}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} >
                                <label className="form-label">Num carta:</label>

                                <OutlinedInput
                                    id="card-search"
                                    type="text"
                                    label=""
                                    value={searchTerm}
                                    theme={theme}
                                    color="secondary"

                                    className="whitecolor whitebordercolor"
                                    variant="outlined"
                                    onChange={handleNumChange}
                                    onKeyPress={handleKeyPress}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} >
                                <CartaSeleccionada carta={carta} />
                            </Grid>

                        </Grid>
                        <Grid container sx={{ m: 2 }} spacing={{ xs: 2, md: 2 }} className='containerblock'>
                            <Grid item xs={12} md={5.5} className='block'>
                                <Stack direction="row" spacing={1} className='containerheader'>
                                    <h4 >
                                        {enviado ? (
                                            <>
                                                <CheckCircleIcon sx={{ color: 'green' }} style={{ marginRight: '10px' }} />

                                            </>) : (<></>)}
                                        Cartas Ofrecidas ({(cambio.cartas.filter(c => c.type === 1)).length})
                                    </h4>
                                </Stack>

                                {enviado ? (
                                    <>
                                    </>
                                ) : (

                                    <Button id="enviado" variant="contained" color="success" type="submit" onClick={CambioEstado} style={{ marginBottom: '10px' }}>
                                        Marcar como enviado
                                    </Button>
                                )}
                                <TableContainer sx={{ maxHeight: 440 }}>
                                    <Table aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className='whitecolor'>
                                                    Img
                                                </TableCell>
                                                <TableCell className='whitecolor'>
                                                    Num. Carta
                                                </TableCell>
                                                <TableCell className='whitecolor'>
                                                    Carta
                                                </TableCell>
                                                <TableCell className='whitecolor'>
                                                    Coleccion
                                                </TableCell>
                                                <TableCell className='whitecolor'>
                                                    Cantidad
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cambio.cartas.map(c => {
                                                if (c.type == 1) {
                                                    c.quantity = c.oquantity - c.num_cartas;
                                                    return (
                                                        <RowAdded key={c.idccambio} info="" carta={c}></RowAdded>
                                                    )
                                                }

                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12} md={5.5} className='block'>
                                <Stack direction="row" spacing={1} className='containerheader'>
                                    <h4 >
                                        {recibido ? (
                                            <>
                                                <CheckCircleIcon sx={{ color: 'green' }} style={{ marginRight: '10px' }} />

                                            </>) : (<></>)}
                                        Cartas a Recibir ({(cambio.cartas.filter(c => c.type === 0)).length})
                                    </h4>
                                </Stack>

                                {recibido ? (
                                    <>
                                    </>
                                ) : (
                                    <Button id="recibido" variant="contained" color="secondary" type="submit" onClick={CambioEstado} style={{ marginBottom: '10px' }}>
                                        Marcar como Recibido
                                    </Button>
                                )}
                                <TableContainer sx={{ maxHeight: 440 }}>
                                    <Table aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className='whitecolor'>
                                                    Img
                                                </TableCell>
                                                <TableCell className='whitecolor'>
                                                    Num. Carta
                                                </TableCell>
                                                <TableCell className='whitecolor'>
                                                    Carta
                                                </TableCell>
                                                <TableCell className='whitecolor'>
                                                    Coleccion
                                                </TableCell>
                                                <TableCell className='whitecolor'>
                                                    Cantidad
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {cambio.cartas.map(c => {
                                                if (c.type == 0) {
                                                    c.quantity = c.oquantity + c.num_cartas
                                                    return (
                                                        <RowAdded key={c.idccambio} info="" carta={c}></RowAdded>
                                                    )
                                                }

                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>

                        </Grid>
                        <div className='block'>
                            <ArchivosCambio id_cambio={cambio.id} />
                        </div>
                    </>
                    ) : (
                        <></>
                    )}

                </ >
            )
            }


        </div >
    );
}

export default EditarCambio;