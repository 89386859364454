import React from 'react';
import Constantes from "../Constantes";
import Session from '../classes/session';
import Loading from '../Functions/Loading';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablaCambios from './TablaCambios';
import { useState, useEffect } from "react";
import Button from '@mui/material/Button';
import TablaArchivos from './TablaArchivos';
import { ToastContainer, toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';

import ApiFetch from '../Functions/ApiFetch';
import ApiFile from '../Functions/ApiFile';
import axios from 'axios';


const ArchivosCambio = (props) => {
    const [idcambio, setIdcambio] = useState(0);
    const [title, setTitle] = useState("");
    const [archivos, setArchivos] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [newFileSt, setNewFileSt] = useState(false)
    const [loading, setLoading] = useState(true);
    const [uploading, setUploading] = useState(false);


    // Llama una vez al iniciar
    useEffect(() => {
        componentDidMount();
    }, []);

    const componentDidMount = async () => {

        if (props.id_cambio !== undefined) {
            setIdcambio(props.id_cambio)
            const requestOptions = {
                method: 'GET'
            };

            const narchivos = await ApiFetch({ requestOptions: requestOptions, url: "/files/cambio?idcambio=" + props.id_cambio });

            setArchivos(narchivos.data);
            setLoading(false);
        }


    }

    const onFileChange = (e) => {
        var file = e.target.files[0];
        // Update the state 
        setSelectedFile(file);
    }

    const fileData = () => {
        if (selectedFile) {
            return (
                <Grid item xs={12} md={6}>
                    <h4>File Details:</h4>
                    <p>File Name: {selectedFile.name}</p>
                    <p>File Type: {selectedFile.type}</p>
                </Grid>
            );
        } else {
            return (
                <div>
                    <br />

                </div>
            );
        }
    };

    const UploadFile = async () => {
        if (selectedFile != null) { // Upload File
            setUploading(true)

            const res = await ApiFile({ url: "/files/cambio/upload", file: selectedFile, name: idcambio + "_" + (archivos.length + 1) + "_" + selectedFile.name });


            if (res.status === "uploaded") {

                let archivo = {};
                const current = new Date();
                archivo.date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
                archivo.id_cambio = idcambio;
                archivo.title = title;
                archivo.name = idcambio + "_" + (archivos.length + 1) + "_" + selectedFile.name;
                archivo.type = selectedFile.type;
                archivo.path = "/assets/files/" + archivo.name;

                const cargaUtil = JSON.stringify(archivo);

                const requestOptions = {
                    method: 'POST',
                    body: JSON.stringify({
                        file: JSON.parse(cargaUtil)
                    })
                };
                const ncartas = await ApiFetch({ requestOptions: requestOptions, url: "/files/cambio/create" });

                const exitoso = ncartas.result;

                if (exitoso === "OK") {
                    let resp = ncartas
                    let ars = archivos
                    archivo.id = resp.id
                    //ars.push(archivo)
                    ars = [archivo, ...ars]
                    setArchivos(ars)
                    setUploading(false)
                    toast('Archivo (' + selectedFile.name + ') subido correctamente.', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,

                    });
                }
            } else {
                setUploading(false)
                toast('❌ Error al subir archivo ❌', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,

                });
                return "";
            }

        } else {
            toast('❌ Por favor selecciona el archivo que quieres añadir ❌', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,

            });
            return "";
        }
    }

    const AddFile = () => {

        if (newFileSt) { // Añadir Archivo
            return (
                <>
                    <Grid item xs={12} md={12}>
                        <Button id="recibido" variant="contained" color="error" onClick={NewFileChg} >
                            -
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Grid item xs={12} md={4}>
                            <input required placeholder="Ticket correos.." value={title} type="text" id="title"
                                onChange={(event) => {
                                    setTitle(event.target.value);
                                }}
                                className="form-control rainbowborder" />
                        </Grid>
                        <label className="form-label">Subir Archivo:</label>
                        <input className="form-control" type="file" onChange={onFileChange} />

                        {fileData()}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button id="recibido" variant="contained" color="primary" onClick={UploadFile} >
                            Añadir archivo
                        </Button>
                        {uploading ? (<>
                            <CircularProgress />
                        </>) : (<></>)}
                    </Grid>
                </>
            );
        } else {
            return ( // Mostrar botón de añadir
                <Grid item xs={12} md={6}>
                    <Button id="recibido" variant="contained" color="success" onClick={NewFileChg} >
                        +
                    </Button>
                </Grid>
            );
        }

    }

    const NewFileChg = () => {
        setNewFileSt(!newFileSt);
    }

    return (
        <div className="containerblock">
            {loading ? (
                <Loading />
            )
                : (
                    <Grid container spacing={{ xs: 1, md: 2 }}>
                        <Stack direction="row" spacing={1} className='containerheader'>
                            <h4 >Archivos adjuntos</h4>
                        </Stack>
                        {AddFile()}
                        <Grid item xs={12} md={12}>
                            <TablaArchivos archivos={archivos} />
                        </Grid>
                    </Grid>

                )
            }
        </div >
    );

}

export default ArchivosCambio;