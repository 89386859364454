import React from 'react';
import Constantes from "../Constantes";
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useState, useEffect } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CircularProgress, {
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { margin } from '@mui/system';


function CircularProgressWithLabel(
    props, value,
) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress size="12rem" color="success" variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="h4"
                    component="div"
                    color="text.secondary"
                >{`${(props.label)}`}</Typography>
            </Box>
        </Box>
    );
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

const InfoColeccion = (props) => {
    const [coleccion, setColeccion] = useState([]);
    const [cartas, setCartas] = useState([]);
    const [faltantes, setFaltantes] = useState([]);
    const [obtenidas, setObtenidas] = useState([]);
    useEffect(() => {
        setColeccion(props.coleccion)
        setCartas(props.cartas)
        setData(props.cartas)
    }, []);



    const setData = (cartas) => {
        const faltantes = cartas.filter(function (carta) {
            if (carta.quantity < 1) {
                return true;
            } else {
                return false;
            }
        });
        const obtenidas = cartas.filter(function (carta) {
            if (carta.quantity > 0) {
                return true;
            } else {
                return false;
            }
        });
        setFaltantes(faltantes.length)
        setObtenidas(obtenidas.length)
    }



    return (
        <Grid container spacing={{ xs: 1, md: 2 }} className='block'>
            <Grid item xs={12} md={12} className='centeredtext'>
                <Stack direction="row" className='containerheader' spacing={1}>
                    <h4 className="centeredtext" style={{ margin: 'auto', fontSize: '27px' }} >{coleccion.name}</h4>
                </Stack>

                <Stack direction="row" spacing={2} className="centeredtext">
                    <div className="centereddiv">
                        <Avatar
                            alt="Remy Sharp"
                            src={coleccion.icon}
                            sx={{ width: 150, height: 150 }}
                        />
                    </div>
                </Stack><h4
                    style={{ fontSize: '25px' }}>{(obtenidas + " / " + cartas.length)}</h4>
                <BorderLinearProgress variant="determinate" value={(obtenidas / cartas.length * 100)} style={{ marginBottom: '15px' }} />
                <Typography
                    className='centeredtext'
                    variant=""
                    component="div"
                    color="text.secondary"
                >{`${(faltantes + " cartas faltantes")}`}</Typography>
            </Grid>
        </Grid >
    )
}

export default InfoColeccion;