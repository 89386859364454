import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import 'jszip';
import 'pdfmake';
import "pdfmake/build/vfs_fonts.js";
import 'datatables.net-dt';
import 'datatables.net-autofill-dt';
import 'datatables.net-buttons-dt';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.flash';
import 'datatables.net-buttons/js/buttons.print';
import 'datatables.net-fixedcolumns-dt';
import 'datatables.net-fixedheader-dt';
import 'datatables.net-keytable-dt';
import 'datatables.net-responsive-dt';
import 'datatables.net-rowgroup-dt';
import 'datatables.net-searchbuilder-dt';
import 'datatables.net-searchpanes-dt';
import 'datatables.net-select-dt';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Chip from '@mui/material/Chip';
import theme from "../../Theme/theme";
import { Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';

import { Link } from 'react-router-dom';

import InfoBox from '../../Home/InfoBox';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class TablaCompraventasCarta extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            itemscompraventa: this.props.compraventas,
            beneficios: 0,
            gastos: 0,
            qtycompra: 0,
            qtyventa: 0,
            mediacompra: 0,
            mediaventa: 0,
        };
    }
    async componentDidMount() {

        const itemscompraventa = this.props.compraventas
        let beneficios = 0
        let gastos = 0
        let qtycompra = 0
        let qtyventa = 0
        console.log(itemscompraventa)

        {
            itemscompraventa.map(item => {
                if (item.tipo == 0) { // venta
                    beneficios += (item.precio * item.cantidad)
                    qtyventa += item.cantidad
                } else if (item.tipo == 1) { // compra
                    gastos += (item.precio * item.cantidad)
                    qtycompra += item.cantidad
                }

            })
        }

        this.setState({
            itemscompraventa: itemscompraventa,
            beneficios: beneficios,
            gastos: gastos,
            qtycompra: qtycompra,
            qtyventa: qtyventa,
            mediacompra: (gastos / qtycompra),
            mediaventa: (beneficios / qtyventa)
        });

        //initialize datatable
        $(document).ready(function () {
            setTimeout(function () {
                $('#tablaitemscompraventa').DataTable({
                    dom: 't',
                    order: [[0, "desc"]],
                    FixedHeader: true,
                    responsive: true,
                    autoFill: false,
                    keys: false,
                    bDestroy: true
                });
            }, 1000);

        });
    }



    render() {

        return (
            <div className='col-ms'>
                <div className="containerblock">
                    <Grid container spacing={{ xs: 1, md: 2 }}>
                        <Grid item xs={6} md={2.4}>
                            <InfoBox value={this.state.gastos.toFixed(2) + "€"} text={"Gastado (" + this.state.qtycompra + " compras)"} color={'red'} small={true} link={'#'} />
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <InfoBox value={this.state.mediacompra.toFixed(2) + "€"} text={"Precio medio de compra"} color={'red'} small={true} link={'#'} />
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <InfoBox value={this.state.beneficios.toFixed(2) + "€"} text={"Ganado (" + this.state.qtyventa.toString() + " ventas)"} color={'green'} small={true} link={'#'} />
                        </Grid>
                        <Grid item xs={6} md={2.4}>
                            <InfoBox value={this.state.mediaventa.toFixed(2) + "€"} text={"Precio medio de venta"} color={'green'} small={true} link={'#'} />
                        </Grid>
                        <Grid item xs={12} md={2.4}>
                            <InfoBox value={(this.state.beneficios - this.state.gastos).toFixed(2) + "€"} text={"Balance"} color={'orange'} small={true} link={'#'} />
                        </Grid>
                    </Grid >

                    <table id="tablaitemscompraventa" className="display  dataTable dtr-inline">
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Pedido</th>
                                <th>Tipo</th>
                                <th>Precio</th>
                                <th>Cantidad</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.itemscompraventa.map(item => {
                                return <tr key={item.id} >
                                    <td className='centeredtext'>
                                        {item.id}
                                    </td>
                                    <td>
                                        <Link className='firstline' to={`/pedido/${item.id}`}>
                                            {item.titulo}
                                        </Link>

                                    </td>
                                    <td className='centeredtext'>
                                        {item.tipo ? <Chip theme={theme} color='error' label="Compra" /> : <Chip theme={theme} color='success' label="Venta" />}
                                    </td>
                                    <td className='centeredtext'>
                                        {item.precio + "€"}
                                    </td>
                                    <td className='centeredtext'>
                                        {item.cantidad}
                                    </td>


                                </tr>
                            })}
                        </tbody>
                    </table>
                </div >
            </div >
        );
    }
}

export default TablaCompraventasCarta;