import React from 'react';
import Constantes from "../Constantes";
import { ToastContainer, toast } from 'react-toastify';
import { Link, Navigate } from 'react-router-dom';
import Select from 'react-select';
import AddItemCompraventa from './AddItemCompraventa';
import TablaCartasCompraventa from './TablaCartasCompraventa';
import IconButton from '@mui/material/IconButton';
import { Cached as CachedIcon } from '@mui/icons-material'
import Loading from '../Functions/Loading';
import Chip from '@mui/material/Chip';
import { Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import theme from "../Theme/theme";
import ContainerHeader from '../Functions/ContainerHeader';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ArchivosCambio from '../Cambios/ArchivosCambio';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';


class EditarCompraVenta extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            compraventa: {
                id: "0",
                "id_user": 0,
                "tipo": "",
                "fecha": "",
                "comentario": "",
                "titulo": "",
                loading: true,
            },
            itemscompraventa: [],
        };
        // Indicarle a las funciones a quién nos referimos con "this"
        this.manejarCambio = this.manejarCambio.bind(this);
        this.manejarEnvioDeFormulario = this.manejarEnvioDeFormulario.bind(this);
        this.onBtNext = this.onBtNext.bind(this);
    }



    async componentDidMount() {
        // Obtener ID de URL
        //const idVideojuego = this.props.match.params.id;
        this.setState({ loading: true });
        const queryParams = new URLSearchParams(window.location.search);
        var idcompraventa = 0
        if (queryParams.has("id")) {
            idcompraventa = queryParams.get('id');
            // Llamar a la API para obtener los detalles
            const respuesta = await fetch(`${Constantes.RUTA_API}/obtener_compraventa.php?id=${idcompraventa}`);
            const compraventa = await respuesta.json();

            const respuesta2 = await fetch(`${Constantes.RUTA_API}/obtener_itemscompraventa.php?id=${idcompraventa}`);
            const itemscompraventa = await respuesta2.json();

            itemscompraventa.tipo = compraventa.tipo
            itemscompraventa.id_user = compraventa.id_user
            // "refrescar" el formulario

            this.setState({
                compraventa: compraventa,
                itemscompraventa: itemscompraventa,
                loading: false,
            });
        } else {

            this.setState({
                loading: false,
            });
            // Nueva compraventa
        }

    }
    render() {
        if (this.state.loading) {
            return <Loading />
        }

        return (
            <div className="container">

                <ToastContainer></ToastContainer>
                <Grid container spacing={{ xs: 1, md: 2 }} className='containerblock'>
                    <Grid item xs={12} md={5.5} className='block'>
                        <ContainerHeader title="Pedido" chip={this.state.compraventa.id} />
                        <form onSubmit={this.manejarEnvioDeFormulario}>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="titulo">Título:</label>
                                <input required placeholder="Título pedido.." type="text" id="titulo" onChange={this.manejarCambio} value={this.state.compraventa.titulo} className="form-control form-control-lg rainbowborder" />

                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="tipo">Tipo:</label>
                                <select className="form-select form-select-lg rainbowborder" name="tipo" id="tipo" value={this.state.compraventa.tipo} onChange={this.manejarCambio}>
                                    <option value="-">-</option>
                                    <option value="1">Compra</option>
                                    <option value="0">Venta</option>

                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="precio">Fecha:</label>
                                <input required placeholder="Fecha compraventa.." type="date" id="fecha" onChange={this.manejarCambio} value={this.state.compraventa.fecha} className="form-control form-control-lg rainbowborder" />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="comentario">Comentario:</label>
                                <textarea rows="4" cols="50" placeholder="Comentario.." type="number" id="comentario" onChange={this.manejarCambio} className="form-control rainbowborder" value={this.state.compraventa.comentario} />
                            </div>
                            <div className="form-label">
                                <Stack direction="row" spacing={1}>
                                    <Button variant="contained" color="primary" type="submit" onClick={this.manejarEnvioDeFormulario}>
                                        Guardar
                                    </Button>
                                    <Button variant="contained" theme={theme} color='error' href='/compraventas'>
                                        Volver
                                    </Button>
                                </Stack>
                            </div>
                        </form>
                    </Grid>
                    <Grid item xs={12} md={5.5} className='block'>
                        <ContainerHeader title="Añadir Cartas al pedido" />
                        <AddItemCompraventa onBtNext={this.onBtNext} id_user={this.state.compraventa.id_user} idcompraventa={this.state.compraventa.id} />
                    </Grid>
                    <Grid item xs={12} md={12} className='block'>
                        <ContainerHeader title="Cartas pedido" chip={<IconButton size='large' color="primary" onClick={this.onBtNext}>
                            <CachedIcon />
                        </IconButton>} />
                        <TablaCartasCompraventa onBtNext={this.onBtNext} id_user={this.state.compraventa.id_user} tipo={this.state.compraventa.tipo} itemscompraventa={this.state.itemscompraventa} idcompraventa={this.state.compraventa.id} />
                    </Grid>
                    <Grid item xs={12} md={12} className='block'>
                        <ArchivosCambio id_cambio={this.state.compraventa.id + "00000"} />
                    </Grid>
                </Grid>
            </div>
        );
    }
    async manejarEnvioDeFormulario(evento) {

        evento.preventDefault();
        this.state.compraventa.id_user = this.props.id_user

        // Codificar nuestro videojuego como JSON
        const cargaUtil = JSON.stringify(this.state.compraventa);
        let respuesta = "";

        if (parseInt(this.state.compraventa.id) == 0) {
            // ¡Y enviarlo!
            respuesta = await fetch(`${Constantes.RUTA_API}/crear_compraventa.php`, {
                method: "POST",
                body: cargaUtil,
            });
        } else {
            // ¡Y enviarlo!
            respuesta = await fetch(`${Constantes.RUTA_API}/actualizar_compraventa.php`, {
                method: "PUT",
                body: cargaUtil,
            });
        }

        const exitoso = await respuesta.json();
        if (exitoso) {
            if (parseInt(this.state.compraventa.id) == 0) {
                let resp = JSON.parse(exitoso)
                this.onBtNext(resp.id)
            }
            toast('Compraventa (' + this.state.compraventa.id + ') guardada 💸', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,

            });

        } else {
            toast.error("Error guardando. Intenta de nuevo");
        }
    }
    manejarCambio(evento) {
        // Extraer la clave del estado que se va a actualizar, así como el valor

        const clave = evento.target.id;
        let valor = evento.target.value;
        this.setState(state => {
            const compraventaActualizada = state.compraventa;
            // Si es la calificación o el nombre, necesitamos castearlo a entero
            if (clave == "tipo") {
                valor = parseInt(valor);
            }
            // Actualizamos el valor del videojuego, solo en el campo que se haya cambiado
            compraventaActualizada[clave] = valor;
            return {
                compraventa: compraventaActualizada,
            }
        });
    }

    async onBtNext(id = 0) {
        // Obtener ID de URL
        //const idVideojuego = this.props.match.params.id;
        this.setState({ loading: true });
        const queryParams = new URLSearchParams(window.location.search);
        var idcompraventa = 0
        if (queryParams.has("id") || id != 0) {
            idcompraventa = queryParams.get('id');
            if (id != 0) {
                idcompraventa = id
            }
            // Llamar a la API para obtener los detalles
            const respuesta = await fetch(`${Constantes.RUTA_API}/obtener_compraventa.php?id=${idcompraventa}`);
            const compraventa = await respuesta.json();

            const respuesta2 = await fetch(`${Constantes.RUTA_API}/obtener_itemscompraventa.php?id=${idcompraventa}`);
            const itemscompraventa = await respuesta2.json();
            // "refrescar" el formulario
            compraventa.id_user = this.props.id_user
            this.setState({
                compraventa: compraventa,
                itemscompraventa: itemscompraventa,
                loading: false,
            });
        } else {
            // Nueva compraventa
        }

    }

}

export default EditarCompraVenta;