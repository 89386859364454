import React from 'react';
import Constantes from "../Constantes";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import 'jszip';
import 'pdfmake';
import "pdfmake/build/vfs_fonts.js";
import 'datatables.net-dt';
import 'datatables.net-autofill-dt';
import 'datatables.net-buttons-dt';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.flash';
import 'datatables.net-buttons/js/buttons.print';
import 'datatables.net-fixedcolumns-dt';
import 'datatables.net-fixedheader-dt';
import 'datatables.net-keytable-dt';
import 'datatables.net-responsive-dt';
import 'datatables.net-rowgroup-dt';
import 'datatables.net-searchbuilder-dt';
import 'datatables.net-searchpanes-dt';
import 'datatables.net-select-dt';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Select from 'react-select';
import { Store } from '@mui/icons-material'
import { Link } from "react-router-dom";
import Chip from '@mui/material/Chip';
import theme from "../Theme/theme";
import CardNameTeam from '../Components/TableCells/CardNameTeam';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility'; import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';


const shipOptions = [
    { value: 0, label: 'No' },
    { value: 1, label: 'Si' }
]

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class TablaPendientes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            itemscompraventa: this.props.itemscompraventa,
            idcompraventa: ""
        };
        this.manejarCambioSelect = this.manejarCambioSelect.bind(this);
    }
    async componentDidMount() {

        const itemscompraventa = this.props.itemscompraventa
        // console.log("IdCV: " + this.props.idcompraventa + " Items 2: " + JSON.stringify(itemscompraventa))

        this.setState({
            itemscompraventa: itemscompraventa
        });

        //initialize datatable
        $(document).ready(function () {
            setTimeout(function () {
                $('#tablaitemscompraventa').DataTable({
                    dom: 'frltip',
                    pageLength: 25,
                    lengthMenu: [[25, 50, 75, 100, -1], [25, 50, 75, 100, "Todos"]],
                    order: [[0, "desc"]],
                    FixedHeader: true,
                    responsive: true,
                    autoFill: false,
                    keys: false,
                    bDestroy: true
                });
            }, 1000);

        });
    }

    async manejarCambioSelect(e) {
        var valores = e.value.split("-")
        console.log(valores[0] + "-" + valores[2])
        if (valores[0] !== valores[2]) {
            //console.log("Ship seleccionado: " + valores[0] + " Id item: " + valores[1])

            var item = this.state.itemscompraventa.find(item => item.id == valores[1]);

            item.tipo = this.props.tipo
            item.id_user = this.props.id_user
            item.ship = valores[0]

            const cargaUtil = JSON.stringify(item);
            //console.log(cargaUtil)
            await fetch(`${Constantes.RUTA_API}/modify_quantity.php`, {
                method: "PUT",
                body: cargaUtil,
            });
            //console.log("resp: " + JSON.stringify(respuesta1))

            const respuesta = await fetch(`${Constantes.RUTA_API}/actualizar_ship.php?id=${valores[1]}&ship=${valores[0]}`);
            const resp = await respuesta.json();
            if (resp) {
                toast(item.name + ' - Envio modificado  📦 ', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    //onClose: this.props.onBtNext,
                    onClick: this.props.onBtNext,

                });
                //this.props.onBtNext()
            }

        }

    }

    render() {

        return (
            <div className='containerblock'>
                <table id="tablaitemscompraventa" className="display  dataTable dtr-inline">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Imagen</th>
                            <th>Carta</th>
                            <th>Colección</th>
                            <th>Tipo</th>
                            <th>Cantidad</th>
                            <th>Precio</th>
                            <th>Total</th>
                            <th>Ver pedido</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.itemscompraventa.map(item => {
                            return <tr key={item.id} className={
                                item.ship ? 'greentr' : ''
                            }>
                                <td>
                                    <span className="btn btn-secondary btn-sm"> {item.id}</span>
                                </td>
                                <td className='centeredtext'>
                                    <Avatar
                                        alt="Remy Sharp"
                                        src={item.imgurl.replace("./", "/")}
                                        sx={{ width: 65, height: 65 }}
                                    />
                                </td>
                                <td>
                                    <CardNameTeam firstline={item.name} secondline={item.team} id={item.id_carta} />
                                </td>
                                <td>
                                    <CardNameTeam firstline={item.coleccion} secondline={item.year} id="0" />
                                </td>
                                <td className='centeredtext'>
                                    {item.tipo ? <Chip theme={theme} color='error' label="Compra" /> : <Chip theme={theme} color='success' label="Venta" />}
                                </td>
                                <td className='centeredtext'>
                                    {item.cantidad}
                                </td>
                                <td className='centeredtext'>
                                    {item.precio + "€"}
                                </td>
                                <td className='centeredtext'>
                                    {(item.cantidad * parseFloat(item.precio)).toFixed(2) + "€"}
                                </td>
                                <td className='centeredtext'>
                                    <Tooltip title="Edit">
                                        <Link to={"/pedido/" + item.id_compraventa}>
                                            <IconButton>
                                                <VisibilityIcon />
                                            </IconButton>
                                        </Link>
                                    </Tooltip>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default TablaPendientes;