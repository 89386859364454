import React from "react";
import Constantes from "../../Constantes";

import CardItem from "./CardItem";
import { useState, useEffect } from "react";
import Pagination from '@mui/material/Pagination';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Loading from "../../Functions/Loading";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Select from 'react-select';
import FiltrosCartas from './FiltrosCartas';

import ApiFetch from "../../Functions/ApiFetch";

// Pagination: https://codesandbox.io/s/react-hooks-material-ui-pagination-example-trp9o?file=/src/App.js:553-719

const VerItemsCartas = (props) => {
    const [cartas, setCartas] = useState([]);
    const [filteredcartas, setFilteredCartas] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [maxPage, setMaxPage] = useState(0)

    const [count, setcount] = useState(0);
    let [page, setPage] = useState(1);
    const PER_PAGE = 20;
    const [colecciones, setColecciones] = useState([]);
    const [selectedCol, setSelectedCol] = useState("");
    //let _DATA = UsePagination([{}], PER_PAGE);

    // Llama una vez al iniciar
    useEffect(() => {
        searchCartas("");
    }, []);

    const handleChange = (e, p) => {
        setCurrentPage(p);
    };

    const handleSearchChange = (e) => {
        const valor = e.target.value;
        setSearchTerm(valor)
        const filtered = filteredcartas.filter(
            (carta) => carta.name && carta.name.toUpperCase().includes(valor.toUpperCase()) || carta.coleccion.toUpperCase().includes(valor.toUpperCase())
        );
        setcount(Math.ceil(filtered.length / PER_PAGE))
        setMaxPage(count)
        setFilteredCartas(filtered)

    }

    const searchCartas = async (search) => {

        const requestOptions = {
            method: 'GET',
        };
        const ncartas = await ApiFetch({ requestOptions: requestOptions, url: "/cartas?idcoleccion=%&iduser=" + props.id_user });
        let data = ncartas.data
        const respuesta2 = await fetch(`${Constantes.RUTA_API}/obtener_colecciones.php?uid=${props.id_user}`);
        const colecciones = await respuesta2.json();
        setColecciones(colecciones)


        setcount(Math.ceil(data.length / PER_PAGE))
        setMaxPage(count)
        setCartas(data);
        setFilteredCartas(data)
        setLoading(false)
    };

    const filteredData = (filtered) => {

        setcount(Math.ceil(filtered.length / PER_PAGE))
        setMaxPage(count)
        setFilteredCartas(filtered)
        setSearchTerm("")
        setCurrentPage(1);
    }

    const currentData = () => {
        const begin = (currentPage - 1) * PER_PAGE;
        const end = begin + PER_PAGE;
        return filteredcartas.slice(begin, end);
    }

    const removefilters = () => {
        setcount(Math.ceil(cartas.length / PER_PAGE))
        setSearchTerm("")
        setFilteredCartas(cartas)
    }

    const handleSelectChange = (e) => {
        const coleccion = e.value;
        const filtered = cartas.filter(
            (carta) => carta.id_coleccion && carta.id_coleccion == coleccion
        );
        setcount(Math.ceil(filtered.length / PER_PAGE))
        setMaxPage(count)
        setFilteredCartas(filtered)
        setCurrentPage(1)
        //setSelectedCol(coleccion);
    };


    return (
        <div className="container">
            {loading ? (
                <Loading />
            ) : (
                <>

                    <Grid container className='block' spacing={{ xs: 2, md: 2 }}>



                        <Grid item xs={12} md={3}>
                            <Pagination
                                count={count}
                                boundaryCount={1}
                                siblingCount={0}
                                size="large"
                                page={currentPage}
                                variant="outlined"
                                color="primary"
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Select
                                id="id_coleccion"
                                options={colecciones.map(c => {
                                    return {
                                        value: c.id, label: c.name
                                    }
                                })}
                                onChange={handleSelectChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={1}>
                            {filteredcartas.length} Cartas
                        </Grid>
                        <Grid item className="centeredtext" xs={12} md={3}>
                            <FormControl variant="outlined">
                                <InputLabel htmlFor="card-search">Buscar</InputLabel>
                                <OutlinedInput
                                    id="card-search"
                                    type="text"
                                    label="Search.."
                                    value={searchTerm}
                                    color="primary"
                                    variant="outlined"
                                    onChange={handleSearchChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="Search"
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item className="centeredtext" xs={12} md={12}>
                            <FiltrosCartas cartas={filteredcartas} set={filteredData} cur={currentData} removefilters={removefilters} />
                        </Grid>
                        <Grid item xs={12} md={12}>

                            {cartas.length > 0 ? (

                                <div className="ffbody">
                                    {
                                        currentData().map((carta) => (
                                            < CardItem carta={carta} key={carta.id} />

                                        ))
                                    }
                                </div>

                            ) : (
                                <div>No data</div>
                            )}
                            <br></br>
                            <Pagination
                                rowsPerPageOptions={[5, 10, 25, 50, { label: 'Todas', value: -1 }]}
                                count={count}
                                size="large"
                                page={currentPage}
                                variant="outlined"
                                color="primary"
                                onChange={handleChange}
                            />

                        </Grid>

                    </Grid>

                </>

            )}


        </div>
    )
}

export default VerItemsCartas;

