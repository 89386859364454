import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

class RowColeccion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {

        var imgurl = this.props.coleccion.icon;
        if (imgurl !== null && imgurl !== "") {
            imgurl = imgurl.replace("./", "/");
        }

        var info = this.props.info
        if (info === null || info === "") {
            info = false;
        } else {
            info = true
        }
        if (this.props.coleccion.quantity === null) {
            this.props.coleccion.quantity = 0;
        }

        return (

            <TableRow>
                <TableCell>
                    <Avatar
                        alt="Remy Sharp"
                        src={imgurl}
                        sx={{ width: 50, height: 50 }}
                    />
                </TableCell>
                <TableCell>
                    <Link className='nodecoration firstline' to={"/cartascoleccion/" + this.props.coleccion.id}>
                        {this.props.coleccion.name}
                        {" "}
                        <OpenInNewIcon className='secondarycolor' fontSize='8px' />
                    </Link>
                </TableCell>
                <TableCell className='whitecolor'>
                    <span className='secondarycolor'>
                        {this.props.coleccion.quantity}
                    </span>
                    {" / " + this.props.coleccion.totalquantity}

                </TableCell>
            </TableRow>


        );
    }
}

export default RowColeccion;