import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import theme from "../../Theme/theme";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

class FilaTablaEbay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // Si han eliminado este juego, no necesitamos mostrarlo
            eliminado: false,
        };

    }


    render() {
        let tipo = 0;
        let oferta = 1;
        let bgcolor = ""
        let datecolor = ""
        if (this.props.item.type[0] === "Auction") {
            tipo = 1;
        }
        if (this.props.item.ofertas[0] === "true") {
            oferta = 0;
        }
        if (!this.props.item.envio[0]) {

            this.props.item.envio[0] = "-";
        }
        if (this.props.item.price[0] < 25) {
            bgcolor = "DarkSeaGreen";
        }
        if (parseInt(this.props.item.timeleft.split(" ")[0]) < 1) {
            datecolor = "red"
        }
        return (
            <tr style={{ backgroundColor: bgcolor }}>
                <td >
                    <img
                        alt="Remy Sharp"
                        src={this.props.item.imgurl[0]}
                        sx={{ width: 100, height: 75 }}
                        height="60px"
                    />
                </td>
                <td><a className='nodecoration' href={this.props.item.url[0]} target="_blank">{this.props.item.title[0].substring(0, 50) + ".."}<OpenInNewIcon sx={{ width: 15 }} /></a></td>
                <td lassName='centeredtext'>
                    <span className='firstline' >{this.props.item.price[0] + this.props.item.currency[0]}</span>
                    <span className='secondline'>{tipo ? "(" + this.props.item.pujas[0] + " pujas)" : ""}</span>
                </td>
                <td lassName='centeredtext'>
                    {this.props.item.envio[0] + this.props.item.currency[0]}
                </td>
                <td lassName='centeredtext'>
                    {this.props.item.ubicacion[0]}
                </td>
                <td>
                    {tipo ? <Chip theme={theme} color='error' label="Subasta" variant="outlined" /> : <Chip theme={theme} color='success' label="Compra" variant="outlined" />}
                </td>
                <td className='centeredtext'>
                    {oferta ? <Chip theme={theme} color='error' label="No" variant="outlined" /> : <Chip theme={theme} color='success' label="Si" variant="outlined" />}
                </td>
                <td style={{ color: datecolor }}>

                    {this.props.item.timeleft}

                </td>
            </tr>
        );
    }
}

export default FilaTablaEbay;