import React from 'react';
import Constantes from "../Constantes";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import 'jszip';
import 'pdfmake';
import "pdfmake/build/vfs_fonts.js";
import 'datatables.net-dt';
import 'datatables.net-autofill-dt';
import 'datatables.net-buttons-dt';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.flash';
import 'datatables.net-buttons/js/buttons.print';
import 'datatables.net-fixedcolumns-dt';
import 'datatables.net-fixedheader-dt';
import 'datatables.net-keytable-dt';
import 'datatables.net-responsive-dt';
import 'datatables.net-rowgroup-dt';
import 'datatables.net-searchbuilder-dt';
import 'datatables.net-searchpanes-dt';
import 'datatables.net-select-dt';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Select from 'react-select';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { CheckCircle, Close, Email } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CardNameTeam from '../Components/TableCells/CardNameTeam';
import Button from '@mui/material/Button';
import theme from "../Theme/theme";


const shipOptions = [
    { value: 0, label: 'No' },
    { value: 1, label: 'Si' }
]

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class TablaCartasCompraventa extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            itemscompraventa: this.props.itemscompraventa,
            idcompraventa: ""
        };
        this.manejarCambioSelect = this.manejarCambioSelect.bind(this);
    }
    async componentDidMount() {

        const itemscompraventa = this.props.itemscompraventa

        this.setState({
            itemscompraventa: itemscompraventa
        });

        //initialize datatable
        $(document).ready(function () {
            setTimeout(function () {
                $('#tablaitemscompraventa').DataTable({
                    dom: 'frltip',
                    pageLength: 25,
                    lengthMenu: [[25, 50, 75, 100, -1], [25, 50, 75, 100, "Todos"]],
                    order: [[0, "desc"]],
                    FixedHeader: true,
                    responsive: true,
                    autoFill: true,
                    keys: false,
                    bDestroy: true
                });
            }, 1000);

        });
    }

    async manejarCambioSelect(e) {
        // 1-226-0 // Nuevo valor- id - viejo valor
        console.log("valores: " + JSON.stringify(e))
        var valores = e.value.split("-")
        if (valores[0] !== valores[2]) {

            var item = this.state.itemscompraventa.find(item => item.id == valores[1]);
            var index = this.state.itemscompraventa.findIndex(item => item.id == valores[1]);
            console.log(index)

            item.tipo = this.props.tipo
            item.id_user = this.props.id_user
            item.ship = valores[0]

            const cargaUtil = JSON.stringify(item);
            await fetch(`${Constantes.RUTA_API}/modify_quantity.php`, {
                method: "PUT",
                body: cargaUtil,
            });

            const respuesta = await fetch(`${Constantes.RUTA_API}/actualizar_ship.php?id=${valores[1]}&ship=${valores[0]}`);
            const resp = await respuesta.json();
            if (resp) {
                toast(item.name + ' - Envio modificado  📦 ', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    //onClose: this.props.onBtNext,
                    onClick: this.props.onBtNext,

                });
                //this.state.itemscompraventa.find(item => item.id == valores[1]) = item;
                this.state.itemscompraventa[index] = item
                console.log(JSON.stringify(this.state.itemscompraventa[index]))
                this.setState({
                    itemscompraventa: this.state.itemscompraventa
                });
                //this.props.onBtNext()
            }

        }

    }

    render() {

        return (
            <div className='containerblock'>
                <div className="table-container">
                    <table id="tablaitemscompraventa" className="display  dataTable dtr-inline">
                        <thead>
                            <tr>
                                <th data-priority="1">Imagen</th>
                                <th data-priority="3">Carta</th>
                                <th data-priority="4">Colección</th>
                                <th>Cantidad</th>
                                <th>Precio</th>
                                <th>Total</th>
                                <th data-priority="2">Enviado/Recibido</th>
                                <th>Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.itemscompraventa.map(item => {
                                return <tr key={item.id} className={
                                    item.ship ? 'greentr' : ''
                                }>
                                    <td className='centeredtext'>
                                        <img className='imgcartatabla' src={item.imgurl.replace("./", "/")} />
                                    </td>
                                    <td>
                                        <CardNameTeam firstline={item.name} secondline={item.team} id={item.id_carta} />
                                    </td>
                                    <td className='min-phone-l'>
                                        <CardNameTeam firstline={item.coleccion} secondline={item.year} id="0" />
                                    </td>
                                    <td className='centeredtext'>
                                        {item.cantidad}
                                    </td>
                                    <td className='centeredtext'>
                                        {item.precio + "€"}
                                    </td>
                                    <td className='centeredtext'>
                                        {(item.cantidad * parseFloat(item.precio)).toFixed(2) + "€"}
                                    </td>
                                    <td className='centeredtext'> {item.ship ? <CheckCircle color="primary" /> : <IconButton variant="contained" color="secondary"

                                        onClick={() => { this.manejarCambioSelect({ value: "1-" + item.id + "-" + item.ship, label: "Si" }) }}>
                                        <Email />
                                    </IconButton>}

                                        {/*<Select
                                            id="ship"
                                            onChange={this.manejarCambioSelect}
                                            options={[{ value: 0 + "-" + item.id + "-" + item.ship, label: 'No' },
                                            { value: 1 + "-" + item.id + "-" + item.ship, label: 'Si' }]}
                                            defaultValue={{ label: shipOptions[item.ship].label, value: item.ship }}
                                        /> */}
                                    </td>
                                    <td>
                                        <Tooltip title="Delete">
                                            <IconButton >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default TablaCartasCompraventa;