
import React from 'react';

function Loading() {
    const text = 'Cargando..';

    return (
        <div className="contentloading">
            <div className="loading">
                <p>{text}</p>
                <span></span>
            </div>
        </div>
    );
}

export default Loading;