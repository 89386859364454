import React from 'react';
import Constantes from "./Constantes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Navigate } from 'react-router-dom';
import InfoCarta from './Components/Cartas/InfoCarta';
import ImgCarta from './Components/Cartas/ImgCarta';
import Grid from '@mui/material/Grid';
import Loading from './Functions/Loading';
import TablaCompraventasCarta from './Components/Cartas/TablaCompraventasCarta';
import ContainerHeader from './Functions/ContainerHeader';
import InfoCartaEbay from './Components/Ebay/InfoCartaEbay';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';

import ApiFetch from './Functions/ApiFetch';

class EditarCarta extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            carta: {
                id: "",
                "name": "",
                "imgurl": "",
                "year": "",
                "quantity": 0,
            },
            compraventas: [],
            loading: true,
        };
        // Indicarle a las funciones a quién nos referimos con "this"
        this.manejarCambio = this.manejarCambio.bind(this);
        this.manejarEnvioDeFormulario = this.manejarEnvioDeFormulario.bind(this);
    }
    async componentDidMount() {
        // Obtener ID de URL
        //const idVideojuego = this.props.match.params.id;
        const queryParams = new URLSearchParams(window.location.search);
        var idcarta = 0
        if (queryParams.has("id")) {
            idcarta = queryParams.get('id');
            //console.log("ID:" + idcarta)
        } else {
            return <Navigate to={`/cartas`} />
        }
        // Llamar a la API para obtener los detalles
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({
                iduser: this.props.id_user
            })
        };
        const ncarta = await ApiFetch({ requestOptions: requestOptions, url: "/carta?idcarta=" + idcarta });
        const carta = ncarta.data[0];

        const respuesta2 = await fetch(`${Constantes.RUTA_API}/obtener_itemscompraventa.php?id_carta=${idcarta}`);
        const compraventas = await respuesta2.json();
        // "refrescar" el formulario
        this.setState({
            carta: carta,
            compraventas: compraventas,
            loading: false,
        });
    }
    render() {
        if (this.state.loading) {
            return <Loading />
        }

        return (
            <div className="container">

                <ToastContainer></ToastContainer>

                <Grid container spacing={{ xs: 1, md: 2 }} className='containerblock'>
                    <Grid item xs={12} md={5.5} className='block' >
                        <ContainerHeader title={this.state.carta.name} chip={this.state.carta.id} />
                        <ImgCarta carta={this.state.carta} />

                        {/*<Accordion className='block '>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                Historico de compras y ventas
                            </AccordionSummary>
                            <AccordionDetails>
                                <TablaCompraventasCarta compraventas={this.state.compraventas} />
                            </AccordionDetails>
                        </Accordion> */}

                    </Grid>
                    <Grid item xs={12} md={5.5} className='block'>
                        <ContainerHeader title="Editar Información" />
                        <InfoCarta carta={this.state.carta} />
                    </Grid>
                    <Grid item xs={12} md={12} className='block'>
                        {/*<InfoCartaEbay carta={this.state.carta} />*/}
                        <TablaCompraventasCarta compraventas={this.state.compraventas} />
                    </Grid>
                </Grid>

            </div>
        );
    }
    async manejarEnvioDeFormulario(evento) {

        evento.preventDefault();
        // Codificar nuestro videojuego como JSON

        const cargaUtil = JSON.stringify(this.state.carta);
        // ¡Y enviarlo!
        const respuesta = await fetch(`${Constantes.RUTA_API} / actualizar_videojuego.php`, {
            method: "PUT",
            body: cargaUtil,
        });
        const exitoso = await respuesta.json();
        if (exitoso) {
            toast('Videojuego guardado 🎮', {
                position: "top-left",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });

        } else {
            toast.error("Error guardando. Intenta de nuevo");
        }
    }
    manejarCambio(evento) {
        // Extraer la clave del estado que se va a actualizar, así como el valor
        const clave = evento.target.id;
        let valor = evento.target.value;
        this.setState(state => {
            const videojuegoActualizado = state.carta;
            // Si es la calificación o el nombre, necesitamos castearlo a entero
            if (clave !== "nombre") {
                valor = parseFloat(valor);
            }
            // Actualizamos el valor del videojuego, solo en el campo que se haya cambiado
            videojuegoActualizado[clave] = valor;
            return {
                carta: videojuegoActualizado,
            }
        });
    }
}

export default EditarCarta;