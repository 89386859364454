import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Link, parsePath } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { RemoveRedEye } from '@mui/icons-material';
import { Download } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import theme from "../Theme/theme";
import { useState, useEffect } from "react";




const TablaArchivos = (props) => {
    const [archivos, setArchivos] = useState([]);


    // Llama una vez al iniciar
    useEffect(() => {
        componentDidMount();
    }, [props]);

    const componentDidMount = async () => {
        var perpage = 25;
        if (props.perpage !== undefined) {
            perpage = props.perpage;
        }

        const c = props.archivos
        setArchivos(c)

        //initialize datatable
        $(document).ready(function () {
            setTimeout(function () {
                $('#tablacompraventa').DataTable({
                    dom: 'frltip',
                    pageLength: perpage,
                    lengthMenu: [[perpage, 50, 75, 100, -1], [perpage, 50, 75, 100, "Todos"]],
                    order: [[0, "desc"]],
                    FixedHeader: true,
                    responsive: true,
                    autoFill: false,
                    keys: false,
                    bDestroy: true
                });
            }, 1000);

        });
    }

    const OpenFile = (path) => {
        var filename = path.replace(/^.*[\\\/]/, '')
        //path = "http://localhost:3000/assets/files/Unknown.pdf"
        //Open the URL on new Window
        const link = document.createElement('a');
        link.href = path;
        link.setAttribute(
            'download',
            filename,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }


    return (
        <div className='containerblock'>
            <div className="column">
                <ToastContainer></ToastContainer>
            </div>
            <div className="table-container">
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell className="whitecolor">
                                    ID
                                </TableCell>
                                <TableCell className="whitecolor">
                                    Titulo
                                </TableCell>
                                <TableCell className="whitecolor">
                                    Path
                                </TableCell>
                                <TableCell className="whitecolor">
                                    Fecha
                                </TableCell>
                                <TableCell className="whitecolor">
                                    Type
                                </TableCell>
                                <TableCell className="whitecolor">
                                    Ver
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {archivos.map(item => {
                                return (
                                    <TableRow key={item.id}>
                                        <TableCell className="secondarycolor">
                                            {item.id}
                                        </TableCell>
                                        <TableCell>
                                            <Link className='firstline' to={`/cambio/${item.id}`}>
                                                {item.title}
                                            </Link>
                                        </TableCell>
                                        <TableCell className="whitecolor">
                                            {item.path}
                                        </TableCell>
                                        <TableCell className="whitecolor">
                                            {item.date}
                                        </TableCell>
                                        <TableCell className="whitecolor">
                                            {item.type}
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip title="See" >
                                                <a href={item.path} target="_blank">
                                                    <IconButton className="whitecolor">
                                                        <RemoveRedEye />
                                                    </IconButton>
                                                </a>
                                            </Tooltip>
                                            <Tooltip title="Download" className="whitecolor">
                                                <IconButton onClick={() => OpenFile(item.path)} >
                                                    <Download />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                )

                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

            </div>
        </div>
    );
}

export default TablaArchivos;