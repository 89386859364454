import React from 'react';
import Constantes from "../Constantes";
import Session from '../classes/session';
import TablaCompraventas from './TablaCompraventas';
import Loading from '../Functions/Loading';
import TablaPendientes from './TablaPendientes';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';
import Paper from '@mui/material/Paper';

class VerCartasPendientes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            compraventas: [],
            iduser: "",
            loading: true,
            itemscompraventa: [],
        };
    }
    async componentDidMount() {

        var uid = localStorage.getItem('uid')
        //console.log("UID: " + uid)
        const respuesta = await fetch(`${Constantes.RUTA_API}/obtener_cartaspendientes.php?uid=${uid}`);
        const itemscompraventa = await respuesta.json();
        this.setState({
            itemscompraventa: itemscompraventa,
            iduser: uid,
            loading: false,
        });


    }


    render() {
        if (this.state.loading) {
            return <Loading />
        }

        return (
            <div className="container">
                <Grid container spacing={{ xs: 1, md: 2 }} className='block' >
                    <Grid item xs={12} md={12}>
                        <Stack direction="column" className='containerheader' spacing={1}>
                            <h4 className="display-4">Cartas pendientes de enviar / recibir</h4>
                        </Stack>
                        <TablaPendientes onBtNext={this.onBtNext} id_user={this.state.id_user} tipo={this.state.itemscompraventa.tipo} itemscompraventa={this.state.itemscompraventa} idcompraventa={this.state.itemscompraventa.idcv} />
                    </Grid>
                </Grid>

            </div >
        );
    }
}

export default VerCartasPendientes;