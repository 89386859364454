import React from 'react';
import Constantes from "../Constantes";
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import { useState, useEffect } from "react";

import OutlinedInput from '@mui/material/OutlinedInput';

import Loading from "../Functions/Loading";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import RowAddedPedido from './RowAddedPedido';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Swal from 'sweetalert2';
import NewMessageNotification from '../Components/Notificaciones/NewMessageNotification';
import CartaSeleccionada from '../Components/Cartas/CartaSeleccionada';


import ArchivosCambio from '../Cambios/ArchivosCambio';
import { Stack } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TextField from '@mui/material/TextField';

import ApiFetch from '../Functions/ApiFetch';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import theme from '../Theme/theme';

const getCurrentDate = (separator = '') => {

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
}

const EditarPedido = (props) => {
    const [colecciones, setColecciones] = useState([]);
    const [loading, setLoading] = useState(true);
    const [carta, setCarta] = useState({ name: "Selecciona carta", num_carta: "", precio: 0, cantidad: 1 });
    const [searchTerm, setSearchTerm] = useState("");
    const [cartas, setCartas] = useState([]);
    const [idcoleccion, setIdcoleccion] = useState(0);
    const [disabled, setDisabled] = useState(true);
    const [added, setAdded] = useState([]);
    const [year, setYear] = useState(2022);
    const [uniqueyears, setUniqueyears] = useState([]);
    const [tipo, setTipo] = useState(0);
    const [tipos, setTipos] = useState([{ value: 1, label: "Compra" }, { value: 0, label: "Venta" }]);
    const [pedido, setPedido] = useState({ id_user: props.id_user, titulo: "", ship: 0, tipo: 0, id: 0, comentario: "", cartas: [], fecha: getCurrentDate("-") });
    const [enviado, setEnviado] = useState(false);
    const [nuevo, setNuevo] = useState(true);
    const [ptitle, setPTitle] = useState("Crear nuevo Pedido");
    var PER_PAGE = 10;
    var CryptoJS = require("crypto-js");

    if (props.perpage !== undefined) {
        PER_PAGE = props.perpage;
    }



    // Llama una vez al iniciar
    useEffect(() => {
        componentDidMount();
    }, []);


    const componentDidMount = async () => {
        var respuesta = null;
        if (props.idpedido != 0 && props.idpedido !== undefined) { // cargar cambio existente
            respuesta = await fetch(`${Constantes.RUTA_API}/obtener_compraventa.php?id=${props.idpedido}`);
            var c = await respuesta.json();

            setEnviado(c.ship)
            //setRecibido(c.received)

            respuesta = await fetch(`${Constantes.RUTA_API}/obtener_itemscompraventa.php?id=${props.idpedido}`);
            const cts = await respuesta.json();
            if (cts.length > 0) {
                c.cartas = cts;
                setAdded(cts)
            } else {
                c.cartas = []
            }
            setPTitle("Editar pedido")
            setNuevo(false);
            if (c.comentario != null && c.comentario.startsWith("U2F")) {
                // Decrypt Address
                var bytes = CryptoJS.AES.decrypt(c.comentario, 'address@123');
                var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                c.comentario = decryptedData;

            } else if (c.comentario == null) {
                c.comentario = ""
            }
            setPedido(c)

        }

        respuesta = await fetch(`${Constantes.RUTA_API}/obtener_colecciones.php?uid=${props.id_user}`);
        const colecciones = await respuesta.json();

        //console.log("Col: " + colecciones[0].name)
        // "refrescar" el formulario
        setColecciones(colecciones)
        setLoading(false)
    }

    const handleSelectChange = async (e) => {
        const coleccion = e.value;
        //console.log(valor)
        setIdcoleccion(coleccion)
        const requestOptions = {
            method: 'GET',
        };
        const ncartas = await ApiFetch({ requestOptions: requestOptions, url: "/cartas?idcoleccion=" + coleccion + "&iduser=" + props.id_user });
        const cartas = ncartas.data;
        setCartas(cartas);
        UniqueYears(cartas);
        setDisabled(false);

    }

    const UniqueYears = (cartas) => {


        const uniqueTags = [];
        cartas.map(c => {
            if (uniqueTags.indexOf(c.year) === -1 && c.year != null) {
                //console.log("añadido año: " + c.year)
                uniqueTags.push(c.year)
            }
        })
        //console.log(uniqueTags)
        setUniqueyears(uniqueTags)

    }

    const handleNumChange = (e) => {
        const valor = e.target.value;
        //console.log(valor)
        setSearchTerm(valor)

        const filtered = cartas.filter(
            (c) => c.num_carta.split("-")[0] == (valor) && c.year == year
        );
        var c = ""
        //console.log(filtered)
        if (filtered.length > 0) {
            c = filtered[0];
        } else {
            c = { name: "No encontrado", num_carta: 0 }
        }

        setCarta(c)
    }

    const handleYearChange = (e) => {
        const valor = e.value;
        //console.log("Set year: " + valor)
        setYear(valor)
    }

    const handleKeyPress = async (event) => {
        if (event.key === 'Enter' && carta.num_carta != 0) {
            //console.log('enter press here! ')
            setSearchTerm("")
            // Añadir carta a lista


            carta.ship = 0;
            carta.idcompraventa = pedido.id
            carta.id_user = props.id_user;
            carta.id_carta = carta.id;
            carta.id_coleccion = idcoleccion;
            carta.precio = carta.precio + "";
            if (carta.cantidad == null) {
                carta.cantidad = 1;
            }
            if (carta.precio == null) {
                carta.precio = 0;
            }
            //console.log(carta)
            var chg = pedido;
            var newc = true;
            if (enviado == true) {
                return toast.error("No puedes añadir más cartas si has confirmado el envio/recepción del pedido");
            }


            const cargaUtil = JSON.stringify(carta);
            //console.log(carta)
            const respuesta = await fetch(`${Constantes.RUTA_API}/crear_itemcompraventa.php`, {
                method: "PUT",
                body: cargaUtil,
            });

            let resp = await respuesta.json();
            if (resp) {
                if (newc) {
                    resp = JSON.parse(resp)
                    carta.idccambio = resp.id
                    carta.id = resp.id
                    chg.cartas = [carta, ...chg.cartas]
                    //setCounter(counter + 1)

                    setPedido(chg);
                } else {
                    setPedido(chg);
                }
            } else {
                toast.error("Error guardando. Intenta de nuevo");
            }

            setCarta({ name: "Selecciona carta", num_carta: "", precio: 0, cantidad: 1 })
        }
    }


    const manejarEnvioDeFormulario = async (evento) => {

    }



    const manejarCambioTipo = (e) => {
        setTipo(e.value)
    }

    const CambioEstado = async (tipo) => {
        const modificar = await Swal.fire({
            title: 'Modificar cantidades',
            text: `Indica si quieres modificar las cantidades de las cartas`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3298dc',
            cancelButtonColor: '#f14668',
            cancelButtonText: 'No',
            confirmButtonText: 'Sí, modificar'
        });

        // Actualizar estado del pedido
        let c = pedido;
        let t = null;
        if (tipo.target.id === "enviado") {
            c.ship = 1;
            t = 1;
            setEnviado(true);
        }
        setPedido(c)
        GuardarPedido();

        // Actualizar estado de las cartas
        // Si no modifica
        if (!modificar.value) {
            //console.log("NO modificar cantidad")
            //GuardarPedido();
            return;
        } else {
            //console.log("Modificar cantidad")
            let cts = pedido.cartas

            cts.map(c => {
                c.completed = 1;
                if (c.ship == 0) {
                    c.ship = 1; // valor para funcion ModificarCantidad de backend
                    c.tipo = pedido.tipo; // valor para funcion ModificarCantidad de backend                
                    c.id_carta = c.id_carta; // valor para funcion ModificarCantidad de backend
                    c.id_user = props.id_user; // valor para funcion ModificarCantidad de backend
                    ActualizarCartaCambio(c);
                }


            })

            // Modify order status

        }
    }

    const ActualizarCartaCambio = async (carta) => {
        const cargaUtil = JSON.stringify(carta);
        //console.log(cambio)
        let respuesta = "";
        respuesta = await fetch(`${Constantes.RUTA_API}/modify_quantity.php`, {
            method: "PUT",
            body: cargaUtil,
        });

        const exitoso = await respuesta.json();
        const respuesta2 = await fetch(`${Constantes.RUTA_API}/actualizar_ship.php?id=${carta.id}&ship=1`);
        const resp2 = await respuesta2.json();
        //console.log(carta.id)
        if (exitoso) {
            let resp = JSON.parse(exitoso)
            //console.log(resp.id)
            toast(resp.text, {
                position: "top-right",
                autoClose: 7000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,

            });
        }
    }
    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'inherit',
            color: 'inherit',
            [`& .${provided.classNamePrefix}__input`]: {
                color: '#ffffff',
            },
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: 'inherit',
            color: 'inherit',
            [`& .${provided.classNamePrefix}__option`]: {
                color: '#ffffff',
            },
        }),
    };

    const GuardarPedido = async () => {
        let ct = pedido;
        // Encrypt
        var ciphertext = CryptoJS.AES.encrypt(ct.comentario, 'address@123').toString();
        ct.comentario = ciphertext;
        const cargaUtil = JSON.stringify(ct);
        //console.log(ct)
        //console.log(cambio)
        let respuesta = "";

        if (parseInt(pedido.id) == 0) {
            // ¡Y enviarlo!
            respuesta = await fetch(`${Constantes.RUTA_API}/crear_compraventa.php`, {
                method: "POST",
                body: cargaUtil,
            });
        } else {
            // ¡Y enviarlo!
            respuesta = await fetch(`${Constantes.RUTA_API}/actualizar_compraventa.php`, {
                method: "PUT",
                body: cargaUtil,
            });
        }

        const exitoso = await respuesta.json();
        //console.log(exitoso)
        if (exitoso) {
            if (ct.comentario.startsWith("U2F")) {
                // Decrypt Address
                var bytes = CryptoJS.AES.decrypt(ct.comentario, 'address@123');
                var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                ct.comentario = decryptedData;
            }
            let resp = JSON.parse(exitoso)
            //console.log(resp.id)
            toast(resp.text, {
                position: "top-right",
                autoClose: 7000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,

            });
            if (ct.id == 0) {
                //let c = cambio;
                ct.id = resp.id
                setPTitle("Editar cambio")
                setPedido(ct)
                setNuevo(false);
            }
            if (ct.address) {
                // Decrypt Address
                var bytes = CryptoJS.AES.decrypt(ct.address, 'address@123');
                var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
                ct.address = decryptedData;
            }
        } else {
            toast.error("Error guardando. Intenta de nuevo");
        }
    }



    return (
        <div className="container">
            {loading ? (
                <Loading />
            ) : (
                <>
                    <ToastContainer></ToastContainer>

                    <form className="form" onSubmit={manejarEnvioDeFormulario}>
                        <Grid container className='block' sx={{ m: 2 }} spacing={{ xs: 2, md: 2 }} >
                            <Stack direction="row" spacing={1} className='containerheader'>
                                <h4 >{ptitle}</h4>
                            </Stack>
                            <Grid item xs={12} md={2}>
                                <Select
                                    id="tipo"
                                    name='tipo'
                                    options={tipos}
                                    styles={customStyles}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    onChange={(event) => {
                                        setPedido(pedido => ({ ...pedido, tipo: event.value }));
                                    }}
                                    value={
                                        tipos.filter(option =>
                                            option.value === pedido.tipo)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel className='whitecolor' htmlFor="card-search">Fecha</InputLabel>
                                    <OutlinedInput
                                        required
                                        onChange={(event) => {
                                            setPedido(pedido => ({ ...pedido, fecha: event.target.value }));
                                        }}
                                        label="Fecha"
                                        variant="standard"
                                        type="date"
                                        id="date"
                                        value={pedido.fecha}
                                        theme={theme}
                                        fullWidth
                                        color="secondary"
                                        className='whitecolor whitebordercolor'
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <FormControl variant="outlined" className='' fullWidth>
                                    <InputLabel className='whitecolor' htmlFor="card-search">Título</InputLabel>
                                    <OutlinedInput
                                        required
                                        id="title"
                                        onChange={(event) => {
                                            setPedido(pedido => ({ ...pedido, titulo: event.target.value }));
                                        }}
                                        label="Título"
                                        placeholder="Pedido de Ebay cartas FCB"
                                        variant="standard"
                                        type="text"
                                        value={pedido.titulo}
                                        theme={theme}
                                        fullWidth
                                        color="secondary"
                                        className='whitecolor whitebordercolor'
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={12}>

                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel className='whitecolor' htmlFor="card-search">Dirección de envío / Comentario</InputLabel>
                                    <OutlinedInput
                                        required
                                        id="address"
                                        onChange={(event) => {
                                            setPedido(pedido => ({ ...pedido, comentario: event.target.value }));
                                        }}
                                        label="Dirección de envío / Comentario"
                                        placeholder="Introduce la dirección.."
                                        variant="standard"
                                        type="text"
                                        value={pedido.comentario}
                                        fullWidth
                                        rows={4}
                                        multiline
                                        theme={theme}
                                        color="secondary"
                                        className='whitecolor whitebordercolor'
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={5}>
                                <Button id="recibido" variant="contained" color="primary" onClick={GuardarPedido} >
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>

                    </form>
                    {!nuevo ? (<>
                        {enviado ? (
                            <></>) : (<>
                                <Grid container className='block' spacing={{ xs: 2, md: 2 }} style={{ zIndex: 50 }}>

                                    <Stack direction="row" spacing={1} className='containerheader'>
                                        <h4 >Añadir cartas</h4>
                                    </Stack>
                                    <Grid item xs={12} md={7}>

                                        <label className="form-label" htmlFor="precio">Selecciona colección:</label>
                                        <Select
                                            id="id_coleccion"
                                            styles={customStyles}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            options={colecciones.map(c => {
                                                return {
                                                    value: c.id, label: c.name
                                                }

                                            })}
                                            onChange={handleSelectChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2} >
                                        <label className="form-label">Selecciona año:</label>
                                        <Select
                                            id="iyear"
                                            styles={customStyles}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            disabled={disabled}
                                            options={uniqueyears.map(c => {
                                                return {
                                                    value: c, label: c
                                                }

                                            })}
                                            onChange={handleYearChange}
                                        />

                                    </Grid>
                                    <Grid item xs={12} md={3} >

                                    </Grid>
                                    <Grid item xs={12} md={2} >
                                        <label className="form-label">Introduce número de carta:</label>
                                        <OutlinedInput
                                            id="card-search"
                                            type="text"
                                            label="Num carta.."
                                            value={searchTerm}
                                            theme={theme}
                                            className="whitecolor whitebordercolor"
                                            color="secondary"
                                            variant="outlined"
                                            onChange={handleNumChange}
                                            onKeyPress={handleKeyPress}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2} >
                                        <label className="form-label">Introduce precio por unidad:</label>
                                        <OutlinedInput
                                            id="card-price"
                                            type="number"
                                            className="whitecolor whitebordercolor"
                                            label="Precio.."
                                            value={carta.precio}
                                            color="primary"
                                            variant="outlined"
                                            onChange={(event) => {
                                                setCarta(carta => ({ ...carta, precio: event.target.value }));
                                            }}
                                            onKeyPress={handleKeyPress}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2} >
                                        <label className="form-label">Introduce cantidad:</label>
                                        <OutlinedInput
                                            id="card-price"
                                            type="number"
                                            label="Cantidad.."
                                            className="whitecolor whitebordercolor"
                                            value={carta.cantidad}
                                            color="primary"
                                            variant="outlined"
                                            onChange={(event) => {
                                                setCarta(carta => ({ ...carta, cantidad: event.target.value }));
                                            }}
                                            onKeyPress={handleKeyPress}
                                            disabled={disabled}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4} >
                                        <CartaSeleccionada carta={carta} />
                                    </Grid>

                                </Grid>
                            </>)}
                        <Grid item xs={12} md={12} className='block'>
                            <Stack direction="row" spacing={1} className='containerheader'>
                                <h4 >
                                    {enviado ? (
                                        <>
                                            <CheckCircleIcon sx={{ color: 'green' }} style={{ marginRight: '10px' }} />

                                        </>) : (<></>)}
                                    Cartas ({pedido.cartas.length})
                                </h4>
                            </Stack>

                            {enviado ? (
                                <>
                                </>
                            ) : (

                                <Button id="enviado" variant="contained" color="success" type="submit" onClick={CambioEstado} style={{ marginBottom: '10px' }}>
                                    Marcar como enviado / recibido
                                </Button>
                            )}
                            <TableContainer sx={{ maxHeight: 440 }}>
                                <Table aria-label="sticky table">
                                    <TableHead>
                                        <TableRow >
                                            <TableCell className="whitecolor">
                                                Img
                                            </TableCell>
                                            <TableCell className="whitecolor">
                                                Num. Carta
                                            </TableCell>
                                            <TableCell className="whitecolor">
                                                Carta
                                            </TableCell>
                                            <TableCell className="whitecolor">
                                                Coleccion
                                            </TableCell>
                                            <TableCell className="whitecolor">
                                                Precio
                                            </TableCell>
                                            <TableCell className="whitecolor">
                                                Cantidad
                                            </TableCell>
                                            <TableCell className="whitecolor">
                                                Ship
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {pedido.cartas.map(c => {
                                            c.quantity = c.cantidad;
                                            c.oquantity = ""
                                            return (
                                                <RowAddedPedido key={c.id} info="pedido" carta={c} id_user={props.id_user} tipo={pedido.tipo} />
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12} md={12} className="block">
                            <ArchivosCambio id_cambio={pedido.id + "00000"} />
                        </Grid>
                    </>
                    ) : (
                        <></>
                    )}

                </ >
            )
            }


        </div >
    );
}

export default EditarPedido;