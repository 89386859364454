import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Constantes from './Constantes';
import CardNameTeam from './Components/TableCells/CardNameTeam';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';

import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import NumbersIcon from '@mui/icons-material/Numbers';
import GradeIcon from '@mui/icons-material/Grade';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import TextureIcon from '@mui/icons-material/Texture';

import ApiFetch from './Functions/ApiFetch';

const FilaDeTablaDeCarta = (props) => {
    const [eliminado, setEliminado] = useState(false);
    const [cantidad, setCantidad] = useState(0);
    const [imgurl, setImgurl] = useState("");
    const [modificado, setModificado] = useState(false);

    useEffect(() => {
        componentDidMount();
    }, []);

    const componentDidMount = async () => {

        const { carta } = props;

        const qty = carta.quantity || 0;
        setCantidad(qty);

        const iurl = carta.imgurl?.replace("./", "/") || "";
        setImgurl(iurl);
    }

    const redireccionarParaEditar = () => {
        return <Navigate to={`/videojuegos/editar/?id=${props.carta.id}`} />
    }
    const increaseqty = () => {

        setCantidad(cantidad + 1)
        modifyqty(1)
        //console.log("Cantidad: " + props.carta.quantity)
    }

    const decreaseqty = () => {
        setCantidad(cantidad - 1)
        modifyqty(-1)
    }

    const modifyqty = async (qty) => {
        setModificado(true);
        const { carta, id_user } = props;
        const newQuantity = cantidad + qty;

        const updatedCarta = {
            ...carta,
            ship: 1,
            tipo: 1,
            id_carta: carta.id,
            id_user,
            cantidad: qty,
        };


        const cargaUtil = JSON.stringify(updatedCarta);
        const respuesta = await fetch(`${Constantes.RUTA_API}/modify_quantity.php`, {
            method: "PUT",
            body: cargaUtil,
        });

        const resp = await respuesta.json();
        if (resp) {
            setModificado(false);
        } else {
            console.log("Error al modificar cantidad");
        }
    }

    function MyIcons(props) {
        const icons = [
            { name: 'Numerada', icon: <NumbersIcon className='secondarycolor' style={{ fontSize: '15px' }} />, prop: 'numerada' },
            { name: 'Gradeada', icon: <GradeIcon className='secondarycolor' style={{ fontSize: '15px' }} />, prop: 'grade' },
            { name: 'Firma', icon: <BorderColorIcon className='secondarycolor' style={{ fontSize: '15px' }} />, prop: 'auto' },
            { name: 'Rookie', icon: <AccessibilityNewIcon className='secondarycolor' style={{ fontSize: '15px' }} />, prop: 'rookie' },
            { name: 'Patch', icon: <TextureIcon className='secondarycolor' style={{ fontSize: '15px' }} />, prop: 'patch' },
        ];
        return (
            <>
                {icons.map((icon, index) => {
                    if (props.carta[icon.prop] === 1) {
                        return (
                            <IconButton key={index} title={icon.name}>
                                {icon.icon}
                            </IconButton>
                        );
                    }
                    return null;
                })}
            </>
        );
    }

    const eliminar = async () => {
        const resultado = await Swal.fire({
            title: 'Confirmación',
            text: `¿Eliminar "${props.carta.name}" (Id: ${props.carta.id})?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3298dc',
            cancelButtonColor: '#f14668',
            cancelButtonText: 'No',
            confirmButtonText: 'Sí, eliminar'
        });

        if (!resultado.value) {
            return;
        }

        try {
            const requestOptions = { method: 'POST' };
            const ncartas = await ApiFetch({ requestOptions, url: `/carta/delete?idcarta=${props.carta.id}` });
            const exitoso = ncartas.message.status;

            if (exitoso === 200) {
                toast('Carta eliminada ', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setEliminado(true)
            } else {
                toast.error("Error eliminando. Intenta de nuevo");
            }
        } catch (error) {
            console.error('Error eliminando carta:', error);
            toast.error('Ocurrió un error eliminando la carta. Intenta de nuevo.');
        }
    };

    return (
        <>
            {eliminado ? (<>

            </>) : (
                <tr>
                    <td className='centeredtext'>
                        <Avatar
                            alt="Remy Sharp"
                            className='centeredtext'
                            src={imgurl}
                            sx={{ width: 75, height: 85 }}
                        />
                    </td>
                    <td className='centeredtext'>{props.carta.num_carta}</td>
                    <td><CardNameTeam firstline={props.carta.name} secondline={props.carta.team} id={props.carta.id} />

                    </td>
                    <td>
                        {MyIcons(props)}
                    </td>
                    <td><CardNameTeam firstline={props.carta.coleccion} secondline={props.carta.year} id={props.carta.id_coleccion} type={"coleccion"} /></td>
                    <td className='centeredtext'>

                        {props.editqty ? (<>
                            <IconButton color='primary' onClick={decreaseqty} >
                                <RemoveCircleOutlineIcon />
                            </IconButton>

                            {modificado ? (<>
                                #
                            </>) : (<>{cantidad}</>)}

                            <IconButton color='primary' onClick={increaseqty}>
                                <ControlPointIcon />
                            </IconButton>
                        </>) : (<>{cantidad}</>)}
                    </td>
                    <td>

                        <Tooltip title="Edit">
                            <Link to={`/carta/editar/?id=${props.carta.id}`}>
                                <IconButton>
                                    <EditIcon className='secondarycolor' />
                                </IconButton>
                            </Link>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <IconButton onClick={eliminar}>
                                <DeleteIcon className='secondarycolor' />
                            </IconButton>
                        </Tooltip>

                    </td>
                </tr>
            )}
        </>
    );

}

export default FilaDeTablaDeCarta;