import React from "react";
import Loading from '../../Functions/Loading';
import { useState, useEffect } from "react";
import Constantes from "../../Constantes";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { Stack } from '@mui/material';

import FilaTablaEbay from "./FilaTablaEbay";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';

const InfoCartaEbay = (props) => {
    const [carta, setCarta] = useState(null);
    const [ebaydata, setEbaydata] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // Llama una vez al iniciar
    useEffect(() => {
        componentDidMount();
    }, props.carta);

    const componentDidMount = async () => {
        const ca = { ...props.carta }
        if (ca.name.includes('(Firma)')) {
            ca.name = ca.name.replace(/ *\([^)]*\) */g, "");
            ca.name = ca.name + " " + " auto";
        }
        if (ca.name.includes('(')) {
            ca.name = ca.name.replace(/ *\([^)]*\) */g, "");
        }
        if (ca.site == undefined) {
            ca.site = "US"
        }

        setCarta(ca)
        const cargaUtil = JSON.stringify(ca);
        //console.log(ct)
        //console.log(cambio)
        let respuesta = "";
        respuesta = await fetch(`${Constantes.RUTA_API}/ebay.php`, {
            method: "PUT",
            body: cargaUtil,
        });

        const c = await respuesta.json();
        setEbaydata(c);



        setIsLoading(false)
    }

    return (
        <>
            {isLoading ? (
                <><Loading></Loading></>
            ) : (<>
                <Accordion className='block '>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Stack direction="row" spacing={0} className='containerheader'>
                            <h4 >{carta.name + " " + carta.coleccion + " " + carta.year + " (" + carta.site + ")"}</h4>
                        </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TableContainer >
                            <Table aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Foto
                                        </TableCell>
                                        <TableCell>
                                            Título
                                        </TableCell>
                                        <TableCell>
                                            Precio
                                        </TableCell>
                                        <TableCell>
                                            Envio
                                        </TableCell>
                                        <TableCell>
                                            Ubicación
                                        </TableCell>
                                        <TableCell>
                                            Tipo
                                        </TableCell>
                                        <TableCell>
                                            Acepta
                                            Ofertas
                                        </TableCell>
                                        <TableCell>
                                            Tiempo Restante
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ebaydata.map((c, index) => {
                                        return (
                                            <FilaTablaEbay key={index + c.title[0]} item={c} />
                                        )

                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
            </>)
            }

        </>
    )
}

export default InfoCartaEbay;