import React from 'react';
import Constantes from "../../Constantes";
import { ToastContainer, toast } from 'react-toastify';
import { Link, Navigate } from 'react-router-dom';
import Select from 'react-select';
import { useState, useEffect } from "react";
import Pagination from '@mui/material/Pagination';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Loading from "../../Functions/Loading";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import RowAdded from './RowAdded';


import { Input } from '@mui/material';
import { SecurityUpdateWarningSharp } from '@mui/icons-material';
import { CSVLink, CSVDownload } from "react-csv";
import Button from '@mui/material/Button';
import pdfMake from 'pdfmake/build/pdfmake';
import { Stack } from '@mui/material';
import CartaSeleccionada from './CartaSeleccionada';
import ImgCarta from './ImgCarta';

import ApiFetch from '../../Functions/ApiFetch';


const ObtenerCartas = (props) => {
    const [colecciones, setColecciones] = useState([]);
    const [loading, setLoading] = useState(true);
    const [carta, setCarta] = useState({ name: "Selecciona carta", num_carta: "" });
    const [searchTerm, setSearchTerm] = useState("");
    const [cartas, setCartas] = useState([]);
    const [idcoleccion, setIdcoleccion] = useState(0);
    const [disabled, setDisabled] = useState(true);
    const [added, setAdded] = useState([]);
    const [year, setYear] = useState(2022);
    const [uniqueyears, setUniqueyears] = useState([]);
    const [counter, setCounter] = useState(0);
    const [pendientestr, setPendientestr] = useState("");
    var PER_PAGE = 10;
    if (props.perpage !== undefined) {
        PER_PAGE = props.perpage;
    }

    const csvheaders = [
        { label: "Carta", key: "num_carta" },
        { label: "Jugador", key: "name" },
        { label: "Equipo", key: "team" },
        { label: "Coleccion", key: "coleccion" },
        { label: "Año", key: "year" },
        { label: "Cantidad Original", key: "oquantity" },
        { label: "Cantidad Nueva", key: "quantity" },
    ];



    // Llama una vez al iniciar
    useEffect(() => {
        componentDidMount();
    }, []);


    const componentDidMount = async () => {


        const respuesta = await fetch(`${Constantes.RUTA_API}/obtener_colecciones.php?uid=${props.id_user}`);
        const colecciones = await respuesta.json();

        //console.log("Col: " + colecciones[0].name)
        // "refrescar" el formulario
        setColecciones(colecciones)
        setLoading(false)
    }

    const handleSelectChange = async (e) => {
        const coleccion = e.value;
        //console.log(valor)
        setIdcoleccion(coleccion)
        const requestOptions = {
            method: 'GET',
        };
        const ncartas = await ApiFetch({ requestOptions: requestOptions, url: "/cartas?idcoleccion=" + coleccion + "&iduser=" + props.id_user });
        const cartas = ncartas.data;
        setCartas(cartas);
        UniqueYears(cartas);
        setDisabled(false);

    }

    const UniqueYears = (cartas) => {


        const uniqueTags = [];
        cartas.map(c => {
            if (uniqueTags.indexOf(c.year) === -1 && c.year != null) {
                //console.log("añadido año: " + c.year)
                uniqueTags.push(c.year)
            }
        })
        //console.log(uniqueTags)
        setUniqueyears(uniqueTags)

    }

    const handleNumChange = (e) => {
        const valor = e.target.value;
        //console.log(valor)
        setSearchTerm(valor)

        const filtered = cartas.filter(
            (c) => c.num_carta.split("-")[0] == (valor) && c.year == year
        );
        var c = ""
        //console.log(filtered)
        if (filtered.length > 0) {
            c = filtered[0];
        } else {
            c = { name: "No encontrado", num_carta: 0 }
        }
        if (!c.precibir) {
            c.precibir = 0;
        }
        if (!c.penviar) {
            c.penviar = 0;
        }
        if (!c.quantity) {
            c.quantity = 0;
        }
        setPendientestr("PR: " + c.precibir + " // PE: " + c.penviar)

        //console.log(c)
        setCarta(c)
    }

    const handleYearChange = (e) => {
        const valor = e.value;
        //console.log("Set year: " + valor)
        setYear(valor)
    }

    const handleKeyPress = async (event) => {
        if (event.key === 'Enter' && carta.num_carta != 0) {
            //console.log('enter press here! ')
            setSearchTerm("")
            // Añadir carta a lista

            var newc = true;

            const UpdateCarta = added.map(obj => {
                // 👇️ if id equals 2, update country property
                if (obj.id === carta.id) {
                    newc = false
                    return { ...obj, quantity: parseInt(obj.quantity + 1) };
                }

                // 👇️ otherwise return object as is
                return obj;
            });

            carta.oquantity = carta.quantity;
            if (carta.quantity === null || carta.quantity === "") {
                carta.oquantity = 0;
                carta.quantity = 1;
            } else {
                carta.oquantity = carta.quantity;
                carta.quantity = parseInt(carta.quantity + 1)
            }



            carta.ship = 1;
            carta.tipo = 1;
            carta.id_user = props.id_user;
            carta.id_carta = carta.id;
            carta.cantidad = 1;
            //console.log(carta)
            const cargaUtil = JSON.stringify(carta);
            const respuesta = await fetch(`${Constantes.RUTA_API}/modify_quantity.php`, {
                method: "PUT",
                body: cargaUtil,
            });

            const resp = await respuesta.json();
            if (resp) {
                if (newc) {

                    setAdded(added => [carta, ...added]);
                    setCounter(counter + 1)
                } else {
                    setAdded(UpdateCarta)
                }
            } else {
                console.log("Error al modificar cantidad")
            }



            setCarta({ name: "Selecciona carta", num_carta: "" })
        }
    }

    const exportPDF = async () => {
        var tbody = [[{ text: 'Carta', style: 'tableHeader' },
        { text: 'Jugador', style: 'tableHeader' },
        { text: 'Equipo', style: 'tableHeader' },
        { text: 'Colección', style: 'tableHeader' },
        { text: 'Año', style: 'tableHeader' },
        { text: 'Cantidad Original', style: 'tableHeader' },
        { text: 'Cantidad Nueva', style: 'tableHeader' }]
        ]
        var numAscending = []
        // If not is ordered by quantity, order by card num.
        numAscending = [...added].sort((a, b) => a.num_carta.split("-")[0] - b.num_carta.split("-")[0]);


        numAscending.forEach((c) => {
            //console.log(el)
            var qty = c.quantity
            if (qty == null) {
                qty = 0;
            }
            if (c.year == null) {
                c.year = "-"
            }
            if (c.oquantity == 0) {
                tbody.push([
                    { text: c.num_carta.split("-")[0], style: 'nueva' },
                    { text: c.name, style: 'nueva' },
                    { text: c.team, style: 'nueva' },
                    { text: c.coleccion, style: 'nueva' },
                    { text: c.year, style: 'nueva' },
                    { text: c.oquantity, style: 'nueva' },
                    { text: c.quantity, style: 'nueva' }])
            } else if (c.oquantity == 1 && c.quantity > 1) {
                tbody.push([
                    { text: c.num_carta.split("-")[0], style: 'repetida' },
                    { text: c.name, style: 'repetida' },
                    { text: c.team, style: 'repetida' },
                    { text: c.coleccion, style: 'repetida' },
                    { text: c.year, style: 'repetida' },
                    { text: c.oquantity, style: 'repetida' },
                    { text: c.quantity, style: 'repetida' }])
            } else {
                tbody.push([c.num_carta.split("-")[0], c.name, c.team, c.coleccion, c.year, c.oquantity, c.quantity])
            }

        })





        var docDefinition = {
            content:
                [
                    { text: 'Cartas Añadidas', style: 'header' },
                    { text: 'Nueva Obtenida', fontSize: 10, color: '#90BFE0' },
                    { text: 'Nueva Repetida', fontSize: 10, color: '#E09090' },
                    {
                        style: 'table',
                        table: {
                            // headers are automatically repeated if the table spans over multiple pages
                            // you can declare how many rows should be treated as headers
                            headerRows: 1,
                            widths: ['auto', '*', "*", "auto", 'auto', 'auto', 'auto'],
                            body: tbody,

                        },
                        alignment: 'center'
                    },
                ],
            styles: {
                header: { fontSize: 15, bold: true, margin: [0, 10, 0, 10], alignment: 'center' },
                tableHeader: { color: 'black', bold: true, fontSize: 9 },
                nueva: { fillColor: '#C5EAF7', color: 'black' },
                repetida: { fillColor: '#E09090', color: 'black' },
                table: {
                    fontSize: 8,
                    alignment: 'center',
                    color: 'black',
                    margin: [0, 5, 0, 15]
                }
            },
            footer: {
                columns: [
                    { text: 'CardManagement', margin: 15 },
                    { text: added.length + ' cartas  ', alignment: 'right', margin: 15 }
                ]
            }
        };
        // open the PDF in a new window
        pdfMake.createPdf(docDefinition).open();

        // print the PDF
        // pdfMake.createPdf(docDefinition).print();

        // download the PDF
        //pdfMake.createPdf(docDefinition).download('optionalName.pdf');
    }

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'inherit',
            color: 'inherit',
            [`& .${provided.classNamePrefix}__input`]: {
                color: '#ffffff',
            },
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: 'inherit',
            color: 'inherit',
            [`& .${provided.classNamePrefix}__option`]: {
                color: '#ffffff',
            },
        }),
    };

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <div className='block' style={{ zIndex: 50 }}>
                    <Grid container spacing={{ xs: 2, md: 2 }} >
                        <Grid item xs={12} md={12}>
                            <Stack direction="row" className='containerheader' spacing={0}>
                                <h4>Añadir cartas:</h4>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Grid container spacing={{ xs: 2, md: 2 }} >

                                <Grid item xs={12} md={8}>

                                    <label className="form-label" htmlFor="precio">Selecciona colección:</label>
                                    <Select
                                        id="id_coleccion"
                                        styles={customStyles}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        options={colecciones.map(c => {
                                            return {
                                                value: c.id, label: c.name
                                            }

                                        })}
                                        onChange={handleSelectChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <label className="form-label">Selecciona año:</label>
                                    <Select
                                        id="iyear"
                                        styles={customStyles}
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        placeholder="Año.."
                                        disabled={disabled}
                                        options={uniqueyears.map(c => {
                                            return {
                                                value: c, label: c
                                            }

                                        })}
                                        onChange={handleYearChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3.5} >
                                    <label className="form-label">Introduce número de carta:</label>

                                </Grid>
                                <Grid item xs={12} md={4} >
                                    <OutlinedInput
                                        id="card-search"
                                        type="text"
                                        value={searchTerm}
                                        color="primary"
                                        variant="outlined"
                                        onChange={handleNumChange}
                                        onKeyPress={handleKeyPress}
                                        disabled={disabled}
                                        className="num_carta_inp secondaryborderbottom secondarycolor"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4} >
                            <CartaSeleccionada carta={carta} />
                        </Grid>
                        <Grid item xs={12} md={4} >
                            {(added.length > 0) ? (
                                <Grid container spacing={{ xs: 2, md: 2 }}>
                                    <Grid item >
                                        <Button variant="contained" color="success" >
                                            <CSVLink
                                                data={added}
                                                headers={csvheaders}
                                                className="none"
                                                filename={"cartas_añadidas_" + counter + ".csv"}
                                            >Exportar CSV</CSVLink>
                                        </Button>
                                    </Grid>
                                    <Grid item >
                                        <Button variant="contained" color="error" onClick={exportPDF}>
                                            PDF
                                        </Button>
                                    </Grid>
                                </Grid>
                            ) : (
                                <>
                                </>
                            )}

                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} className="box-container">
                        <TableContainer sx={{ maxHeight: 440 }}>
                            <Table aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='whitecolor'>
                                            Img
                                        </TableCell>
                                        <TableCell className='whitecolor'>
                                            Num. Carta
                                        </TableCell>
                                        <TableCell className='whitecolor'>
                                            Carta
                                        </TableCell>
                                        <TableCell className='whitecolor'>
                                            Coleccion
                                        </TableCell>
                                        <TableCell className='whitecolor'>
                                            Cantidad
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {added.map(c => {
                                        return (
                                            <RowAdded key={c.id + "-" + counter} info="" carta={c}></RowAdded>
                                        )

                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </div >
            )
            }


        </ >
    );
}

export default ObtenerCartas;