import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import CardNameTeam from '../TableCells/CardNameTeam';

class RowAdded extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {

        var imgurl = this.props.carta.imgurl;
        if (imgurl !== null && imgurl !== "") {
            imgurl = imgurl.replace("./", "/");
        }

        var selected = false;
        if (this.props.carta.oquantity == 0) {
            selected = true;
        }




        return (

            <TableRow selected={selected}>
                <TableCell>
                    <Avatar
                        alt="Remy Sharp"
                        src={imgurl}
                        sx={{ width: 50, height: 50 }}
                    />
                </TableCell>
                <TableCell className='whitecolor'>
                    {this.props.carta.num_carta}
                </TableCell>
                <TableCell>
                    <CardNameTeam firstline={this.props.carta.name} secondline={this.props.carta.team} id={this.props.carta.id} />
                </TableCell>
                <TableCell>
                    <CardNameTeam firstline={this.props.carta.coleccion} secondline={this.props.carta.year} id={this.props.carta.id_coleccion} type="coleccion" />
                </TableCell>
                <TableCell className='whitecolor'>
                    {(this.props.carta.oquantity) + " => "}
                    <span className='secondarycolor'>
                        {this.props.carta.quantity}
                    </span>
                </TableCell>
            </TableRow>


        );
    }
}

export default RowAdded;