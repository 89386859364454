import React from "react";
import Grid from '@mui/material/Grid';
import Loading from '../../Functions/Loading';
import { useState, useEffect } from "react";
import Constantes from "../../Constantes";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import InfoCartaEbay from "./InfoCartaEbay";

import FilaTablaEbay from "./FilaTablaEbay";

import axios from "axios";

const PanelEbay = (props) => {
    const [carta, setCarta] = useState([]);
    const [ebaydata, setEbaydata] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // Llama una vez al iniciar
    useEffect(() => {
        componentDidMount();
    }, []);

    const componentDidMount = async () => {
        setCarta([])
        setCarta(carta => [...carta, { name: "Barcelona auto", year: "", coleccion: "", tipo: "Auction", order: "EndTimeSoonest", site: "US", quantity: "10" }]);
        setCarta(carta => [...carta, { name: "barcelona auto", year: "", coleccion: "", tipo: "Auction", order: "EndTimeSoonest", site: "ES", quantity: "10" }]);
        setCarta(carta => [...carta, { name: "Barcelona psa 9", year: "", coleccion: "", tipo: "Auction", order: "EndTimeSoonest", site: "US", quantity: "10" }]);
        setCarta(carta => [...carta, { name: "Barcelona psa 10", year: "", coleccion: "", tipo: "Auction", order: "EndTimeSoonest", site: "US", quantity: "10" }]);
        setCarta(carta => [...carta, { name: "Barcelona psa 8", year: "", coleccion: "", tipo: "Auction", order: "EndTimeSoonest", site: "US", quantity: "10" }]);
        setCarta(carta => [...carta, { name: "Barcelona match worn panini", year: "", coleccion: "", tipo: "All", order: "PricePlusShippingLowest", site: "US", quantity: "10" }]);
        setCarta(carta => [...carta, { name: "aguero PSA", year: "", coleccion: "", tipo: "Auction", order: "EndTimeSoonest", site: "US", quantity: "10" }]);
        //PricePlusShippingLowest
        setIsLoading(false)
    }

    return (
        <>
            {isLoading ? (
                <><Loading></Loading></>
            ) : (<>
                <div className="container">
                    <Grid container className='containerblock ' spacing={{ xs: 1, md: 1 }} alignItems="center" justifyContent="center" >
                        {carta.map((c, index) => {
                            return (
                                <Grid item xs={12}>
                                    <InfoCartaEbay key={index + c.name + c.coleccion + c.tipo + c.order + c.site} carta={c} />
                                </Grid>
                            )

                        })}
                    </Grid>
                </div>
            </>)
            }

        </>
    )
}

export default PanelEbay;