import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
class BoxColeccion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {

        var imgurl = this.props.coleccion.icon;
        if (imgurl !== null && imgurl !== "") {
            imgurl = imgurl.replace("./", "/");
        }

        var info = this.props.info
        if (info === null || info === "") {
            info = false;
        } else {
            info = true
        }


        return (
            <Link className='custom-box' to={"/cartascoleccion/" + this.props.coleccion.id}>
                <Avatar
                    alt="Remy Sharp"
                    src={imgurl}
                    sx={{ width: 100, height: 100 }}
                />
                <br></br>
                <span className='centeredtext'>
                    {this.props.coleccion.name}
                </span>

                {info ? <span className="alert alert-danger" style={{ position: "absolute" }}>{this.props.info}</span> : ''}
            </Link>


        );
    }
}

export default BoxColeccion;