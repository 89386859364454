import * as React from 'react';
import Loading from "../Functions/Loading";
import { useState, useEffect } from "react";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Constantes from '../Constantes';
import TextField from '@mui/material/TextField';
import theme from "../Theme/theme";

// Select
import MenuItem from '@mui/material/MenuItem';

// Tabla
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { width } from '@mui/system';


const HomeLinks = (props) => {

    const [loading, setLoading] = useState(true);
    const [noti, setNoti] = useState([]);
    const [newN, setNewN] = useState({ id: 0, title: "", url: "", comment: "", active: 1, icon: "" });

    useEffect(() => {
        componentDidMount();
    }, []);


    const componentDidMount = async () => {

        const respuesta = await fetch(`${Constantes.RUTA_API}/obtener_links.php`);
        const datos = await respuesta.json();

        setNoti(datos)
        setLoading(false);
    }

    const ChangeStatus = (id, status) => {

        const newState = noti.map(obj => {
            let modified = false;
            // 👇️ if id equals 2, update country property
            if (obj.id === id && status == 0) {
                GuardarNoti({ ...obj, active: 1 })
                return { ...obj, active: 1 };
            } else if (obj.id === id && status == 1) {
                GuardarNoti({ ...obj, active: 0 })
                return { ...obj, active: 0 };
            }
            // 👇️ otherwise return object as is
            return obj;
        });

        setNoti(newState);
    }

    const GuardarNoti = async (n) => {

        if (n.id == undefined || n.id == null) {
            n = newN
        }

        const cargaUtil = JSON.stringify(n);
        //console.log(carta)
        const respuesta = await fetch(`${Constantes.RUTA_API}/addHomeLink.php`, {
            method: "PUT",
            body: cargaUtil,
        });

        let resp = await respuesta.json();
        if (resp) {
            resp = JSON.parse(resp)

            if (n.id == 0) {
                n.id = resp.id
                var notis = noti
                notis = [n, ...notis]
                setNoti(notis)
                setNewN({ id: 0, title: "", url: "", comment: "", active: 1, icon: "" })
            } else { // actualizar elemento en noti
                const newState = noti.map(obj => {
                    // 👇️ if id equals 2, update country property
                    if (obj.id === n.id) {
                        return n;
                    }
                    // 👇️ otherwise return object as is
                    return obj;
                });
                setNoti(newState)
            }
        }
    }


    const EditNoti = (n) => {
        setNewN(n);
    }

    const DeleteNoti = async (n) => {
        const cargaUtil = JSON.stringify(n);
        //console.log(carta)
        const respuesta = await fetch(`${Constantes.RUTA_API}/deleteHomeLink.php`, {
            method: "PUT",
            body: cargaUtil,
        });

        let resp = await respuesta.json();
        if (resp) {
            resp = JSON.parse(resp)
            if (resp.result == "OK") {
                setNoti(noti.filter(item => item.id !== n.id));

            }
        }
    }

    return (
        <div className=''>
            {loading ? (
                <Loading />
            ) : (
                <div >


                    <Grid container sx={{ m: 2 }} spacing={{ xs: 2, md: 2 }} >
                        <Grid item xs={12} md={3}>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                required
                                type="text"
                                id="search"
                                label="Título"
                                fullWidth
                                value={newN.title}
                                onChange={(event) => {
                                    setNewN(newN => ({ ...newN, title: event.target.value }));
                                }}
                                variant="filled"
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                required
                                type="text"
                                id="search"
                                label="URL"
                                fullWidth
                                value={newN.url}
                                onChange={(event) => {
                                    setNewN(newN => ({ ...newN, url: event.target.value }));
                                }}
                                variant="filled"
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                required
                                type="text"
                                id="search"
                                label="Descripción"
                                fullWidth
                                value={newN.comment}
                                onChange={(event) => {
                                    setNewN(newN => ({ ...newN, comment: event.target.value }));
                                }}
                                variant="filled"
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                required
                                type="text"
                                id="search"
                                label="Icono"
                                fullWidth
                                value={newN.icon}
                                onChange={(event) => {
                                    setNewN(newN => ({ ...newN, icon: event.target.value }));
                                }}
                                variant="filled"
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button id="recibido" variant="contained" color="primary" onClick={GuardarNoti} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>

                    <TableContainer >
                        <Table aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: 30 }}>
                                        Id
                                    </TableCell>
                                    <TableCell sx={{ width: 100 }}>
                                        Icono
                                    </TableCell>
                                    <TableCell >
                                        Titulo
                                    </TableCell>
                                    <TableCell>
                                        URL
                                    </TableCell>
                                    <TableCell>
                                        Descripción
                                    </TableCell>

                                    <TableCell >
                                        Activo
                                    </TableCell>
                                    <TableCell >
                                        Editar
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {noti.map((c, index) => {
                                    return (<tr key={c.id}>
                                        <td className='centeredtext'>
                                            {c.id}
                                        </td>
                                        <td className='centeredtext'>
                                            <img src={c.icon} style={{ width: '80px' }} />
                                        </td>
                                        <td >
                                            {c.title.toUpperCase()}
                                        </td>
                                        <td className='centeredtext'>
                                            {c.url}
                                        </td>
                                        <td className='centeredtext'>
                                            {c.comment}
                                        </td>
                                        <td className='centeredtext'>
                                            <FormControlLabel control={<Switch
                                                checked={Boolean(c.active)}
                                                onChange={() => {
                                                    ChangeStatus(c.id, c.active);
                                                }} />} label="" />
                                        </td>
                                        <td className='centeredtext'>
                                            <IconButton variant="contained" onClick={() => { EditNoti(c, index) }}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton theme={theme} variant="contained" onClick={() => { DeleteNoti(c) }}>
                                                <DeleteIcon />
                                            </IconButton>


                                        </td>
                                    </tr>)

                                })}
                            </TableBody>
                            <TableFooter>

                            </TableFooter>
                        </Table>
                    </TableContainer>
                </div>
            )}
        </div>
    );
}

export default HomeLinks;