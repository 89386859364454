import * as React from 'react';
import Loading from "../../Functions/Loading";
import Paper from '@mui/material/Paper';
import Constantes from '../../Constantes';
import { useState, useEffect } from "react";
import Chart, {
    Series,
    Aggregation,
    ArgumentAxis,
    Font,
    Title,
    CommonSeriesSettings, Label, Format, Legend, Export,
} from 'devextreme-react/chart';
import { ArgumentScale } from '@devexpress/dx-react-chart';
import { scaleBand } from '@devexpress/dx-chart-core';


//https://devexpress.github.io/devextreme-reactive/react/chart/docs/reference/argument-axis/
//https://js.devexpress.com/Demos/WidgetsGallery/Demo/Charts/SideBySideBar/React/Light/


const GBeneficiosPedidos = (props) => {

    const [loading, setLoading] = useState(true);
    const [chartData, setchartData] = useState({ cambios: 0, month: 0, monthname: "none" });
    const date = new Date();


    useEffect(() => {
        componentDidMount();
    }, []);


    const componentDidMount = async () => {

        const respuesta = await fetch(`${Constantes.RUTA_API}/obtener_compraventas.php?uid=${props.id_user}`);
        const pedidos = await respuesta.json();

        // Filtramos pedidor para el año actual y ordenamos por mes ascendente
        const datos = pedidos.filter(
            (pedido) => pedido.year == date.getFullYear()
        ).sort((a, b) => a.month - b.month);;

        datos.forEach((el) => {

            el.arg = toMonthName(el.month)
            if (el.total == null) {
                el.total = "0€";
            }

            // 1 -> Compra // 0 -> Venta
            if (el.tipo == 1) {
                el.compras = parseInt(el.total.slice(0, -1))
                el.ventas = 0
            } else if (el.tipo == 0) {
                el.compras = 0
                el.ventas = parseInt(el.total.slice(0, -1))
            }
        })

        setchartData(datos)
        setLoading(false);
    }

    function toMonthName(monthNumber) {

        date.setMonth(monthNumber - 1);

        return date.toLocaleString('es-ES', {
            month: 'long',
        });
    }

    // https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxChart/Configuration/argumentAxis/

    return (
        <div >
            {loading ? (
                <Loading />
            ) : (
                <div >
                    {(chartData.length > 1) ? (
                        <Paper className='block whitecolor'>
                            <Chart dataSource={chartData}>
                                <Title text={"Ganancias y perdidas " + date.getFullYear() + " (€)"}>
                                    <Font color="#fff" />
                                </Title>
                                <CommonSeriesSettings
                                    argumentField="arg"
                                    type='spline'
                                >
                                    <Label visible={true}>
                                        <Format type="fixedPoint" precision={0} />
                                    </Label>
                                </CommonSeriesSettings>
                                <Series valueField="compras"
                                    argumentField="arg"
                                    name={"Perdidas"}
                                    type="spline"
                                    color="#DE2B2B"
                                >
                                    <Label displayFormat="{valueText}€" />
                                    <Aggregation enabled={true} method="sum" />
                                </Series>
                                <Series valueField="ventas"
                                    argumentField="arg"
                                    name={"Ganancias"}
                                    type="spline"
                                    color="#3E803C"
                                >
                                    <Label displayFormat="{valueText}€" />
                                    <Aggregation enabled={true} method="sum" />
                                </Series>
                                <Legend verticalAlignment="bottom" horizontalAlignment="center">
                                    <Font size={12} color="#fff" /></Legend>
                                <Export enabled={true} />
                                <ArgumentAxis aggregateByCategory={true} />
                            </Chart>

                        </Paper>
                    ) : (<></>)}
                </div >
            )}
        </div>
    );
}

export default GBeneficiosPedidos;