import React from 'react';
import Constantes from "../Constantes";
import TablaCompraventas from './TablaCompraventas';
import Loading from '../Functions/Loading';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';
import { useState, useEffect } from "react";
import InfoBox from '../Home/InfoBox';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Link } from 'react-router-dom';
import GHistorialPedidos from '../Components/Graficos/GHistorialPedidos';
import GBeneficiosPedidos from '../Components/Graficos/GBeneficiosPedidos';

const VerCompraventas = (props) => {

    const [loading, setLoading] = useState(true);
    const [compraventas, setCompraventas] = useState([]);
    const [iduser, setIduser] = useState(0);
    const [ganancias, setGanancias] = useState(0);
    const [ventas, setVentas] = useState(0);
    const [cartasvendidas, setCartasvendidas] = useState(0);
    const [perdidas, setPerdidas] = useState(0);
    const [compras, setCompras] = useState(0);
    const [cartascompradas, setCartascompradas] = useState(0);


    useEffect(() => {
        componentDidMount();
    }, []);


    const componentDidMount = async () => {

        setIduser(props.id_user)
        const respuesta = await fetch(`${Constantes.RUTA_API}/obtener_compraventas.php?uid=${props.id_user}`);
        const cv = await respuesta.json();
        setCompraventas(cv)
        let beneficio = 0;
        let v = 0;
        let c = 0;

        if (cv.length > 0) {
            cv.forEach((el) => {
                if (el.tipo == 0) { // es venta
                    v += 1;
                    beneficio += parseFloat(el.total.slice(0, -1))
                    c += parseInt(el.cantidad)
                }
            })
        }
        setGanancias(beneficio.toFixed(2))
        setVentas(v)
        setCartasvendidas(c)
        let perdidas = 0;
        let compr = 0;
        let cacompr = 0;
        cv.forEach((el) => {
            if (el.tipo == 1) { // es compra
                compr += 1;
                if (el.total != null) {
                    perdidas += parseFloat(el.total.slice(0, -1))
                }
                if (el.cantidad != null) {
                    cacompr += parseInt(el.cantidad)
                }
            }
        })
        setPerdidas(perdidas.toFixed(2))
        setCompras(compr)
        setCartascompradas(cacompr)
        setLoading(false);
    }




    return (
        <div className="container">
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Grid container spacing={{ xs: 1, md: 1 }} className='containerblock' >
                        <section className="home_page-contain">
                            <InfoBox value={ventas} text={"Ventas totales"} color={'green'} small={true} link={'/compraventas'} />
                            <InfoBox value={cartasvendidas} text={"Cartas vendidas"} color={'green'} small={true} link={'/compraventas'} />
                            <InfoBox value={ganancias + "€"} text={"Beneficios"} color={'green'} small={true} link={'/compraventas'} />
                            <InfoBox value={compras} text={"Compras totales"} color={'red'} small={true} link={'/compraventas'} />
                            <InfoBox value={cartascompradas} text={"Cartas compradas"} color={'red'} small={true} link={'/compraventas'} />
                            <InfoBox value={perdidas + "€"} text={"Inversión"} color={'red'} small={true} link={'/compraventas'} />
                            <InfoBox value={(ganancias - perdidas).toFixed(2) + "€"} text={"Balance"} color={'orange'} small={true} link={'/compraventas'} />
                        </section>
                    </Grid>
                    <Grid container className='containerblock' spacing={{ xs: 1, md: 2 }}>
                        <Grid item xs={12} md={6} >
                            <GHistorialPedidos id_user={props.id_user} />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <GBeneficiosPedidos id_user={props.id_user} />
                        </Grid>
                        <Grid item xs={12} md={12} className='block'>
                            <Stack direction="row" className='containerheader' spacing={1}>
                                <Link className='firstline' to={`/pedido/0`} style={{ color: "green" }}>
                                    <AddBoxIcon fontSize="large" />
                                </Link>
                                <h4 >Compras & Ventas</h4>
                            </Stack>
                            <TablaCompraventas perpage={props.perpage} compraventas={compraventas} />
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    );
}

export default VerCompraventas;