import AgregarVideojuego from "./AgregarVideojuego";
import VerCartas from "./VerCartas";
import EditarCarta from "./EditarCarta";
import RouteCartas from "./routes/cartas";
import Login from "./Login/Login";
import CrearCarta from "./Functions/CrearCarta";
import VerCompraventas from './CompraVenta/VerCompraventas'
import VerCartasPendientes from "./CompraVenta/VerCartasPendientes";
import Colecciones from "./Colecciones/Colecciones";
import {
  Routes,
  Route,
} from "react-router-dom";
import { Redirect } from 'react-router-dom'
import EditarCompraVenta from "./CompraVenta/EditarCompraventa";
import { useState } from "react";
import Test from "./Components/test";

import MainMenu from "./MainMenu"
import SearchAppBar from "./MainMenu/SearchAppBar";
import VerItemsCartas from "./Components/Cartas/VerItemsCartas";

import EditarCambio from "./Cambios/EditarCambio";

import Home from "./Home/Home";
import RouteCambios from "./routes/cambios";
import VerCambios from "./Cambios/VerCambios";
import PanelEbay from "./Components/Ebay/PanelEbay";
import RoutePedidos from "./routes/pedidos";
import Ajustes from "./Ajustes/Ajustes";
import BuscarEbay from "./Components/Ebay/BuscarEbay";

function App(props) {
  var uid = props.id_user
  //console.log(uid)

  const [open, setOpen] = useState(false)
  return (
    <div className={"root " + props.theme}>
      <SearchAppBar open={open} setOpen={setOpen} />

      <MainMenu open={open} setOpen={setOpen} theme={props.theme} />

      <div className={"root layoutSidenav_content"}>
        <main>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/videojuegos/agregar" element={<AgregarVideojuego />} />
            <Route path="/carta/editar" element={<EditarCarta id_user={uid} />} />
            <Route path="/compraventa" element={<EditarCompraVenta id_user={uid} />} />
            <Route path="/compraventas" element={<VerCompraventas id_user={uid} />} />
            <Route path="/crearcarta" element={<CrearCarta id_user={uid} />} />
            <Route path="/pendientes" element={<VerCartasPendientes id_user={uid} />} />
            <Route path="/colecciones" element={<Colecciones id_user={uid} />} />
            <Route path="/cartas" element={<VerCartas id_user={uid} />} />
            <Route path="/test" element={<Test id_user={uid} />} />
            <Route path="/cartas/cuadricula" element={<VerItemsCartas id_user={uid} />} />
            <Route path="/cartascoleccion/:idcoleccion" element={<RouteCartas id_user={uid} />} >
            </Route>
            <Route path="/cartascoleccion" element={<RouteCartas id_user={uid} />} />
            <Route path="/buscar/:searchterm" element={<RouteCartas id_user={uid} />} />
            <Route path="/cambio/:idcambio" element={<RouteCambios id_user={uid} />} />
            <Route path="/pedido/:idpedido" element={<RoutePedidos id_user={uid} />} />
            <Route path="/cambios" element={<VerCambios id_user={uid} />} />
            <Route path="/" element={<Home id_user={uid} />} />
            <Route path="/panelebay" element={<PanelEbay id_user={uid} />} />
            <Route path="/buscarebay" element={<BuscarEbay id_user={uid} />} />
            <Route path="/ajustes" element={<Ajustes id_user={uid} />} />
          </Routes>
        </main>
      </div>
    </div>
  );
}

export default App;
