import * as React from 'react';
import Loading from "../Functions/Loading";
import { useState, useEffect } from "react";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Constantes from '../Constantes';
import TextField from '@mui/material/TextField';
import theme from "../Theme/theme";

// Select
import MenuItem from '@mui/material/MenuItem';

// Tabla
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';

// Icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';


const NotificacionesEbay = (props) => {

    const [loading, setLoading] = useState(true);
    const [noti, setNoti] = useState([]);
    const [newN, setNewN] = useState({ id: 0, search: "", type: "Auction", orden: "EndTimeSoonest", quantity: 0, locatedin: "US", enabled: 1, maxprice: 0 });

    useEffect(() => {
        componentDidMount();
    }, []);


    const componentDidMount = async () => {

        const respuesta = await fetch(`${Constantes.RUTA_API}/get_ebay_notificationes.php?uid=${props.id_user}`);
        const datos = await respuesta.json();
        //console.log(JSON.parse((datos[0].ebay_params)));
        parseParams(datos)
        //setNoti(datos)
        setLoading(false);
    }

    const parseParams = (datos) => {

        datos.map((n, index) => {
            let epjson = JSON.parse((n.ebay_params))
            let filter = epjson.filter.replace(/({|})/g, '"');
            const num = filter.match(/price:\[(\d+)\.\.(\d+)\]/)?.[2]; // Busca el número después de los dos puntos
            //console.log(filter)
            const regex = /([^:,]+):([^,]+)/g;
            const values = [];
            let match;
            while ((match = regex.exec(filter))) {
                if (match.index === 0) {
                    values.push(match[2].replace(/^\s*"|"\s*$/g, ''));
                } else {
                    values.push(match[1] + ":" + match[2].replace(/^\s*"|"\s*$/g, ''));
                }
            }
            values[1] = num
            //console.log(values);
            epjson.filter = values
            n.ebay_params = epjson
            //console.log(n)

        });
        //console.log(JSON.parse((datos[0].ebay_params)));
        setNoti(datos)

    }

    const ChangeStatus = (id, status) => {

        const newState = noti.map(obj => {
            let modified = false;
            // 👇️ if id equals 2, update country property
            if (obj.id === id && status == 0) {
                GuardarNoti({ ...obj, enabled: 1 })
                return { ...obj, enabled: 1 };
            } else if (obj.id === id && status == 1) {
                GuardarNoti({ ...obj, enabled: 0 })
                return { ...obj, enabled: 0 };
            }
            // 👇️ otherwise return object as is
            return obj;
        });

        setNoti(newState);
    }

    const GuardarNoti = async (n) => {

        if (n.id == undefined || n.id == null) {
            n = newN
        }

        const cargaUtil = JSON.stringify(n);
        //console.log(carta)
        const respuesta = await fetch(`${Constantes.RUTA_API}/addEbayNotification.php`, {
            method: "PUT",
            body: cargaUtil,
        });

        let resp = await respuesta.json();
        if (resp) {
            resp = JSON.parse(resp)

            if (n.id == 0) {
                n.id = resp.id
                var notis = noti
                notis = [n, ...notis]
                setNoti(notis)
                setNewN({ id: 0, search: "", type: "All", orden: "EndTimeSoonest", quantity: 0, locatedin: "US", enabled: 1, maxprice: 0 })
            } else { // actualizar elemento en noti
                const newState = noti.map(obj => {
                    // 👇️ if id equals 2, update country property
                    if (obj.id === n.id) {
                        return n;
                    }
                    // 👇️ otherwise return object as is
                    return obj;
                });
                setNoti(newState)
            }
        }
    }


    const EditNoti = (n) => {
        setNewN(n);
    }

    const DeleteNoti = async (n) => {
        const cargaUtil = JSON.stringify(n);
        //console.log(carta)
        const respuesta = await fetch(`${Constantes.RUTA_API}/deleteEbayNotification.php`, {
            method: "PUT",
            body: cargaUtil,
        });

        let resp = await respuesta.json();
        if (resp) {
            resp = JSON.parse(resp)
            if (resp.result == "OK") {
                setNoti(noti.filter(item => item.id !== n.id));

            }
        }
    }

    return (
        <div className=''>
            {loading ? (
                <Loading />
            ) : (
                <div >


                    <Grid container sx={{ m: 2 }} spacing={{ xs: 2, md: 2 }} >
                        <Grid item xs={12} md={3}>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                required
                                type="text"
                                id="search"
                                label="Busqueda"
                                fullWidth
                                value={newN.search}
                                onChange={(event) => {
                                    setNewN(newN => ({ ...newN, search: event.target.value }));
                                }}
                                variant="filled"
                            />
                        </Grid>
                        <Grid item xs={6} md={1.5}>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label="Tipo"
                                required
                                select
                                fullWidth
                                value={newN.type}
                                variant="filled"
                                onChange={(event) => {
                                    setNewN(newN => ({ ...newN, type: event.target.value }));
                                }}
                            >
                                <MenuItem value={"All"}>Cualquiera</MenuItem>
                                <MenuItem value={"Auction"}>Subasta</MenuItem>
                                <MenuItem value={"FixedPrice"}>Venta</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label="Ordenar por"
                                required
                                select
                                fullWidth
                                value={newN.orden}
                                variant="filled"
                                onChange={(event) => {
                                    setNewN(newN => ({ ...newN, orden: event.target.value }));
                                }}
                            >
                                <MenuItem value={"EndTimeSoonest"}>Primeros en terminar</MenuItem>
                                <MenuItem value={"PricePlusShippingLowest"}>Precio más bajo</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={6} md={1}>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                required
                                type="number"
                                id="quantity"
                                label="Cantidad"
                                fullWidth
                                value={newN.quantity}
                                onChange={(event) => {
                                    setNewN(newN => ({ ...newN, quantity: event.target.value }));
                                }}
                                variant="filled"
                            />
                        </Grid>
                        <Grid item xs={6} md={1.5}>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                required
                                type="number"
                                id="date"
                                label="Precio Maximo ($)"
                                fullWidth
                                value={newN.maxprice}
                                onChange={(event) => {
                                    setNewN(newN => ({ ...newN, maxprice: event.target.value }));
                                }}
                                variant="filled"
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button id="recibido" variant="contained" color="primary" onClick={GuardarNoti} >
                                Guardar
                            </Button>
                        </Grid>
                    </Grid>

                    <TableContainer >
                        <Table aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: 30 }} className='whitecolor'>
                                        Id
                                    </TableCell>
                                    <TableCell className='whitecolor'>
                                        Busqueda
                                    </TableCell>
                                    <TableCell className='whitecolor'>
                                        Tipo
                                    </TableCell>
                                    <TableCell className='whitecolor'>
                                        Orden
                                    </TableCell>
                                    <TableCell sx={{ width: 50 }} className='whitecolor'>
                                        Cantidad
                                    </TableCell>
                                    <TableCell sx={{ width: 80 }} className='whitecolor'>
                                        Ubicación
                                    </TableCell>
                                    <TableCell sx={{ width: 80 }} className='whitecolor'>
                                        Precio Max.
                                    </TableCell>
                                    <TableCell className='whitecolor'>
                                        Otros filtros
                                    </TableCell>
                                    <TableCell className='whitecolor'>
                                        Activo
                                    </TableCell>
                                    <TableCell className='whitecolor'>
                                        Editar
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {noti.map((c, index) => {
                                    return (<tr key={c.id}>
                                        <td className='centeredtext'>
                                            {c.id}
                                        </td>
                                        <td >
                                            {c.search.toUpperCase()}
                                        </td>
                                        <td className='centeredtext'>
                                            {c.ebay_params.filter[0]}
                                        </td>
                                        <td className='centeredtext'>
                                            {c.ebay_params.sort}
                                        </td>
                                        <td className='centeredtext'>
                                            {c.ebay_params.limit}
                                        </td>
                                        <td className='centeredtext'>
                                            {c.ebay_params.filter[3].split(":")[1]}
                                        </td>
                                        <td className='centeredtext'>
                                            {c.ebay_params.filter[1] + "$"}
                                        </td>
                                        <td className='centeredtext'>
                                            {c.ebay_params.aspect_filter}
                                        </td>
                                        <td className='centeredtext'>
                                            <FormControlLabel control={<Switch
                                                checked={Boolean(c.enabled)}
                                                onChange={() => {
                                                    ChangeStatus(c.id, c.enabled);
                                                }} />} label="" />
                                        </td>
                                        <td className='centeredtext'>
                                            <IconButton variant="contained" onClick={() => { EditNoti(c, index) }}>
                                                <EditIcon className='whitecolor' />
                                            </IconButton>
                                            <IconButton theme={theme} variant="contained" onClick={() => { DeleteNoti(c) }}>
                                                <DeleteIcon className='redcolor' />
                                            </IconButton>


                                        </td>
                                    </tr>)

                                })}
                            </TableBody>
                            <TableFooter>

                            </TableFooter>
                        </Table>
                    </TableContainer>
                </div>
            )}
        </div>
    );
}

export default NotificacionesEbay;