import React from "react";
import Constantes from "../Constantes";
import { RefreshToken } from "../Login/RefreshToken";
import Login from "../Login/Login";
import axios from "axios";
import ApiFetch from "./ApiFetch";

const ApiEbay = async (props) => {
    let token = ""
    if (localStorage.getItem('e_aToken') == undefined) {
        let code = ""
        await openPopup().then(c => {
            code = c
        })
        ////console.log("Code: " + code)
        await getNewToken(code).then(t => {
            token = t
        })
        ////console.log("Token: " + token)
    } else {
        token = localStorage.getItem('e_aToken')
    }


    const expire = localStorage.getItem('e_aexpire')
    const requestdata = props
    let data = ""
    await sendEbayRequest(token, expire, requestdata).then(r => {
        ////console.log("Dataaaa:")
        data = r


    })

    return data;

}
// Función que envía la petición a la API de eBay
// https://developer.ebay.com/api-docs/buy/browse/resources/item_summary/methods/search
const sendEbayRequest = async (token, expirationTime, requestData) => {
    if (isTokenExpired(expirationTime)) {
        ////console.log("Token expirado")
        // Si el token ha expirado, obtenemos uno nuevo antes de hacer la petición
        const newToken = await getNewToken();
        token = newToken;
        expirationTime = Date.now() + 3600 * 1000; // Suponiendo que el nuevo token expira en una hora
    }
    const requestHeaders = {
        headers: {
            "Authorization": 'bearer ' + token,
            "X-EBAY-C-MARKETPLACE-ID": 'EBAY_ES'
        },
    };
    const requestOptions = { ...(requestHeaders) }
    ////console.log("Haciendo peticion a EBAY, con token: " + token)
    const params = new URLSearchParams();

    for (const prop in requestData) {
        params.set(prop, requestData[prop]);
    }

    const url = "https://api.ebay.com/buy/browse/v1/item_summary/search?" + params.toString()
    console.log(requestOptions)
    let data = ""
    await fetch(url, requestOptions, { mode: 'no-cors' })
        .then(response => response.json())
        .then(response => {

            data = response.itemSummaries


        })
        .catch(err => {
            console.error(err)
        });
    //console.log(data)
    return data;
};

// Función para obtener un nuevo token
const getNewToken = async (authcode) => {

    if (!isTokenExpired(localStorage.getItem('e_rexpire')) && localStorage.getItem('e_rToken') != undefined) {
        return await RefreshAccessToken(localStorage.getItem('e_rToken'));
    } else {
        //console.log("Pide token")
        const requestOptions2 = {
            method: 'GET'
        };
        const ncartas = await ApiFetch({ requestOptions: requestOptions2, url: "/ebay/callback?code=" + authcode });

        const exitoso = ncartas.data;
        let expirationTime = Date.now() + exitoso.expires_in * 1000;
        let r_expirationTime = Date.now() + exitoso.refresh_token_expires_in * 1000;
        localStorage.setItem('e_aToken', exitoso.access_token)
        localStorage.setItem('e_rToken', exitoso.refresh_token)
        localStorage.setItem('e_aexpire', expirationTime)
        localStorage.setItem('e_rexpire', r_expirationTime)

        return exitoso.access_token;
    }


};

// Función para comprobar si el token ha expirado
const isTokenExpired = (expirationTime) => {
    //console.log("Comprobando si ha expirado: " + expirationTime)
    return Date.now() >= expirationTime;
};

const RefreshAccessToken = async (rtoken) => {
    //console.log("Entra Refresh Access Token: " + rtoken)
    const requestOptions2 = {
        method: 'POST',
        body: JSON.stringify({
            rtoken: (rtoken)
        })
    };

    ////console.log("Pedido token ebay, auth: " + req.query.code)
    ////console.log("Auth code: " + req.query.code)
    const r = await ApiFetch({ requestOptions: requestOptions2, url: "/ebay/refreshtoken?rtoken=" + rtoken });

    let expirationTime = Date.now() + r.data.expires_in * 1000;
    localStorage.setItem('e_aToken', r.data.access_token)
    localStorage.setItem('e_aexpire', expirationTime)
    //console.log("nuevo access Toke: " + r.data.access_token)
    return r.data.access_token

}

const openPopup = async () => {
    const authurl = "https://auth.ebay.com/oauth2/authorize?client_id=Guillerm-CardMana-PRD-667414f10-e6d70821&response_type=code&redirect_uri=Guillermo_Navar-Guillerm-CardMa-yyhpbo&scope=https://api.ebay.com/oauth/api_scope%20https://api.ebay.com/oauth/api_scope/sell.marketing.readonly%20https://api.ebay.com/oauth/api_scope/sell.marketing%20https://api.ebay.com/oauth/api_scope/sell.inventory.readonly%20https://api.ebay.com/oauth/api_scope/sell.inventory%20https://api.ebay.com/oauth/api_scope/sell.account.readonly%20https://api.ebay.com/oauth/api_scope/sell.account%20https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly%20https://api.ebay.com/oauth/api_scope/sell.fulfillment%20https://api.ebay.com/oauth/api_scope/sell.analytics.readonly%20https://api.ebay.com/oauth/api_scope/sell.finances%20https://api.ebay.com/oauth/api_scope/sell.payment.dispute%20https://api.ebay.com/oauth/api_scope/commerce.identity.readonly%20https://api.ebay.com/oauth/api_scope/commerce.notification.subscription%20https://api.ebay.com/oauth/api_scope/commerce.notification.subscription.readonly"

    const POPUP_HEIGHT = 700;
    const POPUP_WIDTH = 600;
    // To fix issues with window.screen in multi-monitor setups, the easier option is to
    // center the pop-up over the parent window.
    const top = window.outerHeight / 2 + window.screenY - POPUP_HEIGHT / 2;
    const left = window.outerWidth / 2 + window.screenX - POPUP_WIDTH / 2;


    const popup = window.open(authurl, 'OAuth2 Popup',
        `height=${POPUP_HEIGHT},width=${POPUP_WIDTH},top=${top},left=${left}`);
    return new Promise(resolve => {
        const checkPopupUrl = setInterval(async () => {

            if (popup.closed) {
                clearInterval(checkPopupUrl);
                return ""
            } else if (popup.location.href !== 'about:blank') {
                ////console.log(`La URL actual del pop-up es: ${popup.location.href}`);
                ////console.log(popup.location.href)
                const params = popup.location.href.toString().split("code=")
                const c = params[1].split("&")[0]
                ////console.log(c)
                popup.close();
                let token = ""
                /*await getNewToken(c).then(t => {
                    token = t
                })
                //console.log(token)*/
                //return c;
                resolve(c)
            }
        }, 1000);
    });
}

export default ApiEbay;

