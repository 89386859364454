import React from 'react';
import Constantes from "../../Constantes";
import { useState, useEffect } from "react";
import Pagination from '@mui/material/Pagination';

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select';
import Button from '@mui/material/Button';

import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import Loading from "../../Functions/Loading";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import PropTypes from 'prop-types';

import InfoColeccion from '../../Colecciones/InfoColeccion';
import EditarColeccion from '../../Colecciones/EditarColeccion';
import FilaDeTablaDeCarta from '../../FilaDeTablaDeCarta';

import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';

import { CSVLink, CSVDownload } from "react-csv";
import pdfMake from 'pdfmake/build/pdfmake';

import ToggleButton from '@mui/material/ToggleButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RouteCartas from '../../routes/cartas';
import FiltrosCartas from './FiltrosCartas';

import ApiFetch from '../../Functions/ApiFetch';

import theme from '../../Theme/theme';


const ListaCartas = (props) => {
    const [cartas, setCartas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredcol, setFilteredcol] = useState([]);
    const [beforesearch, setBeforesearch] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [PdfTitle, setPdfTitle] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [count, setcount] = useState(0);
    const [coleccion, setColeccion] = useState({ name: "-", id: 0 });
    const [escoleccion, setEsColeccion] = useState("%");
    const [faltantes, setFaltantes] = useState([]);
    const [obtenidas, setObtenidas] = useState([]);
    const [perpage, setPerPage] = useState(25);
    const [showfaltantes, setShowFaltantes] = useState(false);
    const [showrepetidas, setShowRepetidas] = useState(false);
    const [showrc, setShowRC] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [orderqty, setorderqty] = useState(false);
    const [year, setYear] = useState("Todos");
    const [editquantity, setEditquantity] = useState(false);

    if (props.perpage !== undefined) {
        setPerPage(props.perpage);
    }



    // Llama una vez al iniciar
    useEffect(() => {
        componentDidMount();
    }, [props]);


    const componentDidMount = async () => {
        var idcoleccion = props.idcoleccion;
        //this.fetchData(idcoleccion);
        if (idcoleccion == undefined || idcoleccion == "%") {
            idcoleccion = "%";
            setEsColeccion(false);
            var col = [];
            col.name = "";
            col.id = 0;
            setPdfTitle("totales")
            setColeccion({ name: "", id: 0 })
        } else {
            const respuestac = await fetch(`${Constantes.RUTA_API}/obtener_colecciones.php`);
            const colecciones = await respuestac.json();
            const filtered = colecciones.filter(
                (col) => col.id && col.id == (idcoleccion)
            );
            var coleccion = filtered;
            setPdfTitle(filtered[0].name)
            setColeccion(coleccion[0])
            setEsColeccion(true);
        }


        const requestOptions = {
            method: 'GET',
        };
        const ncartas = await ApiFetch({ requestOptions: requestOptions, url: "/cartas?iduser=" + props.id_user + "&idcoleccion=" + idcoleccion });
        let cartas = ncartas.data;


        /*const requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": 'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoicHJ1ZWJhYXBpIiwiaWF0IjoxNjczNjMwMDMwLCJleHAiOjE2NzM2MzA5MzB9.PfHozWSitRRsOWfZ0Eh2dFFFlClq6puaphBaSUFFdtQ',
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: "pruebaapi",
                password: "123456aA"
            })
        };

        const respuestatest = await fetch(`http://localhost:3001/users/login`, requestOptions);
        let cartastest = await respuestatest.json();
        console.log(cartastest.accessToken)*/




        /*const requestOptions = {
            method: 'GET',
             headers: {
                 "authorization": 'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoicHJ1ZWJhYXBpIiwiaWF0IjoxNjczNjM2OTQ0LCJleHAiOjE2NzM2Mzc4NDR9.GwEBSM2R1eqMBUoraB1Rzm3V0T_beVJOfYtWrblf704',
                 'Accept': 'application/json',
                 'Content-Type': 'application/json'
             }
        };*/

        //const ncartas = await ApiFetch({ requestOptions: requestOptions, url: "/cartas" });

        /*await fetch(`http://localhost:3001/cartas`, requestOptions)
            .then(response => {
                response.json()
                if (response.status === 403) {
                    console.log("Code " + response.status)
                }
            })
            .then(response => {
                console.log(response);
            })
            .catch(err => {
                console.error(err)
            });*/




        setcount(Math.ceil(cartas.length / perpage))
        // "refrescar" el formulario
        setCartas(cartas)
        setFilteredcol(cartas)
        setBeforesearch(cartas)
        setData(cartas)


        if (props.searchterm) {
            var valor = "";
            valor = props.searchterm;
            setSearchTerm(valor)
            const filtered = cartas.filter(
                (c) => c.name && c.name.toUpperCase().includes(valor.toUpperCase()) || c.team.toUpperCase().includes(valor.toUpperCase()) || c.num_carta.toUpperCase().includes(valor.toUpperCase())
            );
            setcount(Math.ceil(filtered.length / perpage))
            setData(filtered)
            setFilteredcol(filtered)
            setCurrentPage(0);
            setPdfTitle(valor)
        }

        setLoading(false)


    }



    const handleChange = (e, p) => {
        setCurrentPage(p);
    };



    const handleChangeRowsPerPage = (event) => {
        //setRowsPerPage(parseInt(event.target.value, 10));
        setPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };




    const handleSearchChange = (e) => {
        const valor = e.target.value;
        setSearchTerm(valor)
        const filtered = beforesearch.filter(
            (c) => c.name && c.name.toUpperCase().includes(valor.toUpperCase()) || c.team.toUpperCase().includes(valor.toUpperCase()) || c.num_carta.toUpperCase().includes(valor.toUpperCase())
        );
        setcount(Math.ceil(filtered.length / perpage))
        setData(filtered)
        setFilteredcol(filtered)
        setCurrentPage(0);
    }



    const removefilters = () => {
        setorderqty(false);
        setcount(Math.ceil(cartas.length / perpage))
        setData(cartas)
        setFilteredcol(cartas)
    }

    const setData = (cartas) => {
        const faltantes = cartas.filter(function (carta) {
            if (carta.quantity < 1) {
                return true;
            } else {
                return false;
            }
        });
        const obtenidas = cartas.filter(function (carta) {
            if (carta.quantity > 0) {
                return true;
            } else {
                return false;
            }
        });
        setFaltantes(faltantes.length)
        setObtenidas(obtenidas.length)
    }

    const ConvertIMG = async (imgurl) => {
        let respuesta = "";
        respuesta = await fetch(`${Constantes.RUTA_API}/tobase64.php?img=${imgurl}`);
        const exitoso = await respuesta.json();
        if (exitoso) {
            let resp = JSON.parse(exitoso)
            //console.log(resp.result)
            return resp.result;
        }
    }

    const exportPDF = async () => {
        var tbody = [['Num', 'Carta', 'Equipo', 'Coleccion', 'Año', 'Cantidad']
        ]
        var numAscending = []
        // If not is ordered by quantity, order by card num.
        if (orderqty) {
            numAscending = filteredcol
        } else {
            numAscending = [...filteredcol].sort((a, b) => a.num_carta.split("-")[0] - b.num_carta.split("-")[0]);
        }

        numAscending.forEach((c) => {
            var qty = c.quantity
            if (qty == null) {
                qty = 0;
            }
            if (c.year == null) {
                c.year = "-"
            }
            if (qty == 0) {
                tbody.push([
                    { text: c.num_carta.split("-")[0], style: 'falta' },
                    { text: c.name, style: 'falta' },
                    { text: c.team, style: 'falta' },
                    { text: c.coleccion, style: 'falta' },
                    { text: c.year, style: 'falta' },
                    { text: qty, style: 'falta' }])
            } else if (qty > 1) {
                tbody.push([
                    { text: c.num_carta.split("-")[0], style: 'repetida' },
                    { text: c.name, style: 'repetida' },
                    { text: c.team, style: 'repetida' },
                    { text: c.coleccion, style: 'repetida' },
                    { text: c.year, style: 'repetida' },
                    { text: qty, style: 'repetida' }])
            } else {
                tbody.push([c.num_carta.split("-")[0], c.name, c.team, c.coleccion, c.year, qty])
            }



        })

        var falt = ""
        if (showfaltantes) {
            falt = "FALTANTES "
        }
        var yeartitle = "";
        if (year != "Todos") {
            yeartitle = " (" + year + ")"
        }

        var docDefinition = {
            content:
                [
                    { text: 'Cartas ' + falt + ' ' + PdfTitle + yeartitle, style: 'header' },
                    { text: 'Carta faltante', fontSize: 10, color: '#E7EC97' },
                    { text: 'Carta Repetida', fontSize: 10, color: '#E09090' },

                    {
                        style: 'table',
                        table: {
                            // headers are automatically repeated if the table spans over multiple pages
                            // you can declare how many rows should be treated as headers
                            headerRows: 1,
                            widths: ['auto', '*', "*", "auto", 'auto', 'auto'],

                            body: tbody,

                        }
                    },
                ],
            styles: {
                header: { fontSize: 15, bold: true, margin: [0, 10, 0, 10], alignment: 'center' },
                tableHeader: { fillColor: '#0d989c', color: 'red', fontSize: 10 },
                table: {
                    fontSize: 8,
                    alignment: 'center',
                    color: 'black',
                    margin: [0, 5, 0, 15]
                },
                falta: { fillColor: '#E7EC97', color: 'black' },
                repetida: { fillColor: '#E09090', color: 'black' },
            },
            footer: {
                columns: [
                    { text: 'CardManagement', margin: 15 },
                    { text: (faltantes + obtenidas) + ' cartas  ', alignment: 'right', margin: 15 }
                ]
            }
        };
        // open the PDF in a new window
        pdfMake.createPdf(docDefinition).open();

        // print the PDF
        // pdfMake.createPdf(docDefinition).print();

        // download the PDF
        //pdfMake.createPdf(docDefinition).download('optionalName.pdf');
    }


    const currentData = () => {
        const begin = (currentPage) * perpage;
        const end = begin + perpage;

        return filteredcol.slice(begin, end);
    }

    const filteredData = (filtered, year) => {
        setYear(year)
        setcount(Math.ceil(filtered.length / perpage))
        setData(filtered)
        setCurrentPage(0);
        setBeforesearch(filtered)
        setFilteredcol(filtered)
    }


    const options = [
        'Desc',
        'Asc',
    ];
    const ITEM_HEIGHT = 48;
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOrder = (e, i, item) => {
        var filtered = [];
        // 0 = DESC, 1 = ASC
        if (i == 1 && item === "qty") {
            filtered = [...filteredcol].sort((a, b) => a.quantity - b.quantity);
            setorderqty(true);
        } else if (i == 0 && item === "qty") {
            filtered = [...filteredcol].sort((a, b) => b.quantity - a.quantity);
            setorderqty(true);
        } else if (i == 1 && item === "num") {
            filtered = [...filteredcol].sort((a, b) => a.num_carta.split("-")[0] - b.num_carta.split("-")[0]);
        } else if (i == 0 && item === "num") {
            filtered = [...filteredcol].sort((a, b) => b.num_carta.split("-")[0] - a.num_carta.split("-")[0]);
        }


        setCurrentPage(0);
        setcount(Math.ceil(filtered.length / perpage))
        setData(filtered)
        setFilteredcol(filtered)
        handleClose()
    }

    const editquantities = () => {
        setEditquantity(!editquantity)
    }

    const MenuOrder = (props) => {
        return (
            <Menu
                id={"long-menu-" + props.col}
                MenuListProps={{
                    'aria-labelledby': 'long-button-' + props.col,
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch',
                    },
                }}
            >
                {options.map((option, index) => (
                    <MenuItem key={option} selected={option === 'Pyxis'} onClick={(event) => handleOrder(event, index, props.col)}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        )
    }

    return (
        <div className={'container '} >
            {loading ? (
                <Loading />
            ) : (

                <>
                    <Grid container className='containerblock ' spacing={{ xs: 2, md: 5 }} alignItems="center" justifyContent="center" >
                        {escoleccion ?
                            <>
                                <Grid item xs={12} md={4}>
                                    <InfoColeccion coleccion={coleccion} cartas={filteredcol} />
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <EditarColeccion coleccion={coleccion} />
                                </Grid>
                            </>
                            : <>
                                <Grid item xs={12} md={12}>

                                </Grid>
                            </>}
                        <Grid className='block' item xs justifyContent="center" alignItems="center" style={{ zIndex: 50 }}>
                            <Grid container justifyContent="center" alignItems="center">
                                <Grid item xs={12} md>
                                    <LinearProgress thickness={40} variant="determinate" value={(obtenidas / (obtenidas + faltantes) * 100)} />
                                    <p></p>
                                    <span style={{ color: '#7FF84E' }}>{obtenidas}</span>{" / " + (obtenidas + faltantes)} - <span className='secondarycolor'> {(obtenidas / (obtenidas + faltantes) * 100).toFixed(2)}%</span> obtenidas, <span style={{ color: '#F84E4E' }}>{faltantes} </span>faltantes
                                </Grid>
                                <Grid item xs={12} md={5}></Grid>
                                <Grid item xs={6} md={0.75}>
                                    Exportar:
                                </Grid>
                                <Grid item xs={6} md={0.75}>
                                    <Button variant="contained" color="success" >
                                        <CSVLink
                                            data={filteredcol}
                                            className="none"
                                            filename={"cartas_" + PdfTitle + ".csv"}
                                        >CSV</CSVLink>
                                    </Button>
                                </Grid>
                                <Grid item xs={6} md={0.75}>

                                    <Button variant="contained" color="error" onClick={exportPDF}>
                                        PDF
                                    </Button>
                                </Grid>
                                <Grid item className="centeredtext" xs={12} md={2}>
                                    <FormControl variant="outlined">
                                        <InputLabel className='whitecolor' htmlFor="card-search">Buscar</InputLabel>
                                        <OutlinedInput
                                            id="card-search"
                                            type="text"
                                            label="Search.."
                                            value={searchTerm}
                                            theme={theme}
                                            color="secondary"
                                            variant="outlined"
                                            onChange={handleSearchChange}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        className='whitecolor'
                                                        aria-label="Search"
                                                    >
                                                        <SearchIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item className="centeredtext" xs={12} md={12}>
                                    <FiltrosCartas cartas={filteredcol} set={filteredData} cur={currentData} removefilters={removefilters} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12} className='block'>
                            {editquantity ? (<Button variant="contained" color="error" onClick={editquantities}>
                                Cancelar
                            </Button>) : (
                                <Button variant="contained" color="success" onClick={editquantities}>
                                    Editar Cantidades
                                </Button>
                            )}

                            <TableContainer >
                                <Table aria-label="sticky table">
                                    <TableHead >
                                        <TableRow >
                                            <TableCell sx={{ width: 60 }} className='whitecolor'>
                                                Foto
                                            </TableCell>
                                            <TableCell sx={{ width: 80 }} className='whitecolor'>
                                                Num. Carta
                                            </TableCell>
                                            <TableCell className='whitecolor'>
                                                Carta
                                            </TableCell>
                                            <TableCell sx={{ width: 180 }} className='whitecolor'>
                                                Atributos
                                            </TableCell>
                                            <TableCell className='whitecolor'>
                                                Colección
                                            </TableCell>
                                            <TableCell sx={{ width: 100 }} className='whitecolor'>
                                                Cantidad
                                                <IconButton
                                                    aria-label="more"
                                                    id="long-button-qty"
                                                    className='whitecolor'
                                                    aria-controls={open ? 'long-menu-qty' : undefined}
                                                    aria-expanded={open ? 'true' : undefined}
                                                    aria-haspopup="true"
                                                    onClick={handleClick}
                                                >
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <MenuOrder col="qty" />
                                            </TableCell >
                                            <TableCell sx={{ width: 100 }} className='whitecolor'>
                                                Acciones
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {currentData().map(c => {
                                            return (
                                                <FilaDeTablaDeCarta id_user={props.id_user} key={c.id} carta={c} editqty={editquantity}></FilaDeTablaDeCarta>
                                            )

                                        })}
                                    </TableBody>
                                    <TableFooter
                                        className='whitecolor'>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25, 50, { label: 'Todas', value: -1 }]}
                                                colSpan={3}

                                                className='whitecolor'
                                                count={parseInt(count * perpage)}
                                                rowsPerPage={perpage}
                                                page={currentPage}
                                                SelectProps={{
                                                    inputProps: {
                                                        'aria-label': 'rows per page',
                                                    },
                                                    native: true,
                                                }}
                                                onPageChange={handleChange}
                                                showFirstButton={true}
                                                showLastButton={true}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </>
            )
            }


        </div >
    );
}

export default ListaCartas;