import { useParams } from "react-router-dom";
import ListaCartas from "../Components/Cartas/ListaCartas";

export default function RouteCartas(props) {
    let params = useParams();
    if (params.idcoleccion === undefined || params.idcoleccion == "") {
        params.idcoleccion = "%";
    }

    if (params.searchterm === null) {
        params.searchterm = "";
        params.idcoleccion = "%";
    }


    return <ListaCartas idcoleccion={params.idcoleccion} id_user={props.id_user} searchterm={params.searchterm} />
}