import React from 'react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './fonts/Lato/Lato-Regular.ttf'
//import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import "./style.css";
import getSession from './Login/getSession'
//import 'font-awesome/css/font-awesome.min.css';

import {
  BrowserRouter
} from "react-router-dom";
import { createRoot } from 'react-dom/client';
import Login from './Login/Login';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript



//const auth = JSON.parse(localStorage.getItem('aToken'))

if (localStorage.getItem('uid') && localStorage.getItem('uid') != "null") {
  //getSession();

  // Create a root.
  var uid = localStorage.getItem('uid')
  var theme = localStorage.getItem('theme')
  document.body.classList.add(theme)
  root.render(
    <BrowserRouter >
      {/* <React.StrictMode> 
      
      <App id_user={uid} theme={theme} />
  </React.StrictMode>*/ }
      <App id_user={uid} theme={theme} />
    </BrowserRouter >,
    //rootElement
  );
} else {
  localStorage.removeItem('aToken')
  localStorage.removeItem('rToken')
  localStorage.removeItem('aexpire')
  localStorage.removeItem('uid')
  root.render(<Login />);
}



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
