import React from 'react';
import { toast } from "react-toastify";
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Loading from '../../Functions/Loading';
import Avatar from '@mui/material/Avatar';
import { Input } from '@mui/material';
import Chip from '@mui/material/Chip';
import EastIcon from '@mui/icons-material/East';

import { useState, useEffect } from "react";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import OutboxIcon from '@mui/icons-material/Outbox';
import ImgCarta from './ImgCarta';

const CartaSeleccionada = (props) => {
    const [carta, setCarta] = useState({ name: "Selecciona carta", num_carta: "" });
    const [pendientestr, setPendientestr] = useState("");

    useEffect(() => {
        componentDidMount();
    }, [props.carta]);

    const componentDidMount = async () => {
        if (!props.carta.precibir) {
            props.carta.precibir = 0;
        }
        if (!props.carta.penviar) {
            props.carta.penviar = 0;
        }
        if (!props.carta.quantity) {
            props.carta.quantity = 0;
        }
        setCarta(props.carta)
        setPendientestr(props.pendientestr)

    }


    return (
        <div className="centeredtext">
            {
                carta.id ? (
                    <div key={props.carta} className="selected_container" >
                        <div className="selected_container__profile">
                            <Chip label={carta.num_carta} color="warning" variant="outlined" />

                            <div className="selected_container__profile__text">
                                <h2>{carta.name}</h2>
                                <p><b>{carta.team}</b></p>
                            </div>
                            <ImgCarta carta={carta} size="small" />
                        </div>

                        <div className="selected_container__info">
                            <span title="Stock actual"><Inventory2Icon style={{ marginRight: "10px" }} />{carta.quantity}</span>
                            <span title="Pendiente de recibir"><MoveToInboxIcon style={{ marginRight: "10px" }} />{carta.precibir}</span>
                            <span title="Pendiente de enviar"><OutboxIcon style={{ marginRight: "10px" }} />{carta.penviar}</span>
                            <span title="Cantidad real" style={{ color: "white" }} ><EastIcon style={{ marginRight: "10px" }} /> {parseInt(carta.quantity) - parseInt(carta.penviar) + parseInt(carta.precibir)}</span>
                        </div>
                    </div>
                ) : (
                    <>
                        <Chip className='secondarycolor' label={carta.name} variant="outlined" />
                    </>
                )
            }

        </div>
    )
}

export default CartaSeleccionada;