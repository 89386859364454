import React from "react";
import Grid from '@mui/material/Grid';
import Loading from '../../Functions/Loading';
import { useState, useEffect } from "react";

import ApiEbay from "../../Functions/ApiEbay";
import ApiFetch from "../../Functions/ApiFetch";

import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import SaveIcon from '@mui/icons-material/Save';

import theme from "../../Theme/theme";

const BuscarEbay = (props) => {
    const [cartas, setCartas] = useState([]);
    const [ebaydata, setEbaydata] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [busqueda, setBusqueda] = useState("");
    const [limite, setLimite] = useState(15);
    const [categorias, setCategorias] = useState("212");
    const [tipos, setTipos] = useState([]);
    const [otros, setOtros] = useState([]);
    const [precioMaximo, setPrecioMaximo] = useState(25);
    const [orden, setOrden] = useState("endingSoonest");

    // Llama una vez al iniciar
    useEffect(() => {
        componentDidMount();
    }, []);

    const componentDidMount = async () => {
        // Cat: 261329 - Trading card LOTS //// cat: 212 - Trading card singles

        //PricePlusShippingLowest
        setIsLoading(false)
    }

    const handleBusquedaChange = (event) => {
        setBusqueda(event.target.value);
    };

    const handleLimiteChange = (event) => {
        setLimite(event.target.value);
    };

    const handleCategoriasChange = (event) => {
        const selectedOptions = Array.from(event.target.selectedOptions).map((option) => option.value);
        setCategorias(selectedOptions);
    };

    const handleTiposChange = (event) => {
        const selectedOptions = Array.from(event.target.selectedOptions).map((option) => option.value);
        setTipos(selectedOptions);
    };

    const handleOtrosChange = (event) => {
        const selectedOptions = Array.from(event.target.selectedOptions).map((option) => option.value);
        setOtros(selectedOptions);
    };

    const handlePrecioMaximoChange = (event) => {
        setPrecioMaximo(event.target.value);
    };

    const handleOrdenChange = (event) => {
        setOrden(event.target.value);
    };

    const handleSubmit = async (event) => {
        // event.preventDefault();
        // Aquí puedes enviar el formulario a tu API o hacer lo que necesites con los datos del formulario
        //const ebaydata2 = await ApiEbay({ q: "ter stegen auto", limit: "15", category_ids: "212", filter: "buyingOptions:{AUCTION|BEST_OFFER},price:[1..25],priceCurrency:USD", sort: "endingSoonest" })
        // aspect_filter: "categoryId:" + categorias.toString() + ",Graded:{Yes},Grade:{10},Features:{Memorabilia|Serial Numbered},Professional Grader:{Professional Sports Authenticator (PSA)}"
        // EBAY US:
        /*
        let ebay_params = { q: busqueda, limit: limite, category_ids: "" + categorias.toString() + "", filter: "buyingOptions:{" + tipos.toString().replace(/,/g, "|") + "},price:[1.." + precioMaximo + "],priceCurrency:USD,deliveryCountry:US,itemLocationRegion:EUROPEAN_UNION", sort: orden, aspect_filter: "categoryId:" + categorias.toString() + "," + otros.toString() }
        */
        // EBAY_ES
        let ebay_params = { q: busqueda, limit: limite, category_ids: "" + categorias.toString() + "", filter: "buyingOptions:{" + tipos.toString().replace(/,/g, "|") + "},price:[1.." + precioMaximo + "],priceCurrency:EUR,deliveryCountry:ES,itemLocationRegion:EUROPEAN_UNION", sort: orden, aspect_filter: "categoryId:" + categorias.toString() + "," + otros.toString() }
        const ebaydata2 = await ApiEbay(ebay_params)
        //console.log(ebaydata2)
        setCartas(ebaydata2)
    };

    const SaveSearch = async (event) => {
        let ebay_params = { q: busqueda, limit: limite, category_ids: "" + categorias.toString() + "", filter: "buyingOptions:{" + tipos.toString().replace(/,/g, "|") + "},price:[1.." + precioMaximo + "],priceCurrency:EUR,deliveryCountry:ES,itemLocationRegion:EUROPEAN_UNION", sort: orden, aspect_filter: "categoryId:" + categorias.toString() + "," + otros.toString() }

        const cargaUtil = JSON.stringify(ebay_params);

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({
                params: JSON.parse(cargaUtil),
                title: busqueda
            })
        };
        const ncartas = await ApiFetch({ requestOptions: requestOptions, url: "/ebay/notification/create" });

        const exitoso = ncartas.result;
        console.log(exitoso)
    }

    const handleEnterPressed = (event) => {
        if (event.key === "Enter") {
            handleSubmit("Se ha presionado Enter");
        }
    };

    const calculateTimeLeft = (endDate = "") => {
        // Comprueba si la fecha tiene el formato correcto
        if (endDate === undefined || !endDate.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/)) {
            return "N/A";
        }

        const difference = new Date(endDate) - new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
            };
        }

        return timeLeft.days + "d " + timeLeft.hours + "h " + timeLeft.minutes + "m";
    };

    return (
        <>
            {isLoading ? (
                <><Loading></Loading></>
            ) : (<>
                <div className="container whitecolor">
                    <Grid container className='containerblock' spacing={{ xs: 2, md: 2 }} theme={theme}>
                        <Grid item xs={12} md={2}>
                            <TextField
                                fullWidth
                                required
                                id="outlined-required"
                                label="Busqueda"
                                value={busqueda}
                                theme={theme}
                                onChange={handleBusquedaChange}
                                onKeyDown={handleEnterPressed}
                            />
                        </Grid>
                        <Grid item xs={6} md={1}>
                            <TextField
                                id="outlined-number"
                                label="Límite"
                                type="number"
                                value={limite}
                                onChange={handleLimiteChange}
                                InputLabelProps={{
                                    shrink: true,
                                }} />
                        </Grid>

                        <Grid item xs={6} md={1}>
                            <TextField
                                id="outlined-number"
                                label="Precio Max"
                                type="number"
                                value={precioMaximo}
                                className="whitecolor"
                                theme={theme}
                                color="secondary"
                                onChange={handlePrecioMaximoChange}
                                InputLabelProps={{
                                    shrink: true,
                                }} />
                        </Grid>
                        <Grid item xs={12} md={1.5}>
                            <FormControl fullWidth>
                                <InputLabel shrink htmlFor="select-multiple-native">
                                    Orden
                                </InputLabel>
                                <Select
                                    native
                                    // @ts-ignore Typings are not considering `native`
                                    onChange={handleOrdenChange}
                                    label="Orden"
                                    className="whitecolor"
                                    inputProps={{
                                        id: 'select-multiple-native',
                                    }}
                                >
                                    <option value="endingSoonest">Primeros en finalizar</option>
                                    <option value="precio_asc">Precio ascendente</option>
                                    <option value="precio_desc">Precio descendente</option>
                                </Select>
                            </FormControl>

                        </Grid>
                        <Grid item xs={12} md={2}>
                            <FormControl fullWidth >
                                <InputLabel shrink htmlFor="select-multiple-nativec">
                                    Categoria
                                </InputLabel>
                                <Select
                                    fullWidth
                                    native
                                    label="Categoria"
                                    className="whitecolor"
                                    onChange={handleCategoriasChange}
                                    inputProps={{
                                        id: 'select-multiple-nativec',
                                    }}
                                >
                                    <option value="212">Cartas Sueltas</option>
                                    <option value="261329">Lotes</option>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={1.5}>
                            <FormControl fullWidth>
                                <InputLabel shrink htmlFor="select-multiple-native">
                                    Tipo
                                </InputLabel>
                                <Select
                                    fullWidth
                                    multiple
                                    native
                                    label="Tipo"
                                    className="whitecolor"
                                    onChange={handleTiposChange}
                                    inputProps={{
                                        id: 'select-multiple-native',
                                    }}
                                >
                                    <option value="BEST_OFFER">Aceptan Ofertas</option>
                                    <option value="AUCTION">Subasta</option>
                                    <option value="FIXED_PRICE">Precio Fijo</option>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={1.5}>
                            <FormControl fullWidth>
                                <InputLabel shrink htmlFor="select-multiple-native">
                                    Otros filtros
                                </InputLabel>
                                <Select
                                    fullWidth
                                    multiple
                                    native
                                    label="Otros filtros"
                                    className="whitecolor"
                                    onChange={handleOtrosChange}
                                    inputProps={{
                                        id: 'select-multiple-native',
                                    }}
                                >
                                    <option value="Graded:{Yes},Grade:{10},Professional Grader:{Professional Sports Authenticator (PSA)}">PSA 10</option>
                                    <option value="Graded:{Yes},Grade:{9},Professional Grader:{Professional Sports Authenticator (PSA)}">PSA 9</option>
                                    <option value="Features:{Memorabilia}">Mamorabilia</option>
                                    <option value="Features:{Serial Numbered}">Numerada</option>
                                    <option value="Autographed:{Yes}">Auto</option>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} md={1.5}></Grid>
                        <Grid item xs={6} md={1.5}>
                            <Button variant="contained" color="success" onClick={handleSubmit}>
                                Buscar en Ebay
                            </Button>
                        </Grid>       <Grid item xs={3} md={1}>
                            <Button variant="contained" onClick={SaveSearch}>
                                <SaveIcon />
                            </Button>

                        </Grid>
                        <Grid item xs={6} md={2}>
                            {"Resultados: " + cartas.length}
                        </Grid>
                        <Grid item xs={12} md={12}>
                            {
                                cartas === undefined ? (
                                    "Sin resultados"
                                ) : (
                                    <>


                                        {cartas.map((carta) => (
                                            <div key={carta.itemId} className="product-card">
                                                <div className="product-image-container">
                                                    <img className="product-image" src={carta.image.imageUrl} alt={carta.title} />
                                                </div>
                                                <Tooltip title={carta.title}>
                                                    <h3 className="product-title">{carta.title.length > 60 ? carta.title.substring(0, 60) + '...' : carta.title}</h3>
                                                </Tooltip>
                                                <p className="product-price">{
                                                    carta.buyingOptions[0] === 'AUCTION' ? (
                                                        carta.currentBidPrice.value + "$ ( " + carta.bidCount + " pujas)"
                                                    ) : (
                                                        carta.price.value + "$"
                                                    )
                                                }</p>
                                                <p className="product-endate">{calculateTimeLeft(carta.itemEndDate)}</p>
                                                <a className="product-link" href={carta.itemWebUrl} target="_blank" rel="noopener noreferrer">Ver en eBay</a>
                                            </div>
                                        ))
                                        } </>
                                )
                            }
                        </Grid>
                    </Grid>
                </div>
            </>)
            }

        </>
    )
}

export default BuscarEbay;