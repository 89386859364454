import Constantes from "../Constantes";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import bcrypt from 'bcryptjs'
import Session from "../classes/session";
import "../style.css";
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { TextField } from "@mui/material";
import { LoginUser } from "./RefreshToken";
import theme from "../Theme/theme";

function Login() {
    // React States
    const [errorMessages, setErrorMessages] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    Session.setIp();


    const errors = {
        uname: "invalid username",
        pass: "invalid password"
    };


    const handleSubmit = async (event) => {
        //Prevent page reload
        event.preventDefault();

        var { uname, pass } = document.forms[0];



        // cambiar database por datos recibidos de la API

        /*var pwd = bcrypt.hashSync(pass.value, bcrypt.genSaltSync())
        console.log(pwd)*/
        /*const respuesta = fetch(`${Constantes.RUTA_API}/login.php?u=${uname.value}`);
        const userInfo = respuesta.json();*/

        /*const fetchedData = fetch(`${Constantes.RUTA_API}/login.php?u=${uname.value}`)
            .then(result => result.json())
            .then(data => {
                const doesPasswordMatch = bcrypt.compareSync(pass.value, data.password)
                console.log("Match: " + doesPasswordMatch)
                if (doesPasswordMatch) {
                    Session.setName(data.username)
                    Session.setUserId(data.id)
                    // Esperar a la IP y crear sesion
                    waitForElement()
                    //console.log("Crear sesión IP: " + Session.getIp())
                } else {
                    // Invalid password
                    setErrorMessages({ name: "pass", message: errors.pass });
                }
            })
            .catch(({ response }) => {
                // Username not found
                setErrorMessages({ name: "uname", message: errors.uname });
                console.log(response.data)
            })*/

        let user = await LoginUser({ user: uname.value, pwd: pass.value })

        window.location.reload(false);

    };

    function waitForElement() {
        var dip = Session.getIp()
        var uid = Session.getUserId()
        if (dip !== "") {
            //variable exists, create session
            const fetchedData = fetch(`${Constantes.RUTA_API}/create_session.php?u=${uid}&ip=${dip}`)
                .then(result => result.json())
                .then(data => {
                    //console.log("Sesion creada: " + data)
                    localStorage.setItem('auth', '"yes"')
                    localStorage.setItem('uid', uid)
                    window.location.reload(false);

                })
                .catch(({ response }) => {
                    // Username not found
                    console.log(response.data)
                })
        }
        else {
            //console.log("Waiting")
            setTimeout(waitForElement, 250);
            dip = Session.getIp()

        }

    }

    // Generate JSX code for error message
    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div className="error">{errorMessages.message}</div>
        );



    // JSX code for login form
    const renderForm = (
        <Grid container spacing={{ xs: 1, md: 2 }}>
            <Grid item xs={12} md={12}>
                <div className="containerblock loginblock">
                    <form onSubmit={handleSubmit}>
                        <Grid item xs={12}>
                            <TextField className="loginfield" label="User" type={'text'}
                                sx={{
                                    input: { color: 'white' },
                                    '& fieldset': {
                                        borderColor: 'white',
                                    },
                                    '& label': {
                                        color: 'white'
                                    },
                                }}
                                name="uname"
                                fullWidth />
                            {renderErrorMessage("uname")}
                        </Grid>
                        <br></br>
                        <Grid item xs={12}>
                            <TextField label="Password" type={'password'}
                                sx={{
                                    input: { color: 'white' },
                                    '& fieldset': {
                                        borderColor: 'white',
                                    },
                                    '& label': {
                                        color: 'white'
                                    },
                                }}
                                name="pass"
                                fullWidth />
                        </Grid>
                        <br></br>
                        <Grid item xs={12}>
                            <Button type="submit" variant="outlined"
                                theme={theme}
                                color={"secondary"}
                                fullWidth > Login </Button>
                        </Grid>
                    </form>
                </div>

            </Grid>
        </Grid>
    );

    return (
        <div className="loginpage">
            <div className="login-form">
                <div className="centeredtext"><h1>LOGIN</h1></div>
                {isSubmitted ? <div>User is successfully logged in</div> : renderForm}
            </div>
        </div>
    );
}

//const rootElement = document.getElementById("root");


export default Login