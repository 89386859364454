import React from "react";
import Constantes from "../Constantes";
import { RefreshToken } from "../Login/RefreshToken";
import axios from "axios";
import Compressor from 'compressorjs';

const ApiFile = async (props) => {
    let data = [];
    let status = 0;
    let ffile;
    var today = new Date().getTime()
    var diff = today - localStorage.getItem('aexpire')
    var diffMins = Math.round(((diff % 86400000) % 3600000) / 60000);
    //console.log("Diferencia minutos token: " + diffMins)
    let tokens = {
        accessToken: localStorage.getItem('aToken'),
        refreshToken: localStorage.getItem('rToken'),
    }

    if (diffMins > 60 || localStorage.getItem('aexpire') == undefined) { // Si han pasado más de 60min el token ha expirado
        console.log("Auth token expirado, refresh")
        tokens = await RefreshToken();
    }

    console.log("Subiendo archivo: " + props.file.name)
    if (localStorage.getItem('aToken') == undefined) {
        return [];
    }
    if (props.file.name.toUpperCase().includes('.JPG') || props.file.name.toUpperCase().includes('.PNG') || props.file.name.toUpperCase().includes('.JPEG')) {
        ffile = await (Compress(props.file))
    } else {
        ffile = props.file
    }


    var formData = new FormData();
    formData.append(
        "file",
        await (Compress(props.file)),
        props.name
    );

    /*console.log("Tokens")
    console.log(tokens.accessToken)*/

    const headers = {
        'Authorization': 'bearer ' + tokens.accessToken
    };

    await axios.post(Constantes.RUTA_API_JS + props.url, formData, { headers }).then((res) => {
        //console.log(res);
        if (status === 403 || res.status == 403) {
            localStorage.removeItem('aToken')
            localStorage.removeItem('rToken')
            localStorage.removeItem('aexpire')
            localStorage.removeItem('uid')
            window.location.reload(false);
        } else {
            data = res.data
        }
    })
        .catch(err => {
            console.error(err)
            localStorage.removeItem('aToken')
            localStorage.removeItem('rToken')
            localStorage.removeItem('aexpire')
            localStorage.removeItem('uid')
            window.location.reload(false);
        });



    return data;

}

const Compress = async (image) => {
    return new Promise((resolve, reject) => {
        new Compressor(image, {
            quality: 0.4, // 0.6 can also be used, but its not recommended to go below.
            maxHeight: 1920,
            success: resolve,
            error: reject
        },

        );
    })

}



export default ApiFile;

