import React from 'react';
import { toast } from "react-toastify";
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

class NewMessageNotification extends React.Component {

    displayMessage = () => {
        //remove all notifications
        toast.dismiss();

        //navigate to the link. I'll use location hash but it can be done with any router solution
        window.location.hash = this.props.link;

    }

    render() {
        return (
            <div className='centeredtext'>
                <div>
                    {this.props.text}
                </div>
                <Link to={this.props.link} >
                    <Button variant="outlined" onClick={this.displayMessage}>
                        Ver
                    </Button>
                </Link>
            </div>
        );
    }
}

export default NewMessageNotification;