import React from 'react';
import Select from 'react-select';
import { useState, useEffect } from "react";

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import Loading from "../../Functions/Loading";
import Grid from '@mui/material/Grid';

import theme from '../../Theme/theme';
import { Input } from '@mui/material';
import Button from '@mui/material/Button';
import { Stack } from '@mui/material';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';



const CalculadoraEbay = (props) => {
    const [loading, setLoading] = useState(true);
    const [precioVenta, setPrecioVenta] = useState(0);
    const [precioCoste, setPrecioCoste] = useState(0);
    const [precioCosteReal, setPrecioCosteReal] = useState(0);
    const [precioEnvio, setPrecioEnvio] = useState(0);
    const [precioCosteEnvio, setPrecioCosteEnvio] = useState(0);
    const [beneficios, setBeneficios] = useState(0);





    // Llama una vez al iniciar
    useEffect(() => {
        componentDidMount();
    }, []);


    const componentDidMount = async () => {

        setLoading(false);
    }
    const CalcularBeneficios = (v, c, e, ce) => {
        let comision = 11.5
        let comisionFija = 0.35
        const ingreso = parseFloat(v) + parseFloat(e)
        let coste = 0

        if ((ingreso) <= 10) {
            comisionFija = 0.05
        }
        comision = parseFloat(ingreso / 100 * comision) + parseFloat(comisionFija);
        coste = parseFloat(parseFloat(c) + comision + parseFloat(ce)).toFixed(2)
        setPrecioCosteReal(coste)
        setBeneficios(parseFloat(ingreso - coste).toFixed(2))





    }


    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <div className='block' style={{ zIndex: 50 }}>
                    <Grid container spacing={{ xs: 2, md: 2 }} className='centeredtext whitecolor' >
                        <Grid item xs={12} md={12} className='centeredtext' >
                            <Stack direction="row" className='containerheader ' spacing={0}>
                                <h4 className='centeredtext' >Calculadora ebay</h4>
                            </Stack>
                        </Grid>
                        <Grid item xs={6} md={6} >
                            <FormControl variant="outlined">
                                <InputLabel className='whitecolor' htmlFor="card-search">Precio Venta</InputLabel>
                                <OutlinedInput
                                    required
                                    id="venta"
                                    onChange={(event) => {
                                        setPrecioVenta(event.target.value);
                                        CalcularBeneficios(event.target.value, precioCoste, precioEnvio, precioCosteEnvio);
                                    }}
                                    label="Precio Venta"
                                    placeholder="20"
                                    variant="standard"
                                    type="number"
                                    theme={theme}
                                    color="secondary"
                                    className='whitecolor'
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">€</InputAdornment>,
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*'
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <FormControl variant="outlined">
                                <InputLabel className='whitecolor' htmlFor="card-search">Envio Ebay</InputLabel>
                                <OutlinedInput
                                    required id="envio"
                                    onChange={(event) => {
                                        setPrecioEnvio(event.target.value);
                                        CalcularBeneficios(precioVenta, precioCoste, event.target.value, precioCosteEnvio);
                                    }}
                                    label="Envio ebay"
                                    placeholder="20"
                                    fullWidth
                                    variant="standard"
                                    type="number"
                                    className='whitecolor'
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">€</InputAdornment>,
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*'
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <FormControl variant="outlined">
                                <InputLabel className='whitecolor' htmlFor="card-search">Precio coste</InputLabel>
                                <OutlinedInput
                                    required id="coste"
                                    onChange={(event) => {
                                        setPrecioCoste(event.target.value);
                                        CalcularBeneficios(precioVenta, event.target.value, precioEnvio, precioCosteEnvio);
                                    }}
                                    label="Precio Coste"
                                    placeholder="20"
                                    fullWidth
                                    className='whitecolor'
                                    variant="standard"
                                    type="number"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">€</InputAdornment>,
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*'
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <FormControl variant="outlined">
                                <InputLabel className='whitecolor' htmlFor="card-search">Coste envio</InputLabel>
                                <OutlinedInput
                                    required id="costeenvio"
                                    onChange={(event) => {
                                        setPrecioCosteEnvio(event.target.value);
                                        CalcularBeneficios(precioVenta, precioCoste, precioEnvio, event.target.value);
                                    }}
                                    label="Coste envio"
                                    placeholder="20"
                                    fullWidth
                                    className='whitecolor'
                                    variant="standard"
                                    type="number"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">€</InputAdornment>,
                                        inputMode: 'numeric',
                                        pattern: '[0-9]*'
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <p>Coste total: {precioCosteReal}€ -
                                Beneficios: {beneficios}€</p>
                        </Grid>

                    </Grid>

                </div >
            )
            }


        </ >
    );
}

export default CalculadoraEbay;