import React from 'react';
import { toast } from "react-toastify";
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import Loading from '../../Functions/Loading';
import Avatar from '@mui/material/Avatar';

class ImgCarta extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // Si han eliminado este juego, no necesitamos mostrarlo
            carta: [],
            loading: true
        };
    }

    async componentDidMount() {
        this.setState({
            carta: this.props.carta,
            loading: false,
        });
    }



    render() {
        var imgclass = "img_carta"

        if (this.props.size == "medium") {
            imgclass = "img_carta_medium"
        } else if (this.props.size == "small") {
            imgclass = "img_carta_small"
        }

        if (this.state.loading) {
            return <Loading />
        }
        var imgurl = this.state.carta.imgurl;
        if (imgurl !== null && imgurl !== "") {
            imgurl = imgurl.replace("./", "/");
        }
        return (
            <div className='containerblock' style={{ justifyContent: "center", display: "flex" }}>
                {/*<Avatar

                    alt="Remy Sharp"
                    src={imgurl}
                    sx={{ width: 275, height: 275 }}
                />*/}
                <img src={imgurl} className={imgclass} />
            </div>
        );
    }

}

export default ImgCarta;