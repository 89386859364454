import * as React from 'react';
import Loading from "../../Functions/Loading";
import Paper from '@mui/material/Paper';
import Constantes from '../../Constantes';
import { useState, useEffect } from "react";
import Chart, {
    Series,
    Aggregation,
    ArgumentAxis,
    Font,
    Title,
    CommonSeriesSettings, Label, Format, Legend, Export,
} from 'devextreme-react/chart';
import { ArgumentScale } from '@devexpress/dx-react-chart';
import { scaleBand } from '@devexpress/dx-chart-core';


//https://devexpress.github.io/devextreme-reactive/react/chart/docs/reference/argument-axis/
//https://js.devexpress.com/Demos/WidgetsGallery/Demo/Charts/SideBySideBar/React/Light/


const GHistorialCambios = (props) => {

    const [loading, setLoading] = useState(true);
    const [chartData, setchartData] = useState({ cambios: 0, month: 0, monthname: "none" });
    const data = [
        { monthname: '1950', cambios: 2.525 },
        { monthname: '1960', cambios: 3.018 },
        { monthname: '1970', cambios: 3.682 },
        { monthname: '1980', cambios: 4.440 },
        { monthname: '1990', cambios: 5.310 },
        { monthname: '2000', cambios: 6.127 },
        { monthname: '2010', cambios: 6.930 },
    ];
    const date = new Date();


    useEffect(() => {
        componentDidMount();
    }, []);


    const componentDidMount = async () => {

        const respuesta = await fetch(`${Constantes.RUTA_API}/obtener_numcambios_anuales.php?uid=${props.id_user}`);
        const datos = await respuesta.json();



        datos.forEach((el) => {
            el.arg = toMonthName(el.month)
            el.val = el.cambios
        })

        setchartData(datos)
        console.log(datos)
        setLoading(false);
    }

    function toMonthName(monthNumber) {

        date.setMonth(monthNumber - 1);

        return date.toLocaleString('es-ES', {
            month: 'long',
        });
    }

    // https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxChart/Configuration/argumentAxis/

    return (
        <div >
            {loading ? (
                <Loading />
            ) : (
                <div >
                    {(chartData.length > 1) ? (
                        <Paper className='block whitecolor'>
                            <Chart dataSource={chartData}>
                                <Title text={"Historial de cambios " + date.getFullYear()}>
                                    <Font color="#fff" />
                                </Title>
                                <CommonSeriesSettings
                                    argumentField="arg"
                                    type="bar"
                                >
                                    <Label visible={true}>
                                        <Format type="fixedPoint" precision={0} />
                                        <Font size={25} color="#fff" />
                                    </Label>
                                </CommonSeriesSettings>
                                <Series valueField="val"
                                    argumentField="arg"
                                    name={"Cambios"}
                                    type="bar"
                                    color="#ffaa66"
                                >                                </Series>
                                <Legend verticalAlignment="bottom" horizontalAlignment="center">
                                    <Font size={12} color="#fff" />
                                </Legend>
                                <Export enabled={true} />
                            </Chart>

                        </Paper>
                    ) : (<></>)}
                </div >
            )}
        </div>
    );
}

export default GHistorialCambios;