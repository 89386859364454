import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import CardNameTeam from '../Components/TableCells/CardNameTeam';
import { CheckCircle, Close, Email } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Constantes from '../Constantes';
import { toast } from 'react-toastify';
import { useState, useEffect } from "react";
import Loading from '../Functions/Loading';

import Skeleton from '@mui/material/Skeleton';

const RowAddedPedido = (props) => {

    const [carta, setCarta] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        componentDidMount();

    });

    const componentDidMount = async () => {
        var c = props.carta
        var imgurl = c.imgurl;
        if (imgurl !== null && imgurl !== "") {
            imgurl = imgurl.replace("./", "/");
        }

        var selected = false;
        if (c.oquantity == 0) {
            selected = true;
        }
        c.imgurl = imgurl
        c.selected = selected
        setCarta(c)

        setLoading(false)
    }

    const manejarCambioSelect = async (e) => {
        // 1-226-0 // Nuevo valor- id - viejo valor
        //console.log("valores: " + JSON.stringify(e))
        var valores = e.value.split("-")
        if (valores[0] !== valores[2]) {

            var item = carta


            item.tipo = props.tipo
            item.id_user = props.id_user
            item.ship = 1



            const cargaUtil = JSON.stringify(item);
            const rest = await fetch(`${Constantes.RUTA_API}/modify_quantity.php`, {
                method: "PUT",
                body: cargaUtil,
            });

            const respuesta = await fetch(`${Constantes.RUTA_API}/actualizar_ship.php?id=${item.id}&ship=1`);
            const resp = await respuesta.json();
            if (resp && rest) {
                toast(item.name + ' - Envio modificado  📦 ', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    //onClose: this.props.onBtNext,

                });
                setCarta(item)
                //this.state.itemscompraventa.find(item => item.id == valores[1]) = item;

                //this.props.onBtNext()
            }

        }

    }







    return (
        <>
            {loading ? (
                <Skeleton variant="rectangular" width={210} height={60} />
            ) : (
                <TableRow selected={carta.selected}><TableCell>
                    <Avatar
                        alt="Remy Sharp"
                        src={carta.imgurl}
                        sx={{ width: 50, height: 50 }}
                    />
                </TableCell>
                    <TableCell className="whitecolor">
                        {carta.num_carta}
                    </TableCell>
                    <TableCell>
                        <CardNameTeam firstline={carta.name} secondline={carta.team} id={carta.id_carta} />
                    </TableCell>
                    <TableCell>
                        <CardNameTeam firstline={carta.coleccion} secondline={carta.year} id={carta.id_coleccion} type="coleccion" />
                    </TableCell>
                    <TableCell className="whitecolor">
                        {carta.precio + "€"}
                    </TableCell>
                    <TableCell className="whitecolor">
                        {carta.cantidad}
                    </TableCell>

                    <TableCell>
                        {props.carta.ship ? <CheckCircle color="primary" /> : <IconButton variant="contained" color="secondary"

                            onClick={() => {
                                manejarCambioSelect({ value: "1-" + carta.id + "-" + carta.ship, label: "Si" });
                                setCarta(carta => ({ ...carta, ship: 1 }));
                            }}>
                            <Email />
                        </IconButton>}
                    </TableCell></TableRow>
            )}
        </>


    );
}

export default RowAddedPedido;