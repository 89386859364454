
import React from 'react';
import { Link } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import theme from "../../Theme/theme";

function CardNameTeam(props) {
    //<Chip theme={theme} label={props.id} />
    if ((props.id) > 0) {
        var tolink = "/carta/editar/?id=";
        if (props.type != undefined) {
            if (props.type == "coleccion") {
                tolink = "/cartascoleccion/"
            }
        }
        return (
            <div >
                <Link className='firstline' to={`${tolink}${props.id}`}>
                    {props.firstline + " "}
                </Link>
                <span className='secondline'>{props.secondline}</span>
            </div>
        );

    }

    return (
        <div >
            <span className='firstline' >{props.firstline}</span>
            <span className='secondline'>{props.secondline}</span>
        </div>
    );
}

export default CardNameTeam;