import { useParams } from "react-router-dom";
import EditarCambio from "../Cambios/EditarCambio";

export default function RouteCambios(props) {
    let params = useParams();
    if (params.idcambio == null) {
        params.idcambio = 0;
    }


    return <EditarCambio idcambio={params.idcambio} id_user={props.id_user} />
}