import * as React from 'react';
import Loading from "../Functions/Loading";
import { useState, useEffect } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import NotificacionesEbay from './NotificacionesEbay';
import HomeLinks from './HomeLinks';

// Acordion
import Typography from '@mui/material/Typography';


const Ajustes = (props) => {

    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState(0);

    useEffect(() => {
        componentDidMount();
    }, []);


    const componentDidMount = async () => {
        setLoading(false);
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const tabchange = (event, newValue) => {
        setValue(newValue);
    }

    return (
        <div className='container'>
            {loading ? (
                <Loading />
            ) : (
                <div className='block'>
                    <Tabs value={value} onChange={tabchange} aria-label="basic tabs example">
                        <Tab className='whitecolor' label="Notificaciones Ebay" {...a11yProps(0)} />
                        <Tab className='whitecolor' label="HomeLinks" {...a11yProps(1)} />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <NotificacionesEbay id_user={props.id_user} />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <HomeLinks id_user={props.id_user} />
                    </TabPanel>
                </div>
            )}
        </div>
    );
}

export default Ajustes;