import React from 'react';
import Constantes from "../Constantes";
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useState, useEffect } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { ToastContainer, toast } from 'react-toastify';

import ApiFile from '../Functions/ApiFile';

const EditarColeccion = (props) => {
    const [coleccion, setColeccion] = useState([]);
    const [title, setTitle] = useState("Editar colección");
    const [selectedFile, setFile] = useState([]);
    useEffect(() => {
        setColeccion(props.coleccion)
        if (props.coleccion.id == 0) {
            setTitle("Añadir nueva colección")
        }
        //console.log(props.coleccion)
    }, []);


    const manejarEnvioDeFormulario = async (evento) => {

        let col = coleccion;
        if (selectedFile != "") {

            var re = /(?:\.([^.]+))?$/;
            var ext = re.exec(selectedFile.name)[1];
            const res = await ApiFile({
                url: "/files/carta/upload", file: selectedFile, name: `imgcol_${coleccion.name.replace(/ /g, '')}.${ext}`
            });

            if (res.status === "uploaded") {
                col.icon = Constantes.RUTA_IMG_CARTAS + "imgcol_" + coleccion.name.replace(/ /g, '') + "." + ext;
                toast('Imagen (' + col.icon + ') subida correctamente 🖼️', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,

                });
                setColeccion(col)
            } else {
                toast.error("Error al subir el icono");
            }
        }
        evento.preventDefault();
        // Crear o Editar colección
        const cargaUtil = JSON.stringify(col);
        // console.log("Carta: " + JSON.stringify(this.state.carta))
        let respuesta = "";

        // ¡Y enviarlo!
        respuesta = await fetch(`${Constantes.RUTA_API}/update_coleccion.php`, {
            method: "PUT",
            body: cargaUtil,
        });

        const exitoso = await respuesta.json();
        if (exitoso) {

            //console.log(col);
            let resp = JSON.parse(exitoso)
            col.id = resp.id;
            setColeccion(col);
            //console.log(coleccion);
            //console.log(resp.id)
            toast(resp.text, {
                position: "top-right",
                autoClose: 15000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,

            });

        } else {
            toast.error("Error guardando. Intenta de nuevo");
        }
    }

    const manejarCambio = (evento) => {
        // Extraer la clave del estado que se va a actualizar, así como el valor

        const clave = evento.target.id;
        let valor = evento.target.value;

        const coleccionActualizada = coleccion;
        // Si es la calificación o el nombre, necesitamos castearlo a entero
        if (clave == "tipo") {
            valor = parseInt(valor);
        }
        // Actualizamos el valor del videojuego, solo en el campo que se haya cambiado
        coleccionActualizada[clave] = valor;
        setColeccion(coleccionActualizada)

    }

    const onFileChange = (event) => {
        setFile(event.target.files[0])

    }

    const fileData = () => {
        if (selectedFile != "") {

            return (
                <div>
                    <h2>File Details:</h2>
                    <p>File Name: {selectedFile.name}</p>
                    <p>File Type: {selectedFile.type}</p>
                </div>
            );
        } else {
            return (
                <></>
            );
        }
    };


    return (
        <Grid item xs={12} md={12}>
            <div elevation={6} >
                <ToastContainer></ToastContainer>
                <Stack direction="row" className='containerheader' spacing={1}>
                    <h4 >{title}</h4>
                </Stack>
                <div className="containercoleccion">

                    <form >
                        <Grid container spacing={{ xs: 1, md: 4 }}>

                            <Grid item xs={12} md={3}>
                                <label className="form-label" htmlFor="titulo">Nombre:</label>
                                <input required placeholder="Colección 1" type="text" id="name" onChange={manejarCambio} defaultValue={coleccion.name} className="form-control form-control-lg rainbowborder" />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <label className="form-label" htmlFor="titulo">Icono:</label>
                                <input placeholder="Colección 1" type="text" id="icon" onChange={manejarCambio} defaultValue={coleccion.icon} className="form-control form-control-lg rainbowborder" />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="titulo">Subir Icono:</label>
                                    <input type="file" onChange={onFileChange} />
                                </div>
                                <div className="mb-3">
                                    {fileData()}
                                </div>
                            </Grid>

                            <Grid item xs={12} md={2}></Grid>

                            <Grid item xs={12} md={4}>
                                <Button variant="contained" color="primary" onClick={manejarEnvioDeFormulario}>
                                    Guardar
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}></Grid>

                    </form>
                </div>
            </div>
        </Grid>
    )
}

export default EditarColeccion;