import React from 'react';
import Constantes from "../Constantes";
import { ToastContainer, toast } from 'react-toastify';
import { Link, Navigate } from 'react-router-dom';
import Select from 'react-select';
import Chip from '@mui/material/Chip';
import { Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import theme from "../Theme/theme";
import ContainerHeader from '../Functions/ContainerHeader';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import ApiFetch from '../Functions/ApiFetch';

class AddItemCompraventa extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            colecciones: [],
            idcompraventa: "",
            cartas: [],
            seleccionada: "",
            imgseleccionada: "",
            itemcompraventa: {
                id: 0,
                "id_coleccion": 0,
                "id_carta": 0,
                "cantidad": 0,
                "precio": "0",
                "ship": 0,
                "fechaship": "-",
                "idcompraventa": 0,
                "id_user": 0,
                "tipo": 0,
            },
        };
        // Indicarle a las funciones a quién nos referimos con "this"
        this.manejarCambio = this.manejarCambio.bind(this);
        this.manejarCambioSelect = this.manejarCambioSelect.bind(this);
        this.manejarCambioSelect2 = this.manejarCambioSelect2.bind(this);
        this.manejarCambioSelect3 = this.manejarCambioSelect3.bind(this);
        this.manejarEnvioDeFormulario = this.manejarEnvioDeFormulario.bind(this);


    }




    async componentDidMount() {
        // Obtener ID de URL
        //const idVideojuego = this.props.match.params.id;
        this.state.idcompraventa = this.props.idcompraventa
        this.state.itemcompraventa.id_user = this.props.id_user

        const respuestacv = await fetch(`${Constantes.RUTA_API}/obtener_compraventa.php?id=${this.state.idcompraventa}`);
        const compraventa = await respuestacv.json();
        this.state.itemcompraventa.tipo = compraventa.tipo

        const respuesta = await fetch(`${Constantes.RUTA_API}/obtener_colecciones.php`);
        const colecciones = await respuesta.json();
        // "refrescar" el formulario

        this.setState({
            colecciones: colecciones,
        });

    }

    async manejarCambioSelect(e) {
        // e.value o e.label
        const requestOptions = {
            method: 'GET',
        };
        const ncartas = await ApiFetch({ requestOptions: requestOptions, url: "/cartas?idcoleccion=" + e.value });
        const cartas = ncartas.data;
        this.state.itemcompraventa.id_coleccion = e.value
        this.setState({
            itemcompraventa: this.state.itemcompraventa,
            cartas: cartas,
        });

    }

    manejarCambioSelect3(e) {

        this.state.itemcompraventa.ship = e.value
        this.setState({
            itemcompraventa: this.state.itemcompraventa
        });

    }
    manejarCambioSelect2(e) {

        const resultado = this.state.cartas.find(carta => carta.id === e.value);
        this.state.itemcompraventa.id_carta = resultado.id
        this.setState({
            itemcompraventa: this.state.itemcompraventa,
            imgseleccionada: resultado.imgurl,
            seleccionada: resultado.id,
        });

    }



    render() {
        let options = this.state.colecciones.map(coleccion => {
            return { value: coleccion.id, label: coleccion.name }
        })

        let options2 = this.state.cartas.map(carta => {
            return { value: carta.id, label: "(" + carta.num_carta + ") " + carta.name }
        })


        return (
            <div>
                <form className="form" onSubmit={this.manejarEnvioDeFormulario}>
                    <Grid container spacing={{ xs: 1, md: 2 }}>
                        <Grid item xs={12} md={12}>
                            <label className="form-label" htmlFor="precio">Selecciona colección:</label>
                            <Select
                                id="id_coleccion"
                                options={options}
                                onChange={this.manejarCambioSelect}
                            />
                            <label className="form-label" htmlFor="precio">Selecciona carta:</label>
                            <Select

                                id="id_carta"
                                options={options2}
                                onChange={this.manejarCambioSelect2}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <label className="form-label" htmlFor="cantidad">Cantidad:</label>
                            <input required placeholder="0" type="number" id="cantidad" onChange={this.manejarCambio} className="form-control" />
                            <label className="form-label" htmlFor="precio">Enviado / Recibido:</label>
                            <Select

                                id="ship"
                                onChange={this.manejarCambioSelect3}
                                options={[{ value: 1, label: "Si" }, { value: 0, label: "No" }]}
                            />
                            <Stack className="form-label" direction="row" spacing={1}>
                                <Button variant="contained" color="primary" type="submit" onClick={this.manejarEnvioDeFormulario}>
                                    Añadir
                                </Button>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <label className="form-label" htmlFor="precio">Precio unidad:</label>
                            <input required placeholder="0" type="text" id="precio" onChange={this.manejarCambio} className="form-control" />
                            <label className="form-label" htmlFor="fechaship">Fecha env/rec:</label>
                            <input placeholder="26/11/1994" type="text" id="fechaship" onChange={this.manejarCambio} className="form-control" />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <label className="form-label" htmlFor="precio"> Carta seleccionada:</label>
                            <a target={"_blank"} href={`/carta/editar/?id=${this.state.seleccionada}`}><img className="imgCartaMedium" src={this.state.imgseleccionada.replace("./", "/")} /></a>
                        </Grid>
                    </Grid>

                </form>
            </div >
        );
    }
    async manejarEnvioDeFormulario(evento) {

        evento.preventDefault();

        var error = false
        if (this.state.itemcompraventa.id_carta == 0 || this.state.itemcompraventa.id_coleccion == 0) {
            error = true
            toast('❌ Por favor selecciona una carta ❌', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,

            });
            return ""
        }

        // Codificar nuestro videojuego como JSON
        this.state.itemcompraventa.idcompraventa = this.state.idcompraventa;
        this.state.itemcompraventa.precio = this.state.itemcompraventa.precio.toString().replace(",", ".")
        //(this.state.itemcompraventa.precio)
        const cargaUtil = JSON.stringify(this.state.itemcompraventa);
        let respuesta = "";

        if (parseInt(this.state.itemcompraventa.id) == 0 && !error) {
            // ¡Y enviarlo!
            respuesta = await fetch(`${Constantes.RUTA_API}/crear_itemcompraventa.php`, {
                method: "PUT",
                body: cargaUtil,
            });
        } else if (!error) {
            // ¡Y enviarlo!
            respuesta = await fetch(`${Constantes.RUTA_API}/actualizar_compraventa.php`, {
                method: "PUT",
                body: cargaUtil,
            });
        }
        const exitoso = await respuesta.json();
        if (exitoso) {
            toast('Carta (' + this.state.itemcompraventa.id_carta + ') añadida 💸', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,

            });
            this.props.onBtNext(this.state.idcompraventa)

        } else {
            toast.error("Error guardando. Intenta de nuevo");
        }
    }



    manejarCambio(evento) {
        // Extraer la clave del estado que se va a actualizar, así como el valor

        const clave = evento.target.id;
        let valor = evento.target.value;
        this.setState(state => {
            const compraventaActualizada = state.itemcompraventa;
            // Si es la calificación o el nombre, necesitamos castearlo a entero
            if (clave == "cantidad") {
                valor = parseInt(valor);
            }
            // Actualizamos el valor del videojuego, solo en el campo que se haya cambiado
            compraventaActualizada[clave] = valor;
            return {
                itemcompraventa: compraventaActualizada,
            }
        });
    }

}

export default AddItemCompraventa;