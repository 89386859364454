import { createTheme } from '@mui/material/styles';
// Iconos: https://mui.com/material-ui/icons/


const theme = createTheme({
    status: {
        danger: '#e53e3e',
    },
    palette: {
        primary: {
            main: '#0971f1',
            darker: '#053e85',
            contrastText: '#fff',
            mainGradient: "linear-gradient(to right, tomato, cyan)"
        },
        secondary: {
            main: '#ffffff',
            darker: '#ffffff',
            contrastText: '#fff',
            borderColor: '#ffffff',
        },
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
        red: {
            main: '#FF0404',
            contrastText: '#fff',
            darker: '#490010',
        },
        green: {
            main: '#006619',
            contrastText: '#fff',
        },
        dark: {
        },
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                    borderColor: '#ffffff',
                },
                input: {
                    color: '#ffffff',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                    borderColor: '#ffffff',
                },
                input: {
                    color: '#ffffff',
                },
                outline: {
                    color: '#ffffff',
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                    borderColor: '#ffffff',
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    color: '#ffffff',
                    borderColor: '#ffffff',
                },
                input: {
                    color: '#ffffff',
                }
            },
        }
    },
    overrides: {
        MuiPaginationItem: {
            root: {
                color: '#ffffff',
                border: '1px solid #ffffff',
                '&:hover': {
                    backgroundColor: '#3f51b5',
                    color: '#ffffff',
                    border: '1px solid #3f51b5',
                },
                '&$Mui-selected': {
                    backgroundColor: '#3f51b5',
                    color: '#fff',
                    border: '1px solid #3f51b5',
                    '&:hover': {
                        backgroundColor: '#3f51b5',
                        color: '#ffffff',
                        border: '1px solid #3f51b5',
                    },
                },
            },
            // Nueva regla de estilo para los elementos no seleccionados
            outlined: {
                backgroundColor: '#fff',
                color: '#ffffff',
            },
        },
        MuiOutlinedInput: {
            root: {
                color: '#ffffff',
            },
            input: {
                color: '#ffffff',
            }
        }
    },
});
export default theme;