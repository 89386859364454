import axios from 'axios'

var Session = (function () {
    var dip = ""
    var username = "";
    var userid = "";

    var getName = function () {
        return username;    // Or pull this from cookie/localStorage
    };

    var setName = function (name) {
        username = name;
        // Also set this in cookie/localStorage
    };

    var getUserId = function () {
        return userid;    // Or pull this from cookie/localStorage
    };

    var setUserId = function (id) {
        userid = id;
        // Also set this in cookie/localStorage
    };

    var setIp = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        //console.log(res.data);
        dip = res.data.IPv4
        //console.log(dip)
    }

    var getIp = function () {
        //console.log("Devolviendo IP: " + dip)
        return dip;
    }

    function waitForElement() {
        if (dip !== "") {
            //variable exists, do what you want
            console.log("NO Waiting")
            return dip
        }
        else {
            console.log("Waiting")
            setTimeout(waitForElement, 250);
        }
    }

    return {
        getName: getName,
        setName: setName,
        getUserId: getUserId,
        setUserId: setUserId,
        getIp: getIp,
        setIp: setIp,
        waitForElement: waitForElement
    }

})();

export default Session;