import * as React from 'react';
import Loading from "../../Functions/Loading";
import Paper from '@mui/material/Paper';
import Constantes from '../../Constantes';
import { useState, useEffect } from "react";
import { ArgumentScale } from '@devexpress/dx-react-chart';
import { scaleBand } from '@devexpress/dx-chart-core';
import PieChart, {
    Series,
    Label,
    Connector,
    Size,
    Export,
    CommonSeriesSettings,
    Format,
    Legend,
    Font,
    Title,
    SmallValuesGrouping,
} from 'devextreme-react/pie-chart';


//https://devexpress.github.io/devextreme-reactive/react/chart/docs/reference/argument-axis/
//https://js.devexpress.com/Demos/WidgetsGallery/Demo/Charts/SideBySideBar/React/Light/


const GCartasColecciones = (props) => {

    const [loading, setLoading] = useState(true);
    const [chartData, setchartData] = useState({ cambios: 0, month: 0, monthname: "none" });



    useEffect(() => {
        componentDidMount();
    }, []);


    const componentDidMount = async () => {


        props.colecciones.forEach((el) => {
            if (el.quantity === null) {
                el.quantity = 0
            } else {
                el.quantity = parseInt(el.quantity)
            }
            //console.log(el)
        })

        //console.log((props.colecciones.reduce((a, v) => a = a + parseInt(v.quantity), 0)))

        let col = props.colecciones.filter(function (e) {
            return e.quantity > 0;
        });

        col.sort(function (a, b) {
            return b.quantity - a.quantity;
        });
        setchartData(col)
        setLoading(false);
    }


    // https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxChart/Configuration/argumentAxis/

    return (
        <div >
            {loading ? (
                <Loading />
            ) : (
                <div className='block'>
                    {(chartData.length > 1) ? (
                        <Paper className='block whitecolor'>
                            <PieChart
                                dataSource={chartData}
                                sizeGroup="piesGroup">
                                <Title text={"Cartas (" + (props.colecciones.reduce((a, v) => a = a + parseInt(v.quantity), 0)) + ")"}>
                                    <Font color="#fff" />
                                </Title>
                                <CommonSeriesSettings
                                    argumentField="name"
                                    type="pie"
                                >
                                    <Label visible={true}>
                                        <Format type="fixedPoint" precision={0} />
                                        <Font size={16} />
                                    </Label>
                                </CommonSeriesSettings>
                                <Series valueField="quantity"
                                    argumentField="name"
                                    name={"Colecciones"}
                                >

                                    <SmallValuesGrouping threshold={chartData[4].quantity} mode="smallValueThreshold" />
                                </Series>
                                <Legend
                                    verticalAlignment="bottom"
                                    horizontalAlignment="center"
                                    itemTextPosition="right"
                                    rowCount={3} >
                                    <Font size={16} color="#fff" />
                                </Legend>
                                <Export enabled={true} />
                            </PieChart>

                        </Paper>
                    ) : (<></>)}
                </div >
            )}
        </div>
    );
}

export default GCartasColecciones;