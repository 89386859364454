import React from "react";
import Constantes from "../Constantes";

import CardItem from "./Cartas/CardItem";
import { useState, useEffect, useCallback } from "react";
import ApiFetch from "../Functions/ApiFetch";
import { Buffer } from "buffer";
import axios from "axios";
import ApiEbay from "../Functions/ApiEbay";




const Test = (props) => {
    const [cartas, setCartas] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");



    useEffect(() => {
        searchCartas("Batman");
    }, []);

    const searchCartas = async (search) => {






        // https://abeflansburg.medium.com/ebay-rest-api-oauth2-plain-english-edition-1d102a9f719c
        // https://auth.ebay.com/oauth2/authorize?client_id=Guillerm-CardMana-PRD-667414f10-e6d70821&response_type=code&redirect_uri=Guillermo_Navar-Guillerm-CardMa-yyhpbo&scope=https://api.ebay.com/oauth/api_scope%20https://api.ebay.com/oauth/api_scope/sell.marketing.readonly%20https://api.ebay.com/oauth/api_scope/sell.marketing

        // Cat: 261329 - Trading card LOTS //// cat: 212 - Trading card singles
        //const ebaydata = await ApiEbay({ q: "spain auto", limit: "5", category_ids: "212", filter: "buyingOptions:{AUCTION|BEST_OFFER},price:[1..85],priceCurrency:USD", sort: "endingSoonest" })
        const ebaydata = await ApiEbay({ q: "panini donruss 2016 messi psa 10", limit: "15", category_ids: "212", filter: "buyingOptions:{AUCTION|BEST_OFFER},price:[1..85],priceCurrency:USD", sort: "endingSoonest" })

        const ebaydata2 = await ApiEbay({ q: "aguero city", limit: "15", category_ids: "261329", filter: "buyingOptions:{AUCTION|BEST_OFFER},price:[1..10],priceCurrency:USD", sort: "endingSoonest" })

        setCartas(ebaydata2)

        try {
            /*const requestOptions = {
                method: 'POST',
                body: JSON.stringify({
                    iduser: props.id_user
                })
            };*/
            // https://stackoverflow.com/questions/58244736/how-to-get-oauth-token-from-ebay-api-using-express-node-javascript

            //console.log(openPopup(authurl))
            // Hace la llamada a la API de eBay utilizando fetch
            const headers = {
                "Authorization": "Bearer ",
                'Content-Type': 'application/json',
                'X-EBAY-C-MARKETPLACE-ID': '0',
            };
            const params = {
                q: 'messi lost rookie',
                limit: 10,
                offset: 0,
            };
            var requestOptions = {
                method: 'GET',
                headers: headers,
                redirect: 'follow'
            };





            /* fetch("https://api.ebay.com/buy/browse/v1/item_summary/search?q=barcelona auto&limit=3", requestOptions)
                 .then(response => response.text())
                 .then(result => console.log(result))
                 .catch(error => console.log('error', error));*/




        } catch (err) {
            //console.log("Backend error")
            console.error(`Backend error `, err.message);
        }

        /*const requestOptions = {
            method: 'GET',
            headers: {
                "authorization": 'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoicHJ1ZWJhYXBpIiwiaWF0IjoxNjczNzg1MDQ3LCJleHAiOjE2NzM3ODg5NDd9.VrY8vZ0UfiFpsVAbPQ6kevxMuki8xrJQwBCRdYt7_Hc',
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };


        await fetch(`http://localhost:3001/cartas`, requestOptions)
            .then(response => {
                response.json()
                if (response.status === 403) {
                    console.log("Code " + response.status)
                }
            })
            .then(response => {
                console.log(response);
            })
            .catch(err => {
                console.error(err)
            });// */
    };

    const openPopup2 = (url) => {
        const POPUP_HEIGHT = 700;
        const POPUP_WIDTH = 600;
        // To fix issues with window.screen in multi-monitor setups, the easier option is to
        // center the pop-up over the parent window.
        const top = window.outerHeight / 2 + window.screenY - POPUP_HEIGHT / 2;
        const left = window.outerWidth / 2 + window.screenX - POPUP_WIDTH / 2;
        return window.open(
            url,
            'OAuth2 Popup',
            `height=${POPUP_HEIGHT},width=${POPUP_WIDTH},top=${top},left=${left}`
        );
    };

    const getEbayToken = async (authcode) => {
        const requestOptions2 = {
            method: 'GET'
        };
        const ncartas = await ApiFetch({ requestOptions: requestOptions2, url: "/ebay/callback?code=" + authcode });

        const exitoso = ncartas.data;
        console.log(exitoso)
    }

    const openPopup = async () => {
        const authurl = "https://auth.ebay.com/oauth2/authorize?client_id=Guillerm-CardMana-PRD-667414f10-e6d70821&response_type=code&redirect_uri=Guillermo_Navar-Guillerm-CardMa-yyhpbo&scope=https://api.ebay.com/oauth/api_scope%20https://api.ebay.com/oauth/api_scope/sell.marketing.readonly%20https://api.ebay.com/oauth/api_scope/sell.marketing%20https://api.ebay.com/oauth/api_scope/sell.inventory.readonly%20https://api.ebay.com/oauth/api_scope/sell.inventory%20https://api.ebay.com/oauth/api_scope/sell.account.readonly%20https://api.ebay.com/oauth/api_scope/sell.account%20https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly%20https://api.ebay.com/oauth/api_scope/sell.fulfillment%20https://api.ebay.com/oauth/api_scope/sell.analytics.readonly%20https://api.ebay.com/oauth/api_scope/sell.finances%20https://api.ebay.com/oauth/api_scope/sell.payment.dispute%20https://api.ebay.com/oauth/api_scope/commerce.identity.readonly%20https://api.ebay.com/oauth/api_scope/commerce.notification.subscription%20https://api.ebay.com/oauth/api_scope/commerce.notification.subscription.readonly"

        const POPUP_HEIGHT = 700;
        const POPUP_WIDTH = 600;
        // To fix issues with window.screen in multi-monitor setups, the easier option is to
        // center the pop-up over the parent window.
        const top = window.outerHeight / 2 + window.screenY - POPUP_HEIGHT / 2;
        const left = window.outerWidth / 2 + window.screenX - POPUP_WIDTH / 2;


        const popup = await window.open(authurl, 'OAuth2 Popup',
            `height=${POPUP_HEIGHT},width=${POPUP_WIDTH},top=${top},left=${left}`);
        const checkPopupUrl = await setInterval(() => {

            if (popup.closed) {
                clearInterval(checkPopupUrl);
                return ""
            } else if (popup.location.href !== 'about:blank') {
                //console.log(`La URL actual del pop-up es: ${popup.location.href}`);
                console.log(popup.location.href)
                const params = popup.location.href.toString().split("code=")
                const c = params[1].split("&")[0]
                console.log(c)
                //const code = params.get('code');
                //const { code, expires_in } = Object.fromEntries(new URLSearchParams(popup.location.href));
                //console.log("Code: " + code + " - " + expires_in)
                popup.close();
                const token = getEbayToken(c);
                return token;
            }
        }, 1000);
    }

    const closePopup = (popupRef) => {
        popupRef.current?.close();
    };

    const onSuccess = response => console.log(response);
    const onFailure = response => console.error(response);
    return (
        <div className="block carditemcontainer">


            Pruebas
            {
                cartas.map((carta) => (
                    <div key={carta.itemId} className="product-card">
                        <div className="product-image-container">
                            <img className="product-image" src={carta.image.imageUrl} alt={carta.title} />
                        </div>
                        <h3 className="product-title">{carta.title}</h3>
                        <p className="product-price">{
                            carta.buyingOptions[0] === 'AUCTION' ? (
                                carta.currentBidPrice.value + "$ ( " + carta.bidCount + " pujas)"
                            ) : (
                                carta.price.value + "$"
                            )
                        }</p>
                        <p className="product-endate">{carta.itemEndDate}</p>
                        <a className="product-link" href={carta.itemWebUrl} target="_blank" rel="noopener noreferrer">Ver en eBay</a>
                    </div>
                ))
            }
            Fin pruebas
        </div>
    )
}

export default Test;