import React from "react";
import { Stack } from '@mui/material';
import Chip from '@mui/material/Chip';
import theme from "../Theme/theme";

const ContainerHeader = (props) => {

    return (
        <Stack direction="row" spacing={1} className='containerheader'>
            {props.chip ? (
                <Chip color="warning" variant="outlined" label={props.chip} />
            ) : (<></>)}
            <span className="containertitle">{props.title}</span>
        </Stack>
    )
}

export default ContainerHeader;