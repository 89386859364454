import React from 'react'
import { Menu as MenuIcon, SportsSoccer, CollectionsBookmark, Store, LocalShipping, FileCopySharp, AddToPhotos } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton';
import { Paper, Drawer, Divider } from '@mui/material';
import { NavLink } from "react-router-dom";
import theme from '../Theme/theme';
import Colecciones from '../Colecciones/Colecciones';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import { MenuItem } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { Link } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import Constantes from '../Constantes';

// Iconos: https://mui.com/material-ui/material-icons/

const MainMenu = ({ open, setOpen }) => {

    return (
        <Drawer className='menupaper' anchor='left' open={open} onClose={() => setOpen(false)}>
            <Paper className='menupaperinner' >
                <div className='menudiv'>
                    <IconButton edge='start' color='inherit' onClick={() => setOpen(false)}>
                        <MenuIcon />
                    </IconButton>
                    Menu
                </div>
                <div className='menutitle'>Principal</div>
                <Divider />

                <MenuItem containerElement={<Link to="/" />} onClick={() => setOpen(false)}>

                    <HomeIcon theme={theme} color='primary' />
                    <NavLink className="nav-link" to="/" >
                        Inicio
                    </NavLink>
                </MenuItem>
                <MenuItem containerElement={<Link to="/cartascoleccion" />} onClick={() => setOpen(false)}>
                    <FileCopySharp theme={theme} color='primary' />
                    <NavLink className="nav-link" to="/cartascoleccion" >
                        Ver cartas
                    </NavLink>
                </MenuItem>
                <MenuItem onClick={() => setOpen(false)}>
                    <ViewComfyIcon theme={theme} color='primary' />
                    <NavLink className="nav-link" to="/cartas/cuadricula" >
                        Ver cartas
                    </NavLink>
                </MenuItem>
                <MenuItem containerElement={<Link to="/buscarebay" />} onClick={() => setOpen(false)}>
                    <ListAltIcon theme={theme} color='primary' />
                    <NavLink className="nav-link" to="/buscarebay">
                        Buscar Ebay
                    </NavLink>
                </MenuItem>
                <div className='menutitle'>Cambios</div>
                <Divider />
                <MenuItem onClick={() => setOpen(false)}>
                    <PublishedWithChangesIcon theme={theme} color='primary' />
                    <NavLink className="nav-link" to="/cambios">
                        Ver Cambios
                    </NavLink>
                </MenuItem>
                <MenuItem onClick={() => setOpen(false)}>
                    <AddCircleIcon theme={theme} color='success' />
                    <NavLink className="nav-link" to="/cambio/0">
                        Añadir Cambio
                    </NavLink>
                </MenuItem>

                <div className='menutitle'>Pedidos</div>
                <Divider />
                <MenuItem onClick={() => setOpen(false)}>
                    <Store theme={theme} color='primary' />
                    <NavLink className="nav-link" to="/compraventas">
                        Pedidos
                    </NavLink>
                </MenuItem>
                <MenuItem onClick={() => setOpen(false)}>
                    <PendingActionsIcon theme={theme} color='error' />
                    <NavLink className="nav-link" to="/pendientes">
                        Envios Pendientes
                    </NavLink>
                </MenuItem>
                <MenuItem onClick={() => setOpen(false)}>
                    <AddShoppingCartIcon theme={theme} color='success' />
                    <NavLink className="nav-link" to="/pedido/0">
                        Añadir pedido
                    </NavLink>
                </MenuItem>
                <div className='menutitle'>Configuración</div>
                <Divider />
                <MenuItem onClick={() => setOpen(false)}>
                    <CollectionsBookmarkIcon theme={theme} color='primary' />
                    <NavLink className="nav-link" to="/colecciones">
                        Ver Colecciones
                    </NavLink>
                </MenuItem>
                <MenuItem onClick={() => setOpen(false)}>
                    <AddCircleIcon theme={theme} color='success' />
                    <NavLink className="nav-link" to="/crearcarta">
                        Añadir Carta
                    </NavLink>
                </MenuItem>
                <MenuItem onClick={() => setOpen(false)}>
                    <SettingsIcon theme={theme} color='primary' />
                    <NavLink className="nav-link" to="/ajustes">
                        Ajustes
                    </NavLink>
                </MenuItem>
                <MenuItem className='MenuFooter'>
                    CM Version: {Constantes.VERSION}

                </MenuItem>
            </Paper>
        </Drawer>
    )
}

export default MainMenu
