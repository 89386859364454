import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import LogoutIcon from '@mui/icons-material/Logout';
import Chip from '@mui/material/Chip';
import theme from '../Theme/theme';
import Constantes from "../Constantes";
import { useState, useEffect } from "react";
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

function logoff() {
    localStorage.setItem('auth', '"no"')
    localStorage.setItem('uid', 'null')
    window.location.reload(false);
}

export default function SearchAppBar({ open, setOpen }) {

    const [search, setSearch] = useState("");

    const navigate = useNavigate();
    const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);
    let test = false;

    if (Constantes.RUTA_API === "https://testapi.gunaphotos.com/php") {
        test = true;
    }

    const handleKeyPress = async (event) => {
        if (event.key === 'Enter') {
            navigate('/buscar/' + search);
            setSearch("")
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" style={{ color: "#fff" }} sx={{ backgroundImage: 'linear-gradient(90deg, rgba(0,0,20,0.9919782913165266) 0%, rgba(0,0,55,0.9975805322128851) 100%)' }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ mr: 2 }}
                        onClick={() => setOpen(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    >
                        CARD MANAGEMENT
                        {test ? (<><Chip label="Test" variant="outlined" theme={theme} color="red" style={{ marginLeft: "10px" }} /></>) : (<></>)}
                    </Typography>

                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search…"
                            value={search}
                            onChange={(event) => {
                                setSearch((search) => event.target.value);
                            }}
                            onKeyPress={handleKeyPress}
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Search>
                    <IconButton
                        size="large"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ mr: 0 }}
                        onClick={() => logoff()}
                    >
                        <LogoutIcon />
                    </IconButton>

                </Toolbar>
            </AppBar>
            <Offset />
        </Box>
    );
}