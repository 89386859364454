import React from 'react';
import Constantes from "../Constantes";
import { useState, useEffect } from "react";

import Loading from "../Functions/Loading";
import Grid from '@mui/material/Grid';

import Colecciones from '../Colecciones/Colecciones';
import ObtenerCartas from '../Components/Cartas/ObtenerCartas';
import InfoBox from './InfoBox';
import CalculadoraEbay from '../Components/Ebay/CalculadoraEbay';
import LinkCard from './LinkCard';
import GraficoCambios from '../Components/Graficos/GHistorialPedidos';
import GCartasColecciones from '../Components/Graficos/GCartasColecciones';

const Home = (props) => {
    const [colecciones, setColecciones] = useState([]);
    const [loading, setLoading] = useState(true);
    const [info, setInfo] = useState(null)
    const [links, setLinks] = useState(null)

    // Llama una vez al iniciar
    useEffect(() => {
        componentDidMount();
    }, []);

    const componentDidMount = async () => {
        const respuesta = await fetch(`${Constantes.RUTA_API}/obtener_colecciones.php?uid=${props.id_user}`);
        const colecciones = await respuesta.json();

        const respuesta2 = await fetch(`${Constantes.RUTA_API}/obtener_homeinfo.php?id=${props.id_user}`);
        const homeinfo = await respuesta2.json();

        const respuesta3 = await fetch(`${Constantes.RUTA_API}/obtener_links.php?filter=1`);
        const links = await respuesta3.json();

        setLinks(links);
        setInfo(homeinfo);
        setColecciones(colecciones)
        setLoading(false)
    }

    return (

        <div className="container">
            {loading ? (
                <Loading />
            ) : (
                <>
                    <Grid container spacing={{ xs: 2, md: 2 }} className='' >
                        <section className="home_page-contain">

                            <InfoBox value={info.cartastotales} text={"Cartas totales"} color={'#74d5f4'} small={true} link={'/cartascoleccion'} />
                            <InfoBox value={info.coleccionestotales} text={"Colecciones"} color={'#f4d974'} small={true} link={'/colecciones'} />
                            <InfoBox value={info.cambios} text={"Cambios totales"} color={'#a4f474'} small={true} link={'/cambios'} />
                            <InfoBox value={info.cambiossinenviar} value2={info.cambiossinrecibir} text={"Cambios Pendientes"} color={'#df5858'} small={true} link={'/cambios'} />
                            <InfoBox value={info.pedidos} text={"Pedidos totales"} color={'#a4f474'} small={true} link={'/compraventas'} />
                            <InfoBox value={info.ventasinrecibir} value2={info.comprasinenviar} text={"Pedidos Pendientes"} color={'#df5858'} small={true} link={'/compraventas'} />
                        </section>

                    </Grid>

                    <Grid container spacing={{ xs: 2, md: 2 }}>
                        <Grid item xs={12} md={9}>
                            <ObtenerCartas id_user={props.id_user} />
                            <Colecciones perpage={6} id_user={props.id_user} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <GCartasColecciones colecciones={colecciones} />
                            <CalculadoraEbay />
                            {links.map(item => {
                                return (
                                    <LinkCard link={item} />
                                )
                            })}

                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    );
}

export default Home;