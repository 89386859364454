import React from "react";
import Loading from "../Functions/Loading";
import { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';

const LinkCard = (props) => {
    const [linkInfo, setLinkInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // Llama una vez al iniciar
    useEffect(() => {
        componentDidMount();
    }, []);

    const componentDidMount = async () => {

        setLinkInfo(props.link)

        setIsLoading(false)
    }

    return (
        < >
            <a href={linkInfo.url} className={"home_link-card"} target="_blank">
                {isLoading ? (
                    <><Loading></Loading></>
                ) : (<>
                    <Grid container className='block' spacing={{ xs: 1, md: 2 }}>
                        <Grid item xs={3} md={3} >
                            <Avatar alt="Remy Sharp" src={linkInfo.icon} sx={{ width: 55, height: 55 }} />
                        </Grid>
                        <Grid item xs={7} md={7} >
                            <div >
                                <span className='firstline' style={{ fontSize: "18px" }} >{linkInfo.title}</span>
                                <span className='secondline'>{linkInfo.comment}</span>
                            </div>
                        </Grid>
                        <Grid item xs={2} md={2} >
                            <ArrowForwardIcon sx={{ height: 45, width: 35 }} />
                        </Grid>

                    </Grid>
                </>)
                }
            </a>
        </>
    )
}

export default LinkCard;