import React from "react";
import { Link } from 'react-router-dom';
import CardNameTeam from "../TableCells/CardNameTeam";
import { Avatar, Badge } from "@mui/material";
import Chip from '@mui/material/Chip';
import { useState, useEffect } from "react";


const CardItem = (props) => {
    const carta = props.carta
    const [isHovering, setIsHovering] = useState(false);
    var tengo = false

    if (carta.quantity > 0) {
        tengo = true;
    }
    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    if (carta.coleccion != null && carta.coleccion.length > 25) {
        carta.coleccion = carta.coleccion.substring(0, 24) + ".."
    }
    if (carta.team == "") {
        carta.team = "-";
    } else {
        carta.team = Capitalize(carta.team.toLowerCase()); //To convert Lower Case
    }



    /*return (
        <div className="cartaitem" key={carta.id}>
            <div>
                <p>{carta.num_carta}</p>
            </div>

            <div>
                <img src={carta.imgurl !== "" ? carta.imgurl : "https://mpng.subpng.com/20180329/zue/kisspng-computer-icons-user-profile-person-5abd85306ff7f7.0592226715223698404586.jpg"} alt={carta.name} />
            </div>

            <div>
                <CardNameTeam firstline={carta.name} secondline={carta.coleccion} id={carta.id} />
                <span>{carta.coleccion}</span>
                <Link className="nodecoration" to={"/carta/editar?id=" + carta.id}>
                    <h3>{carta.name + " - " + carta.id}</h3>
    </Link>
            </div>
        </div>
    )*/

    return (
        <div className="ffcard" key={carta.id}>
            {/*
            <Link className='custom-card-box' to={"/carta/editar?id=" + carta.id}>
            <Badge
                badgeContent={carta.quantity ? carta.quantity : ""} color={tengo ? "success" : "error"}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Avatar
                    variant="roundedsquare"
                    src={carta.imgurl !== "" ? carta.imgurl : "https://mpng.subpng.com/20180329/zue/kisspng-computer-icons-user-profile-person-5abd85306ff7f7.0592226715223698404586.jpg"}
                    sx={{ width: 250, height: 300 }}
                />
            </Badge>
            <br></br>
            <CardNameTeam firstline={carta.num_carta + ". " + carta.name} secondline={carta.coleccion} id={carta.id} />
            </Link>
     */}


            <Link className="nodecoration" to={"/carta/editar?id=" + carta.id}>
                <div className="ffimgBx" style={{ boxShadow: '0 5px 10px ' + (tengo ? "green" : "red") }}>
                    <img onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} src={carta.imgurl !== "" ? carta.imgurl : "https://mpng.subpng.com/20180329/zue/kisspng-computer-icons-user-profile-person-5abd85306ff7f7.0592226715223698404586.jpg"} className="" alt="" />
                </div>
                {/*<img onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} src={carta.imgurl !== "" ? carta.imgurl : "https://mpng.subpng.com/20180329/zue/kisspng-computer-icons-user-profile-person-5abd85306ff7f7.0592226715223698404586.jpg"} className="" alt="" />
                    {isHovering && (
                        <div className="">
                            <div className="">
                                <svg className="" xmlns="http://www.w3.org/2000/svg"><path /></svg>
                                <Chip title="Cantidad actual" className="" label={carta.quantity ? carta.quantity : "0"} color={tengo ? "success" : "error"} variant="outlined" />
                                <div className="">
                                    <h3 className="">{carta.name}</h3>
                                    <span className="">{carta.team}</span><span className="">{carta.coleccion + " (" + carta.year + ")"}</span>
                                </div>
                            </div>
                            <p className="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, blanditiis?</p>
                        </div>
                    )}
                    */}
                <div className="ffcontent">
                    <div className="ffdetails">
                        <h2>{carta.name}<br></br><span>{carta.coleccion}</span></h2>
                        <div className="ffdata">
                            <h3>{carta.quantity ? carta.quantity : "0"}<br></br><span>Stock</span></h3>
                            <h3>{carta.team}<br></br><span>Equipo</span></h3>
                            <h3>{carta.year}<br></br><span>Año</span></h3>
                        </div>
                    </div>
                </div>
            </Link>
        </div>


    )
}

export default CardItem;