import { useParams } from "react-router-dom";
import EditarPedido from "../CompraVenta/EditarPedido";
export default function RoutePedidos(props) {
    let params = useParams();
    if (params.idpedido == null) {
        params.idpedido = 0;
    }


    return <EditarPedido idpedido={params.idpedido} id_user={props.id_user} />

}