import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import 'jszip';
import 'pdfmake';
import "pdfmake/build/vfs_fonts.js";
import 'datatables.net-dt';
import 'datatables.net-autofill-dt';
import 'datatables.net-buttons-dt';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.flash';
import 'datatables.net-buttons/js/buttons.print';
import 'datatables.net-fixedcolumns-dt';
import 'datatables.net-fixedheader-dt';
import 'datatables.net-keytable-dt';
import 'datatables.net-responsive-dt';
import 'datatables.net-rowgroup-dt';
import 'datatables.net-searchbuilder-dt';
import 'datatables.net-searchpanes-dt';
import 'datatables.net-select-dt';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import theme from "../Theme/theme";
import { useState, useEffect } from "react";
import OutboxIcon from '@mui/icons-material/Outbox';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';


pdfMake.vfs = pdfFonts.pdfMake.vfs;

const TablaCambios = (props) => {
    const [cambios, setCambios] = useState([]);


    // Llama una vez al iniciar
    useEffect(() => {
        componentDidMount();
    }, []);

    const componentDidMount = async () => {
        var perpage = 25;
        if (props.perpage !== undefined) {
            perpage = props.perpage;
        }

        const c = props.cambios
        setCambios(c)

        //initialize datatable
        $(document).ready(function () {
            setTimeout(function () {
                $('#tablacompraventa').DataTable({
                    dom: 'frltip',
                    pageLength: perpage,
                    lengthMenu: [[perpage, 50, 75, 100, -1], [perpage, 50, 75, 100, "Todos"]],
                    order: [[0, "desc"]],
                    FixedHeader: true,
                    responsive: true,
                    autoFill: false,
                    keys: false,
                    bDestroy: true
                });
            }, 1000);

        });
    }




    return (
        <div className='containerblock'>
            <div className="column">
                <ToastContainer></ToastContainer>
            </div>
            <div className="table-container">
                <table id="" className="display  dataTable dtr-inline">
                    <thead>
                        <tr >
                            <th>ID</th>
                            <th>Titulo</th>
                            <th>Fecha</th>
                            <th>Usuario</th>
                            <th><OutboxIcon /></th>
                            <th><MoveToInboxIcon /></th>
                            <th>Enviado</th>
                            <th>Recibido</th>
                            <th>Detalles</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cambios.map(item => {
                            return <tr key={item.id} >

                                <td className='centeredtext'>
                                    {item.id}
                                </td>
                                <td>
                                    <Link className='firstline' to={`/cambio/${item.id}`}>
                                        {item.title}
                                    </Link>

                                </td>
                                <td className='centeredtext'>
                                    {item.date}
                                </td>
                                <td className='centeredtext'>
                                    {item.changeuser}
                                </td>
                                <td className='centeredtext'>
                                    {item.cartasenviar}
                                </td>
                                <td className='centeredtext'>
                                    {item.cartasrecibir}
                                </td>
                                <td className='centeredtext'>
                                    {!item.sent ? <Chip theme={theme} color='error' label="No Enviado" /> : <Chip theme={theme} color='success' label="Enviado" />}
                                </td>
                                <td className='centeredtext'>
                                    {!item.received ? <Chip theme={theme} color='error' label="No Recibido" /> : <Chip theme={theme} color='success' label="Recibido" />}
                                </td>
                                <td>
                                    <Tooltip title="Edit">
                                        <Link to={`/cambio/${item.id}`}>
                                            <IconButton>
                                                <EditIcon />
                                            </IconButton>
                                        </Link>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <IconButton>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                    { /* <Link to={"/"} className="btn btn-danger btn-sm">Eliminar</Link> */}
                                </td>

                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default TablaCambios;