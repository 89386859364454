import React from 'react';
import Constantes from "./Constantes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FilaDeTablaDeCarta from './FilaDeTablaDeCarta';
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery';
import 'jszip';
import 'pdfmake';
import "pdfmake/build/vfs_fonts.js";
import 'datatables.net-dt';
import 'datatables.net-autofill-dt';
import 'datatables.net-buttons-dt';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.flash';
import 'datatables.net-buttons/js/buttons.print';
import 'datatables.net-fixedcolumns-dt';
import 'datatables.net-fixedheader-dt';
import 'datatables.net-keytable-dt';
import 'datatables.net-responsive-dt';
import 'datatables.net-rowgroup-dt';
import 'datatables.net-searchbuilder-dt';
import 'datatables.net-searchpanes-dt';
import 'datatables.net-select-dt';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import withRouter from "./routes/withrouter"
import Loading from './Functions/Loading';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import EditarColeccion from './Colecciones/EditarColeccion'
import InfoColeccion from './Colecciones/InfoColeccion'

import ApiFetch from './Functions/ApiFetch';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class VerCartas extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cartas: [],
            idcoleccion: "",
            loading: true,
            escoleccion: false,
            coleccion: [],
            years: [],
        };
    }
    async componentDidMount() {
        var idcoleccion = this.props.idcoleccion;
        var escol = true;
        //this.fetchData(idcoleccion);
        if (idcoleccion == null || idcoleccion == "%") {
            idcoleccion = "%"
            escol = false
        }



        const requestOptions = {
            method: 'GET',
        };
        const ncartas = await ApiFetch({ requestOptions: requestOptions, url: "/cartas?idcoleccion=${idcoleccion}&iduser=" + this.props.id_user });
        let cartas = ncartas.data


        //initialize datatable
        $(document).ready(function () {
            setTimeout(function () {
                $('#tablacartas').DataTable({
                    dom: 'QBfrltip',
                    pageLength: 25,
                    lengthMenu: [[25, 50, 75, 100, -1], [25, 50, 75, 100, "Todos"]],
                    order: [[0, "desc"]],
                    FixedHeader: true,
                    responsive: true,
                    autoFill: false,
                    keys: false,
                    buttons: [
                        {
                            extend: 'csv',
                            fileName: "Cartas.csv",
                            className: 'btn btn-success',
                        },
                        {
                            extend: 'excel',
                            fileName: "Cartas.xlsx",
                            className: 'btn btn-success',
                        },
                        {
                            extend: 'pdf',
                            className: 'btn btn-danger',
                        },
                        {
                            extend: 'colvis',
                            className: 'btn btn-primary'
                        }
                    ],
                    bDestroy: true
                });
            }, 1000);

        });

        this.setState({
            cartas: cartas,
            escoleccion: escol,
        });

        if (escol) {
            //console.log("hola")

            const respuestac = await fetch(`${Constantes.RUTA_API}/obtener_colecciones.php`);
            const colecciones = await respuestac.json();
            const filtered = colecciones.filter(
                (col) => col.id && col.id == (idcoleccion)
            );
            var coleccion = filtered;
            this.setState({
                coleccion: coleccion,
                loading: false
            }, () => {
                //console.log(this.state.coleccion);
            });
        } else {
            this.setState({
                loading: false
            });
        }
    }


    render() {

        if (this.state.loading) {
            return <Loading />
        }

        return (
            <div className='container'>
                <Grid container spacing={{ xs: 1, md: 2 }}>
                    {this.state.escoleccion ?
                        <>
                            <Grid item xs={12} md={4}>
                                <InfoColeccion coleccion={this.state.coleccion[0]} cartas={this.state.cartas} />
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <EditarColeccion coleccion={this.state.coleccion[0]} />
                            </Grid>
                        </>
                        : ""}
                    <Grid item xs={12} md={12}>
                        <Paper elevation={6} >
                            <Paper className='containerheader' elevation={0} >
                                <Stack direction="row" spacing={1}>
                                    <h4 >Todas las cartas</h4>
                                </Stack>
                            </Paper>
                            <div className="containerblock">
                                <ToastContainer></ToastContainer>
                                <table id="tablacartas" className="display  dataTable dtr-inline">
                                    <thead>
                                        <tr>
                                            <th>Imagen</th>
                                            <th>Num. Carta</th>
                                            <th>Nombre</th>
                                            <th>Colección</th>
                                            <th>Cantidad</th>
                                            <th>Acciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.cartas.map(carta => {
                                            return <FilaDeTablaDeCarta key={carta.id} carta={carta}></FilaDeTablaDeCarta>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withRouter(VerCartas);