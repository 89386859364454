import React from 'react';
import Constantes from "../Constantes";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { Link, Navigate } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import NewMessageNotification from '../Components/Notificaciones/NewMessageNotification';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@mui/material';
import Papa from 'papaparse';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Download } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import FilaDeTablaDeCarta from '../FilaDeTablaDeCarta';

import ApiFetch from './ApiFetch';
import ApiFile from './ApiFile';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import theme from '../Theme/theme';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CrearCarta = (props) => {
    const [colecciones, setColecciones] = useState([]);
    const [coleccion, setColeccion] = useState([]);
    const [options, setOptions] = useState([]);
    const [equipos, setEquipos] = useState([]);
    const [cartasCreadas, setCartasCreadas] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFile2, setSelectedFile2] = useState(null);
    const [guardando, setGuardando] = useState(false);
    const [value, setValue] = useState(0);
    const [carta, setCarta] = useState({
        id: 0,
        "id_coleccion": 0,
        "num_carta": "XX",
        "name": 0,
        "imgurl": "0",
        "comment": "",
        "year": new Date().getFullYear(),
        "team": "FC Team",
        "rookie": "0",
        "numerada": "0",
        "auto": "0",
        "patch": "0",
        "grade": "0",
    });

    // Llama una vez al iniciar
    useEffect(() => {
        componentDidMount();
    }, []);








    const componentDidMount = async () => {
        // Obtener ID de URL
        //const idVideojuego = props.match.params.id;

        const respuesta = await fetch(`${Constantes.RUTA_API}/obtener_colecciones.php`);
        const tcolecciones = await respuesta.json();

        const requestOptions = {
            method: 'GET',
        };
        const ncartas = await ApiFetch({ requestOptions: requestOptions, url: "/cartas?idcoleccion=%" });
        const totalcartas = ncartas.data;
        const tequipos = [...new Set(totalcartas.map(item => item.team))];
        // "refrescar" el formulario
        setColecciones(tcolecciones)
        let opt = tcolecciones.map(coleccion => {
            return { value: coleccion.id, label: coleccion.name }
        })
        setOptions(opt)
        setEquipos(tequipos)

    }

    const manejarCambioSelect = async (e) => {
        // e.value o e.label
        //console.log("Id coleccion seleccionada: " + e.value)
        carta.id_coleccion = e.value
        carta.coleccion = e.label
        setColeccion(e)
        //console.log("Col: " + cartas[0].name)
        setCarta(carta)

    }


    // On file select (from the pop up) 
    // https://www.laravelcode.com/post/how-to-upload-files-in-reactjs-with-example
    const onFileChange = (event) => {
        //console.log(event.target.files[0]);
        // Update the state 
        setSelectedFile(event.target.files[0])
    }

    const onFileChange2 = (event) => {
        // Update the state 
        setSelectedFile2(event.target.files[0])
    }



    // file upload is complete 
    const fileData = () => {
        if (selectedFile) {

            return (
                <div>
                    <h2>File Details:</h2>
                    <p>File Name: {selectedFile.name}</p>
                    <p>File Type: {selectedFile.type}</p>
                </div>
            );
        } else {
            return (
                <div>
                    <br />

                </div>
            );
        }
    };

    const tabchange = (event, newValue) => {
        setValue(newValue)
    }

    const OpenFile = (path) => {
        var filename = path.replace(/^.*[\\\/]/, '')
        //console.log(path)
        //path = "http://localhost:3000/assets/files/Unknown.pdf"
        //Open the URL on new Window
        const link = document.createElement('a');
        link.href = path;
        link.setAttribute(
            'download',
            filename,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: 'inherit',
            border: '1px solid #fff',
            color: 'inherit',
            [`& .${provided.classNamePrefix}__input`]: {
                color: '#ffffff',

                border: '1px solid #fff',
            },
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: 'inherit',
            color: 'inherit',
            [`& .${provided.classNamePrefix}__option`]: {
                color: '#ffffff',
            },
        }),
    };

    const Crearcarta = async (c, cc) => {

        c.comment = ""
        const cargaUtil = JSON.stringify(c);
        //console.log(cargaUtil)
        // console.log("Carta: " + JSON.stringify(carta))
        let respuesta = "";


        // console.log("Nueva: " + JSON.stringify(itemcompraventa))
        // ¡Y enviarlo!
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({
                carta: JSON.parse(cargaUtil)
            })
        };
        const ncartas = await ApiFetch({ requestOptions: requestOptions, url: "/carta/create" });

        const exitoso = ncartas.result;
        if (exitoso === "OK") {
            let resp = ncartas
            c.id = resp.id
            c.cantidad = 0
            c.quantity = 0
            //cc = [c, ...cc]
            //cc = cartasCreadas
            //await setCartasCreadas([c, ...cartasCreadas])
            /*toast(<NewMessageNotification link={"/carta/editar/?id=" + resp.id} text={'Carta (' + c.name + ') añadida 🃏 '} />, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,

            });*/
            return c;

        } else {
            toast.error("Error guardando. Intenta de nuevo");
        }
    }

    const timeout = async (delay) => {
        return new Promise(res => setTimeout(res, delay));
    }

    const manejarEnvioDeFormulario = async (evento) => {

        evento.preventDefault();

        var error = false
        if (carta.id_coleccion == 0) {
            error = true
            toast('❌ Por favor selecciona una colección ❌', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,

            });
            return ""
        }


        // Create an object of formData 
        if (selectedFile2 == null) { // Si no se ha subido csv
            setGuardando(true)
            if (selectedFile != null) { // Si hay imagen


                var re = /(?:\.([^.]+))?$/;
                var ext = re.exec(selectedFile.name)[1];
                ext = ext.toLowerCase();

                const res = await ApiFile({ type: "img/cartas", url: "/files/carta/upload", file: selectedFile, name: `${carta.id_coleccion}-${carta.num_carta}-${carta.year}.${ext}` });

                if (res.status === "uploaded") {
                    carta.imgurl = Constantes.RUTA_IMG_CARTAS + carta.id_coleccion + "-" + carta.num_carta + "-" + carta.year + "." + ext;
                    toast('Imagen (' + carta.imgurl + ') subida correctamente 🖼️', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,

                    });
                }

            } else {
                carta.imgurl = "https://cdn3.iconfinder.com/data/icons/sharp-users-vol-1/32/-_Default_Account_Avatar-512.png"
            }



            carta.num_carta = carta.num_carta + "-" + carta.year;
            //console.log(carta.team)
            carta.team = carta.team.toUpperCase();
            carta.name = carta.name;
            const cargaUtil = JSON.stringify(carta);
            // console.log("Carta: " + JSON.stringify(carta))
            let respuesta = "";


            // console.log("Nueva: " + JSON.stringify(itemcompraventa))
            // ¡Y enviarlo!
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({
                    carta: JSON.parse(cargaUtil)
                })
            };
            const ncartas = await ApiFetch({ requestOptions: requestOptions, url: "/carta/create" });
            console.log(ncartas)

            const exitoso = ncartas.result;
            if (exitoso === "OK") {
                let resp = ncartas
                carta.id = resp.id
                carta.cantidad = 0
                carta.quantity = 0
                setCarta(carta)
                let cc = [carta, ...cartasCreadas]
                setCartasCreadas(cc)
                //console.log(resp.id)
                toast(<NewMessageNotification link={"/carta/editar/?id=" + resp.id} text={'Carta (' + carta.name + ') añadida 🃏 '} />, {
                    position: "top-right",
                    autoClose: 15000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,

                });


            } else {
                toast.error("Error guardando. Intenta de nuevo");
            }

            setGuardando(false)
        } else { // Carga Masiva CSV

            setGuardando(true)
            Papa.parse(selectedFile2, {
                header: true,
                skipEmptyLines: true,
                complete: cartas,
            });
        }

    }

    const cartas = async (result) => {
        let cc = cartasCreadas
        result = await Promise.all(result.data.map(async (c) => {
            if (c.imgurl == "") {
                c.imgurl = "https://cdn3.iconfinder.com/data/icons/sharp-users-vol-1/32/-_Default_Account_Avatar-512.png"
            } else {
                c.imgurl = c.imgurl
            }

            c.id_coleccion = coleccion.value
            c.coleccion = coleccion.label
            c.num_carta = c.num_carta + "-" + carta.year;
            c.year = carta.year
            c.cantidad = 0
            c.team = c.team.toUpperCase()
            c.name = c.name
            //setCarta(c)
            c = await Crearcarta(c, cc)
            if (c.id != null && c.id != undefined) {
                cc = [c, ...cc]
                await setCartasCreadas(cc)
            } else {
                c.name = c.name + "ERROR"
                cc = [c, ...cc]
                await setCartasCreadas(cc)
            }


        }));
        // setCartasCreadas(result.data)

        setGuardando(false)

    }
    const manejarCambio = (evento) => {
        // Extraer la clave del estado que se va a actualizar, así como el valor
        /*let clave = evento.target.id;
        let valor = evento.target.value;
        setState(state => {
            const cartaActualizada = state.carta;
            // Si es la calificación o el nombre, necesitamos castearlo a entero
            if (clave == "cantidad") {
                valor = parseInt(valor);
            }

            if (clave.includes("team-option")) {
                valor = evento.target.innerText
                clave = "team"
            }
            //console.log("Clave: " + clave + " Valor: " + valor)
            // Actualizamos el valor del videojuego, solo en el campo que se haya cambiado
            cartaActualizada[clave] = valor;
            //console.log(cartaActualizada)
            return {
                carta: cartaActualizada,
            }
        });*/
    }

    const useStyles = {
        inputRoot: {
            color: "purple"
        }
    };

    return (
        <div className="container">
            <ToastContainer></ToastContainer>

            <form onSubmit={manejarEnvioDeFormulario}>
                <Grid container className='block' sx={{ m: 2 }} spacing={{ xs: 2, md: 2 }} >
                    <Stack direction="row" spacing={1} className='containerheader'>
                        <h4 >Añadir carta nueva</h4>
                    </Stack>
                    <Grid item xs={12} md={6}>
                        <label className="form-label" htmlFor="precio">Selecciona colección:</label>
                        <Select
                            id="id_coleccion"
                            styles={customStyles}
                            className="react-select"
                            classNamePrefix="react-select"
                            options={options}
                            onChange={manejarCambioSelect}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}></Grid>

                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={tabchange} aria-label="basic tabs example">
                                <Tab label="Manual" {...a11yProps(0)} />
                                <Tab label="Importación masiva" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <Grid container sx={{ m: 2 }} spacing={{ xs: 2, md: 2 }} >
                                <Grid item xs={12} md={2}>
                                    <label className="form-label" htmlFor="cantidad">Número de carta:</label>
                                    <input placeholder="10" type="text" id="num_carta" onChange={(event) => {
                                        setCarta(carta => ({ ...carta, num_carta: event.target.value }));
                                    }} className="form-control rainbowborder" />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <label className="form-label">Nombre:</label>

                                    <input placeholder="Lionel Messi" type="text" id="name" onChange={(event) => {
                                        setCarta(carta => ({ ...carta, name: event.target.value }));
                                    }} className="form-control rainbowborder" />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <label className="form-label">Equipo:</label>

                                    <Autocomplete
                                        theme={theme}
                                        id="team"
                                        freeSolo
                                        onInputChange={(event) => {
                                            setCarta(carta => ({ ...carta, team: event.target.innerText }));
                                        }}
                                        options={equipos.map((option) => option)}
                                        InputProps={{
                                            className: "whitecolor"
                                        }}
                                        renderInput={(params) => <TextField theme={theme}  {...params} className="whitecolor" onChange={(event) => {
                                            setCarta(carta => ({ ...carta, team: event.target.value }));
                                        }} label="" />}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <FormControlLabel
                                        control={<Checkbox id="rookie" />}
                                        label="Rookie"
                                        value="1"
                                        labelPlacement="start"
                                        onChange={(event) => {
                                            const value = event.target.checked ? 1 : 0;
                                            setCarta(carta => ({ ...carta, rookie: value }));
                                        }}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox id="numerada" />}
                                        label="Numerada"
                                        value="1"
                                        labelPlacement="start"
                                        onChange={(event) => {
                                            const value = event.target.checked ? 1 : 0;
                                            setCarta(carta => ({ ...carta, numerada: value }));
                                        }}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox id="firma" />}
                                        label="Firma"
                                        value="1"
                                        labelPlacement="start"
                                        onChange={(event) => {
                                            const value = event.target.checked ? 1 : 0;
                                            setCarta(carta => ({ ...carta, auto: value }));
                                        }}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox id="patch" />}
                                        label="Patch"
                                        value="1"
                                        labelPlacement="start"
                                        onChange={(event) => {
                                            const value = event.target.checked ? 1 : 0;
                                            setCarta(carta => ({ ...carta, patch: value }));
                                        }}
                                    />

                                    <FormControlLabel
                                        control={<Checkbox id="graded" />}
                                        label="Gradeada"
                                        value="1"
                                        labelPlacement="start"
                                        onChange={(event) => {
                                            const value = event.target.checked ? 1 : 0;
                                            setCarta(carta => ({ ...carta, grade: value }));
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <label className="form-label">Imagen:</label>
                                    <input class="custom-file-input" type="file" onChange={onFileChange} />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    {fileData()}
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Grid item xs={12} md={12}>
                                <label className="form-label">Creación másiva (CSV):</label>
                                <input className="form-control" type="file" accept=".csv" onChange={onFileChange2} />
                                <Tooltip title="Download">
                                    <IconButton onClick={() => OpenFile("/assets/files/templates/templateAddCartas.csv")} >
                                        <Download />Descargar Plantilla
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </TabPanel>
                    </Box>


                    <Grid item xs={12} md={2}>
                        <label className="form-label">Año:</label>
                        <input required placeholder="2004" type="text" id="year" value={carta.year} onChange={(event) => {
                            setCarta(carta => ({ ...carta, year: event.target.value }));
                        }} className="form-control rainbowborder" />
                    </Grid>
                    <Grid item xs={12} md={10}></Grid>


                    <Grid item xs={12} md={6}>
                        <Button id="recibido" variant="contained" color="primary" onClick={manejarEnvioDeFormulario} >
                            Guardar
                        </Button>
                        {guardando ? (<>
                            <CircularProgress />
                        </>) : (<></>)}
                    </Grid>
                    <Grid item xs={12} md={12} className='block'>

                        <TableContainer >
                            <Table aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ width: 60 }} className="whitecolor">
                                            Foto
                                        </TableCell>
                                        <TableCell sx={{ width: 80 }} className="whitecolor">
                                            Num. Carta
                                        </TableCell>
                                        <TableCell className="whitecolor">
                                            Carta
                                        </TableCell>
                                        <TableCell className="whitecolor">
                                            Colección
                                        </TableCell>
                                        <TableCell sx={{ width: 100 }} className="whitecolor">
                                            Cantidad
                                        </TableCell>
                                        <TableCell sx={{ width: 100 }} className="whitecolor">
                                            Acciones
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {cartasCreadas.map(c => {
                                        return (
                                            <FilaDeTablaDeCarta id_user={props.id_user} key={c.id} carta={c} editqty={true}></FilaDeTablaDeCarta>
                                        )

                                    })}
                                </TableBody>
                                <TableFooter>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </form>
        </div >
    );





}

export default CrearCarta;