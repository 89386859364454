import React from "react";
import Constantes from "../Constantes";
import { RefreshToken } from "../Login/RefreshToken";
import Login from "../Login/Login";

const ApiFetch = async (props) => {
    let data = [];
    let status = 0;
    var today = new Date().getTime()
    var diff = today - localStorage.getItem('aexpire')
    var diffMins = Math.round(((diff % 86400000) % 3600000) / 60000);
    //console.log("Diferencia minutos token: " + diffMins)
    let tokens = {
        accessToken: localStorage.getItem('aToken'),
        refreshToken: localStorage.getItem('rToken'),
    }

    if (diffMins > 60 || localStorage.getItem('aexpire') == undefined) { // Si han pasado más de 60min el token ha expirado
        console.log("Auth token expirado, refresh")
        tokens = await RefreshToken();
    }


    if (localStorage.getItem('aToken') == undefined) {
        return [];
    }

    /*console.log("Tokens")
    console.log(tokens.accessToken)*/

    const requestHeaders = {
        headers: {
            "Authorization": 'bearer ' + tokens.accessToken,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    };
    //console.log(JSON.stringify("User ID: " + tokens.userid))
    const requestOptions = { ...props.requestOptions, ...(requestHeaders) }

    //console.log(JSON.stringify(requestOptions))

    await fetch(`${Constantes.RUTA_API_JS}${props.url}`, requestOptions)
        .then(response => response.json())
        .then(response => {
            //console.log(response)
            if (status === 403 || response.status == 403) {
                localStorage.removeItem('aToken')
                localStorage.removeItem('rToken')
                localStorage.removeItem('aexpire')
                localStorage.removeItem('uid')
                window.location.reload(false);
            } else {
                data = response
            }
        })
        .catch(err => {
            console.error(err)
        });

    //console.log(data.data[0])
    return data;

}

export default ApiFetch;

